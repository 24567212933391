import * as ActionTypes from '../constants/actionTypes';

export const initialState = {
    request: false,
    error: '',
    data: [],
};

export default function (state = initialState, action) {

    switch (action.type) {
        case ActionTypes.DEALERSHIPS_REQUEST:
            return {
                ...state,
                request: true,
                error: '',
            };

        case ActionTypes.DEALERSHIPS_SUCCESS:
            return {
                ...state,
                request: false,
                error: '',
                data: action.payload
            };

        case ActionTypes.DEALERSHIPS_ERROR:
            return {
                ...state,
                request: false,
                error: action.payload
            };

        default :
            return state;
    }

}
