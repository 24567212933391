import React, {useEffect} from 'react'
import {Link as RouterLink} from "react-router-dom";
import clsx from "clsx";
import ReactHtmlParser from "react-html-parser";
import {useDispatch, useSelector} from 'react-redux'
import Fade from "react-reveal/Fade";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import qs from 'qs'
/*actions*/
import {fetchPage} from "../../actions/pages";
import {fetchModels} from "./actions";
/*ui*/
import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
/*components*/
import SectionBox from "../../components/SectionBox";
import ProgressFullScreen from "../../components/ProgressFullScreen";
import {PAGES_SLUGS} from "../../constants";
import Seo from "../../components/seo/Seo";
import {appStyle} from "../../theme/appStyle";
import SpaceBox from "../../components/SpaceBox";
import {getModels, getYears} from "../../components/accessories/RenderModelsYears";

function AccessoriesIntro() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const pageData = useSelector(state => _get(state.pages.data, PAGES_SLUGS.ACCESSORIES_INTRO, {}));
    const accessoryModels = useSelector(state => state.accessoryModels.data);
    const loading = useSelector(state => state.accessoryModels.request);

    const [model, setModel] = React.useState('');
    const [year, setYear] = React.useState('');
    const [url, setUrl] = React.useState(`/${PAGES_SLUGS.ACCESSORIES}`);
    const [queryParameters] = React.useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
        if (_isEmpty(pageData)) {
            dispatch(fetchPage(PAGES_SLUGS.ACCESSORIES_INTRO))
        }
        if (_isEmpty(accessoryModels)) {
            dispatch(fetchModels())
        }
    }, [])


    const changeModel = (event) => {
        const value = event.target.value;
        // Reset year if not present in previous model's array
        if (year && !accessoryModels[value].includes(year)) {
            setYear('');
            delete queryParameters.years;
        }

        setModel(value);
        queryParameters.models = value;
        setUrl(`/${PAGES_SLUGS.ACCESSORIES}${qs.stringify(queryParameters,{addQueryPrefix: true, arrayFormat: 'repeat',})}`);
    };

    const changeYear = (event) => {
        const value = event.target.value;
        setYear(value);
        queryParameters.years = value;
        setUrl(`/${PAGES_SLUGS.ACCESSORIES}${qs.stringify(queryParameters,{addQueryPrefix: true, arrayFormat: 'repeat',})}`);
    };

    return (
        <React.Fragment>
            {!_isEmpty(_get(pageData, 'data.meta_data', {})) && <Seo data={pageData.data.meta_data}/>}
            {!loading && <>
                {!_isEmpty(_get(pageData, 'data.top_section', {})) && <Fade>

                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        flexDirection={"column"}
                        className={clsx(classes.banner)}
                        style={{
                            "--background-image": `url(${_get(pageData, 'data.top_section.side_image', "")})`
                        }}
                    >
                        <Fade delay={1000}>
                            <Typography component={'div'}
                                        className={classes.bannerTitle}>{ReactHtmlParser(_get(pageData.data.top_section, "title", ''))}</Typography>

                            <Typography align={"center"} component={'div'}
                                        className={classes.bannerText}>{ReactHtmlParser(_get(pageData.data.top_section, "content", ''))}</Typography>
                        </Fade>
                    </Box>

                </Fade>}

                {!_isEmpty(accessoryModels) && <SectionBox>
                    <Container>
                        <Grid spacing={3} container>
                            <Grid item sm={6} xs={12}>
                                <Box
                                    display={"flex"}
                                    justifyContent={"flex-start"}
                                    alignItems={"center"}
                                >
                                    <Icon className={clsx("brand-icon-car", classes.sectionIcon)}/>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        className={classes.sectionTitle}
                                    >1. Model</Typography>
                                </Box>
                                <FormControl variant="outlined" className={classes.formControl}
                                             fullWidth={true}>
                                    <InputLabel id="model-label">Alege modelul preferat</InputLabel>
                                    <Select
                                        labelId="model-label"
                                        id="model-select"
                                        value={model}
                                        onChange={changeModel}
                                        label="Alege modelul preferat"
                                        inputProps={{
                                            classes: {
                                                root: classes.inputRoot,
                                                icon: classes.dropdownIcon
                                            }
                                        }}
                                        IconComponent={ExpandMoreIcon}
                                    >
                                        {getModels(accessoryModels)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Box
                                    display={"flex"}
                                    justifyContent={"flex-start"}
                                    alignItems={"center"}
                                >
                                    <Icon className={clsx("brand-icon-date", classes.sectionIcon)}/>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        className={classes.sectionTitle}
                                    >2. Anul</Typography>
                                </Box>
                                <FormControl variant="outlined" className={classes.formControl}
                                             fullWidth={true}>
                                    <InputLabel id="year-label">Alege anul modelului tau</InputLabel>
                                    <Select
                                        labelId="year-label"
                                        id="year-select"
                                        value={year}
                                        onChange={changeYear}
                                        label="Alege anul modelului tau"
                                        inputProps={{
                                            classes: {
                                                root: classes.inputRoot,
                                                icon: classes.dropdownIcon
                                            }
                                        }}
                                        IconComponent={ExpandMoreIcon}
                                    >
                                        {getYears(accessoryModels, model)}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Container>
                </SectionBox>}
            </>}

            <Container>
                <Grid container justify={"center"}>
                    <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        className={classes.extraSpacing}
                        startIcon={<Icon className="brand-icon-offers"/>}
                        component={RouterLink}
                        to={url}>
                        VEZI TOATE ACCESORIILE
                    </Button>
                </Grid>
                <SpaceBox/>
            </Container>

            {loading &&
            <ProgressFullScreen loading={loading}/>
            }
        </React.Fragment>
    )
}


const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    banner: {
        backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.35), rgba(0,0,0,0.35)), var(--background-image)`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        minHeight: 540,
        padding: theme.spacing(3),
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            minHeight: 350,
        },
    },
    bannerTitle: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(38),
        lineHeight: theme.typography.pxToRem(56),
        letterSpacing: "0.4",
        color: "#fff",
    },
    bannerText: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(24),
        color: "#fff",
        [theme.breakpoints.up('md')]: {
            maxWidth: 350,
        },
    },
    dropdownIcon: {
        color: theme.palette.primary.main,
    },
    inputRoot: {
        background: "#fff",
    },
    sectionTitle: {
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    extraSpacing: {
        marginTop: theme.spacing(3.5)
    }
}))

export default AccessoriesIntro;
