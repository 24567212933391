import React, {useEffect} from 'react'
import clsx from "clsx";
import {useDispatch, useSelector} from 'react-redux'
import Fade from "react-reveal/Fade";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import * as actions from "../../actions/pages"
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SectionBox from "../../components/SectionBox";
import ProgressFullScreen from "../../components/ProgressFullScreen";
import {PAGES_SLUGS} from "../../constants";
import Seo from "../../components/seo/Seo";
import ReactHtmlParser from "react-html-parser";
import {Text, Title} from "../../components/Typografy";
import _map from "lodash/map";
import OfferCard from "../../components/OfferCard";

function AboutUsPage(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const pageData = useSelector(state => _get(state.pages.data, PAGES_SLUGS.CONFIDENTIALITY_POLICY, {}));
    const loading = useSelector(state => state.pages.request);
    const error = useSelector(state => state.pages.error);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (_isEmpty(pageData)) {
            dispatch(actions.fetchPage(PAGES_SLUGS.CONFIDENTIALITY_POLICY))
        }
    }, [])

    return (
        <React.Fragment>
            {!_isEmpty(_get(pageData, 'data.meta_data', {})) && <Seo data={pageData.data.meta_data}/>}
            {!loading && <>
                {!_isEmpty(_get(pageData, 'data.top_section', {})) && <Fade>

                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        flexDirection={"column"}
                        className={classes.banner}
                        style={{
                            "--background-image": `url(${_get(pageData, 'data.top_section.side_image', "")})`
                        }}
                    >
                        <Fade delay={1000}>
                            <Typography component={'div'}
                                        className={classes.bannerTitle}>{ReactHtmlParser(_get(pageData.data.top_section, "title", ''))}</Typography>

                            <Typography align={"center"} component={'div'}
                                        className={classes.bannerText}>{ReactHtmlParser(_get(pageData.data.top_section, "content", ''))}</Typography>
                        </Fade>
                    </Box>

                </Fade>}
                {!_isEmpty(_get(pageData, 'data.content_section', {})) && <SectionBox>

                    <Container>

                        <Grid container spacing={3} className={classes.container}>
                            <Grid item xs={12}>
                                <Text>
                                    {ReactHtmlParser(_get(pageData, 'data.content_section.content', ""))}
                                </Text>
                            </Grid>
                        </Grid>

                    </Container>

                </SectionBox>}
                <Divider/>
                {!_isEmpty(_get(pageData, 'data.body_types_section.body_types_section', {})) && <SectionBox>
                    <Fade>
                        <Container maxWidth={"sm"}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Title>{_get(pageData, 'data.body_types_section.title', '')}</Title>
                                </Grid>
                            </Grid>
                        </Container>
                        <div className={classes.containerOutter}>
                            <Grid container spacing={3}>
                                {_map(_get(pageData, 'data.body_types_section.body_types_section', []), (item, index) => {
                                    return <OfferCard
                                        key={index}
                                        to={_get(item, "attributes.url", '')}
                                        title={_get(item, "attributes.title", '')}
                                        content={_get(item, "attributes.content", '')}
                                        image={_get(item, "attributes.slide_image", '')}
                                    />
                                })}
                            </Grid>
                        </div>
                    </Fade>
                </SectionBox>}
            </>}
            {loading &&
            <ProgressFullScreen loading={loading}/>
            }
        </React.Fragment>
    )
}


const useStyles = makeStyles((theme) => ({
    containerOutter: {
        overflow: "Hidden",
        [theme.breakpoints.down('sm')]: {
            paddingLeft: `${theme.spacing(3) / 2}px`,
            paddingRight: `${theme.spacing(3) / 2}px`,
        },
    },
    banner: {
        backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.35), rgba(0,0,0,0.35)), var(--background-image)`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        minHeight: "calc(100vh - 64px)",
        padding: theme.spacing(3),
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            minHeight: 350,
        },
    },

    bannerTitle: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(38),
        lineHeight: theme.typography.pxToRem(56),
        letterSpacing: "0.4",
        color: "#fff",
    },
    bannerText: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(24),
        color: "#fff",
        [theme.breakpoints.up('md')]: {
            maxWidth: 350,
        },
    },


}))


export default AboutUsPage;
