import {appStyle} from "./appStyle";

const itemDescriptionStyles = theme => ({
    ...appStyle(theme),
    descriptionParent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: theme.spacing(2),
        width: "100%",
    },
    sectionTitle: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(22),
        lineHeight: theme.typography.pxToRem(32),
        paddingBottom: theme.spacing(2),
    },
    sectionName: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(24),
        paddingBottom: theme.spacing(1),
        wordWrap:"break-word",
        whiteSpace: `pre-wrap`
    },
    sectionText: {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(24),
        paddingBottom: theme.spacing(0),
        wordWrap:"break-word",
    },
});

export default itemDescriptionStyles;
