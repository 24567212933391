import * as ActionTypes from './constants';

const initialState = {
    facets: {
        request: true,
        error: '',
        data: []
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.FACETS_SUCCESS:
            return {
                ...state,
                facets: {
                    ...state.facets,
                    request: false,
                    error: '',
                    data: action.payload
                }
            };

        case ActionTypes.FACETS_ERROR:
            return {
                ...state,
                facets: {
                    ...state.facets,
                    request: false,
                    error: action.payload,
                }
            };

        default:
            return state;
    }
}
