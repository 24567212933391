import * as ActionTypes from './constants';
import {
    addToLocalStorageArray,
    emptyLocalStorageArray,
    getWishlistData,
    removeFromLocalStorageArray,
    WL
} from "../../helpers/localStorage";
import axios from "axios";
import {API_WISHLIST_UUID} from "../../api";
import setHeaders from "../../helpers/setHeaders";
import handleErrors from "../../helpers/handleErrors";
import _get from "lodash/get";

export const addAccessoryToWishlist = (slug) => {
    return function (dispatch) {
        const currentWishlistArray = getWishlistData(WL.ACCESSORY);
        if (currentWishlistArray.includes(slug)) {
            // update state only
        } else {
            currentWishlistArray.push(slug);
            dispatch({
                type: ActionTypes.WISHLIST_UUID_REQUEST
            });
            const tempWishlistObject = {
                cars: getWishlistData(WL.CAR),
                accessories: currentWishlistArray
            }

            axios.post(`${API_WISHLIST_UUID}`, tempWishlistObject, setHeaders()).then((response) => {
                const uuid = response.data.data.uuid;
                // Wishlist source of truth will be local storage for faster access
                addToLocalStorageArray(WL.ACCESSORY, slug);
                localStorage.setItem(WL.UUID, uuid);
                dispatch({
                    type: ActionTypes.UPDATE_WISHLIST,
                    payload: uuid
                });
            }).catch((response) => {
                const {errors, message} = handleErrors(response);
            });
        }
    }
}

export const removeAccessoryFromWishlist = (slug) => {
    return function (dispatch) {
        let currentWishlistArray = getWishlistData(WL.ACCESSORY);
        if (!currentWishlistArray.includes(slug)) {
            // update state only
        } else {
            currentWishlistArray = currentWishlistArray.filter(item => item !== slug);
            dispatch({
                type: ActionTypes.WISHLIST_UUID_REQUEST
            });
            const tempWishlistObject = {
                cars: getWishlistData(WL.CAR),
                accessories: currentWishlistArray
            }

            axios.post(`${API_WISHLIST_UUID}`, tempWishlistObject, setHeaders()).then((response) => {
                const uuid = response.data.data.uuid;
                removeFromLocalStorageArray(WL.ACCESSORY, slug);
                localStorage.setItem(WL.UUID, uuid);
                dispatch({
                    type: ActionTypes.UPDATE_WISHLIST,
                    payload: uuid
                });
            }).catch((response) => {
                const {errors, message} = handleErrors(response);
            });
        }
    }
}

export const addCarToWishlist = (slug) => {
    return function (dispatch) {
        const currentWishlistArray = getWishlistData(WL.CAR);
        if (currentWishlistArray.includes(slug)) {
            // update state only
        } else {
            currentWishlistArray.push(slug);
            dispatch({
                type: ActionTypes.WISHLIST_UUID_REQUEST
            });
            const tempWishlistObject = {
                cars: currentWishlistArray,
                accessories: getWishlistData(WL.ACCESSORY)
            }

            axios.post(`${API_WISHLIST_UUID}`, tempWishlistObject, setHeaders()).then((response) => {
                const uuid = response.data.data.uuid;
                addToLocalStorageArray(WL.CAR, slug);
                localStorage.setItem(WL.UUID, uuid);
                dispatch({
                    type: ActionTypes.UPDATE_WISHLIST,
                    payload: uuid
                });
            }).catch((response) => {
                const {errors, message} = handleErrors(response);
            });
        }
    }
}

export const removeCarFromWishlist = (slug) => {
    return function (dispatch) {
        let currentWishlistArray = getWishlistData(WL.CAR);
        if (!currentWishlistArray.includes(slug)) {
            // update state only
        } else {
            currentWishlistArray = currentWishlistArray.filter(item => item !== slug);
            dispatch({
                type: ActionTypes.WISHLIST_UUID_REQUEST
            });
            const tempWishlistObject = {
                cars: currentWishlistArray,
                accessories: getWishlistData(WL.ACCESSORY)
            }

            axios.post(`${API_WISHLIST_UUID}`, tempWishlistObject, setHeaders()).then((response) => {
                const uuid = response.data.data.uuid;
                removeFromLocalStorageArray(WL.CAR, slug);
                localStorage.setItem(WL.UUID, uuid);
                dispatch({
                    type: ActionTypes.UPDATE_WISHLIST,
                    payload: uuid
                });
            }).catch((response) => {
                const {errors, message} = handleErrors(response);
            });
        }
    }
}

export const emptyWishlist = () => {
    return function (dispatch) {
        emptyLocalStorageArray(WL.ACCESSORY);
        emptyLocalStorageArray(WL.CAR);
        emptyLocalStorageArray(WL.UUID);
        dispatch({
            type: ActionTypes.UPDATE_WISHLIST,
            payload: null
        });
    }
}

export const getWishlistFromUuid = (uuid) => {
    return function (dispatch) {
        dispatch({
            type: ActionTypes.WISHLIST_REQUEST
        });
        axios.get(`${API_WISHLIST_UUID}/${uuid}`, setHeaders()).then((response) => {
            const wishlistObject = _get(response,"data.data.data_object", {})
            wishlistObject.uuid = uuid;
            dispatch({
                type: ActionTypes.DATA_FROM_UUID,
                payload: wishlistObject
            });

        }).catch((response) => {
            const {errors, message} = handleErrors(response);
        });
    }
}

export const getWishlistFromLocalStorage = () => {
    return function (dispatch) {
        dispatch({
            type: ActionTypes.USE_LOCAL_STORAGE
        });
    }
}

// These are related to accessory offer for single and multiple ones
// Get the wishlist from the uuid
export const getAccWishlistFromUuid = (uuid) => {
    return function (dispatch) {
        dispatch({
            type: ActionTypes.WISHLIST_REQUEST
        });
        axios.get(`${API_WISHLIST_UUID}/${uuid}`, setHeaders()).then((response) => {
            const wishlistArray = _get(response,"data.data.data_object.accessories",[])
            dispatch({
                type: ActionTypes.UUID_TO_SLUGS,
                payload: wishlistArray
            });
        }).catch((response) => {
            const {errors, message} = handleErrors(response);
        });
    }
}

// Replace the old array with one containing a single slug
export const addSlug = (slug) => {
    return function (dispatch) {
        dispatch({
            type: ActionTypes.ADD_SLUG,
            payload: [slug]
        });
    }
}
