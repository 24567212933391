import React from 'react';
/*ui*/
import Typography from "@material-ui/core/Typography";
/*component*/
import toLocale from "../../../helpers/toLocale";


const searchAccPrice = (accData, classes) => {
    if (!accData.base_price) return;

    if (!accData.final_price || accData.final_price === accData.base_price) {
        return `${toLocale(accData.base_price)}€ +TVA`
    } else {
        return (
            <React.Fragment>
                <div>{toLocale(accData.final_price)}€ + TVA
                    <Typography variant="caption"
                                className={classes.oldPrice}>{toLocale(accData.base_price)}€</Typography> + TVA</div>
            </React.Fragment>
        )
    }
}

export default searchAccPrice;
