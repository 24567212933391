import React, {Component} from 'react';
import {connectHits} from "react-instantsearch-dom";
// UI
import Grid from "@material-ui/core/Grid";
import DetailedCarCard from "../../DetailedCarCard";
import {appStyle} from '../../../theme/appStyle';
import {withStyles} from "@material-ui/core";


class AlgoliaHits extends Component {
    render() {
        const {hits} = this.props;
        return (
            hits.map(hit => {
                    // console.log(hit);
                    return (
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}
                              key={hit.rom_ref}
                        >
                            <DetailedCarCard data={hit}/>
                        </Grid>
                    )
                }
            )
        )
    }
}

AlgoliaHits = withStyles(appStyle)(AlgoliaHits);
AlgoliaHits = connectHits(AlgoliaHits);
export default AlgoliaHits;
