import axios from 'axios';
import _get from "lodash/get";
import * as ActionTypes from '../constants/actionTypes';
import * as api from '../api';
import setHeaders from "../helpers/setHeaders";
import handleErrors from "../helpers/handleErrors";

export function fetchPage(page_slug, withLoading = true) {
    return function (dispatch) {
        if (withLoading) {
            dispatch({
                type: ActionTypes.PAGES_REQUEST
            });
        }

        axios.get(`${api.API_PAGES}/${page_slug}`, setHeaders()).then((response) => {
            dispatch({
                type: ActionTypes.PAGES_SUCCESS,
                payload: {
                    "slug": page_slug,
                    'data': _get(response, "data", {})
                }
            });
        }).catch((response) => {
            const {errors, message} = handleErrors(response);
            dispatch({
                type: ActionTypes.PAGES_ERROR,
                payload: errors
            });
        });
    }
}
