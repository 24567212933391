import {appStyle} from "./appStyle";

const listingStyles = theme => ({
    ...appStyle(theme),
    sidePadding: {
        padding: theme.spacing(0, 2),
    },
    searchBarContainer: {
        marginBottom: theme.spacing(6),
    },
    searchSubtitle: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: `center`,
            order: 4
        },
    },
    searchInputParent: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: `center`,
            order: 1
        },
    },
    stickySort: {
        position: `sticky`,
        height: `100%`,
        top: 64
    },
    sortParent: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: `center`,
            order: 3
        },
    },
    mobileFilterParent: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: `center`,
            order: 2
        },
    },
    filterContainer: {
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(3),
        },
        [theme.breakpoints.up('xl')]: {
            paddingRight: theme.spacing(5),
        }
    },
    paginationGrid: {
        marginTop: theme.spacing(7),
        '&::before': {
            content: `''`,
            position: `absolute`,
            width: `100%`,
            borderBottom: `1px solid #EEE`,
            bottom: 15,
            zIndex: -1,
        }
    }
});

export default listingStyles;
