import React from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Link from "@material-ui/core/Link";
import _get from "lodash/get";
import _map from "lodash/map";

import ModelCard from "../components/ModelCard"
import {PAGES_SLUGS} from "../constants";
import getSuppotedFileType from "../helpers/getSuppotedFileType";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const NodelsList = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const data = useSelector(state => state.models.data);
    const loading = useSelector(state => state.models.request);
    const error = useSelector(state => state.models.error);

    const handleCloseModal = () => {
        setTimeout(() => {
            props.onClose()
        }, 100)
    }

    return (

        <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}
                classes={{root: classes.dialogRoot}}>
            <div className={classes.root}>
                <div className={classes.list}>
                    <Container className={classes.container}>
                        {_map(data, (item, index) => {
                            /**
                             * Divide full height (100%) to the number of car rows so the title is always vertically align with the 1st row
                             * @type {number}
                             */
                            const itemTitleHeight = Math.ceil(_get(item, "models", []).length / 3);
                            return <Grid container spacing={3} key={index}>
                                <Grid item xs={12} sm={3}>
                                    <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"}
                                         height={`calc(100% / ${itemTitleHeight})`} className={classes.mobileFullHeight}>
                                        <div>
                                            <Typography component={"p"}
                                                        className={classes.title}>{item.name}</Typography>
                                            <Link className={classes.link} onClick={handleCloseModal} component={RouterLink} to={`/${PAGES_SLUGS.PRODUCTS}?car_body=${item.slug}`} >Vezi toate ofertele <Icon
                                                className={clsx("brand-icon-right-nav-arrow", classes.linkIcon)}/>
                                            </Link>
                                        </div>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <Grid container  spacing={3} >
                                        {_map(_get(item, "models", []), (model, index) => {
                                            if (!model.min_price) return null
                                            return <Grid item xs={12} sm={4}
                                                         key={index}>
                                                <ModelCard
                                                    image={getSuppotedFileType(_get(model, "image", []))}
                                                    title={model.name}
                                                    price={model.min_price}
                                                    to={model.slug.toUpperCase()}
                                                    onClose={props.onClose}
                                                />
                                            </Grid>
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        })}
                    </Container>
                    <Container>
                        <Grid container>
                            <Grid item xs={12}>
                                <Divider/>
                                <Box
                                    display={"flex"}
                                    justifyContent={"center"}
                                    className={classes.all}
                                >

                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        startIcon={<Icon className={clsx("brand-icon-offers", classes.icon)}/>}
                                        component={RouterLink} to={`/${PAGES_SLUGS.PRODUCTS}`}
                                        onClick={handleCloseModal}
                                    >
                                        VEZI OFERTE PENTRU TOATE MODELELE
                                    </Button>

                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <Container>
                    <Grid container>
                        <Grid item xs={12}>
                            <Divider/>
                            <Box
                                display={"flex"}
                                justifyContent={"center"}
                                className={classes.rootClose}
                            >
                                <Button
                                    onClick={props.onClose}
                                    color="secondary"
                                    startIcon={<Icon
                                        className={clsx("brand-icon-close", classes.icon, classes.close)}/>}
                                >
                                    <span className={classes.text}>Inchide</span>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Dialog>

    );
}

const useStyles = makeStyles((theme) => ({
    container:{
        [theme.breakpoints.up('lg')]: {
            maxWidth:"1100px !important"
        },
    },
    mobileFullHeight: {
        [theme.breakpoints.down('xs')]: {
            height: `100% !important`
        },
    },
    dialogRoot: {
        zIndex: `${theme.zIndex.appBar - 1}!important`
    },
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    list: {
        paddingTop: theme.spacing(3),
        flex: 1,
        overflowY: "auto",
        marginTop: 64,
    },
    all: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(8),
    },
    rootClose: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    icon: {
        fontSize: "14px",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
        color: theme.palette.primary.main,
    },
    close: {
        fontSize: "24px",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
        color: theme.palette.secondary.main,
    },
    title: {
        fontFamily: "Volvo Broad, Arial Black",
        fontSize: 36,
        letterSpacing: "0px",
        lineHeight: "1",
        paddingBottom: theme.spacing(1),
    },
    link: {
        fontSize: "14px",
        lineHeight: "1",
        padding: `${theme.spacing(1) / 2}px 0`,
        position: "relative",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "none",
        },
        "&:before": {
            content: `''`,
            position: "absolute",
            width: "0",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform:"translateX(-50%)",
            background: theme.palette.primary.main,
            transition: theme.transitions.create(['width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        "&:hover:before": {
            content: `''`,
            position: "absolute",
            width: "100%",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform:"translateX(-50%)",
            background: theme.palette.primary.main
        }
    },
    linkIcon: {
        fontSize: 10,
    },
    text: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(24),
        textTransform: "capitalize",
    }
}));

export default NodelsList;
