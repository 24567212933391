import * as ActionTypes from './constants';
import {accessoriesIndexName, searchClient, staticOrder} from "../../constants/algolia";
import extractFilterName from "../../helpers/extractFilterName";

export function fetchFacets() {
    return function (dispatch) {
        const index = searchClient.initIndex(accessoriesIndexName);
        index.search('', {
            facets: ['*']
        }).then((response) => {
            const facets = Object.keys(response.facets);
            // Create an array of empty strings. If a static facet doesn't exist, it's place should be taken
            // Example: staticOrder = ["categories", "brands", "forms", "subforms", "benefits"]
            // ["categories.name", "", "brands.name", "", "subforms.name", "", "", "size.name"]
            const orderedFacets =  Array(facets.length).fill('')
            facets.map((item, index) => {
                // Get the index of the facet from Algolia in the static one
                const indexInArray = staticOrder.indexOf(extractFilterName(item));
                if(indexInArray !== -1) {
                    // Push it to that position if it does exist
                    orderedFacets.splice(indexInArray, 1, item);
                } else {
                    // Otherwise push it at the back of the array
                    orderedFacets.push(item);
                }
            })
            // Remove empty entries when passing the payload
            // Boolean function is enough to clear those
            // console.log(orderedFacets.filter(Boolean));
            dispatch({
                type: ActionTypes.FACETS_SUCCESS,
                payload: orderedFacets.filter(Boolean)
            });

        }).catch((response) => {
            const {errors, message} = response.data;
            dispatch({
                type: ActionTypes.FACETS_ERROR,
                payload: errors
            });
        });
    }
}
