import * as ActionTypes from '../constants/actionTypes';

/**
 * Hide Dealer filter in car dealer pages (dealer?dealerships=Todorut%20-%20Timisoara)
 * Hide header to disable navigation to different pages
 * Hide "Masini similare" from details page
 * Hide search bar in /dealer page
 *
 * For filters, it needs to exist for the faceting so we only hide it visually
 * @param state
 * @returns {{payload: *, type: string}}
 */
export function setCurrentActiveDealer(state) {
    return {
        type: ActionTypes.DEALER_SET_CURRENT_VALUE,
        payload: state
    };
}
