import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
// import Swiper core and required components
import SwiperCore, {EffectFade, Lazy, Pagination, Thumbs} from 'swiper';
import {Swiper} from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
/*demo image*/


// install Swiper components
SwiperCore.use([ Pagination, Thumbs, EffectFade, Lazy]);

const Slider = (props) => {
    const classes = useStyles();
    // const paginationEl = _uniqueId("swiper-pagination-");
    return (
            <Swiper
                {...props}
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log('slide change')}
                style={{width: `100%`}}
            >
                {props.children}
            </Swiper>
    );
};

const useStyles = makeStyles((theme) => ({

}))

export default Slider
