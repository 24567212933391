import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from 'react-router-dom';
/*components*/
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import {LogoContrast} from "./BrandItems";
import {PAGES_SLUGS} from "../constants";


export default (props) => {
    const classes = useStyles();

    // If data object's data is only null, we only render the copyright text and fix it to bottom
    return (
        <React.Fragment>

            <footer className={classes.root}>
                <Container maxWidth="sm">
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography component={"p"} align={"center"} className={classes.caption}>
                                Drepturi de autor ©{new Date().getFullYear()} Volvo Car Corporation (afiliaţii sau
                                licenţiatorii acestora).
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component={"div"} align={"center"} className={classes.links}>
                                <Link component={RouterLink} to={`/${PAGES_SLUGS.COOKIE_POLICY}`} className={classes.link}>
                                    Cookies
                                </Link>

                                <Link component={RouterLink} to={`/${PAGES_SLUGS.CONFIDENTIALITY_POLICY}`} className={classes.link}>
                                    Politica de confidențialitate pentru clienți
                                </Link>

                                <Link component={RouterLink} to={`/${PAGES_SLUGS.TERMS_AND_CONDITIONS}`} className={classes.link}>
                                    Termeni si conditii
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container justify={"center"} className={classes.logo}>
                            <LogoContrast/>
                        </Grid>
                    </Grid>

                    {/*<Typography align={"center"} className={classes.caption}>*/}
                    {/*    <i>Delivery solution ofered by FROMTO Delivery. ©{new Date().getFullYear()}</i>*/}
                    {/*</Typography>*/}
                </Container>
            </footer>
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#161618",
        paddingTop: 60,
        paddingBottom: 60,
    },
    caption: {
        fontSize: 14,
        lineHeight: "20px",
        fontWeight: 300,
        letterSpacing: 0,
        color: "#999999"
    },
    links:{
      paddingTop:theme.spacing(2),
    },
    link: {
        fontSize: 16,
        lineHeight: "24px",
        fontWeight: 300,
        letterSpacing: 0,
        color: "#FFFFFF",
        "&:not(:last-child):after": {
            content: `"|"`,
            display: "inline-block",
            padding: "0 4px",
            color: "#fff",
        }
    },
    logo:{
        paddingTop:theme.spacing(5),
    }
}));
