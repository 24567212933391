import React, {useState} from 'react'
import {Configure, InstantSearch} from 'react-instantsearch-dom';
import {makeStyles} from "@material-ui/core/styles";
import {indexName, nonEmptySearchClient} from "../constants/algolia";
import HomepageAlgoliaAutoComplete from "./ContentBlocks/Cars/HomepageAlgoliaAutoComplete";
import {autocompleteHits} from "../containers/CarsListPage/constants";

const SearchBox = (props) => {
    const classes = useStyles();
    const [openSearchDialog, setopenSearchDialog] = useState(false);

    const handleCloseSearchDialog = () => {
        setopenSearchDialog(false);
    };

    return (
        <div className={classes.root}>
            <div className={classes.searchbox}>
                <InstantSearch indexName={indexName} searchClient={nonEmptySearchClient}>
                    <HomepageAlgoliaAutoComplete
                        open={openSearchDialog}
                        onClose={handleCloseSearchDialog}
                    />
                    <Configure hitsPerPage={autocompleteHits}/>
                </InstantSearch>
            </div>
        </div>

    )
}


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#141414",
        padding: theme.spacing(2)
    },
    searchbox: {
        maxWidth: 648,
        margin: "0 auto",
        position: "relative",
        zIndex: 1000,
    },
}));

export default SearchBox;
