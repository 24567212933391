/**
 * Copy, change styles
 */

import React from 'react';


import {withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import _get from "lodash/get";

const StyledTabs = withStyles({
    root: {
        minHeight: "32px"
    },
    flexContainer: {},
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > div': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: 'transparent',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{children: <div/>}}/>);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(20),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minHeight: 0,
        opacity: 1,

        '&$selected': {
            color: theme.palette.secondary.contrastText,
            background: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightRegular,
            borderRadius: 60,
            minHeight: 0,
        }
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function TabsWidgetPrimary(props) {
    const labels = _get(props,"labels",[]);
    return (
        <Container>
            <Grid container>
                <Grid item container justify={"center"} xs={12}>
                    <StyledTabs
                        value={props.current > -1 ? props.current : 0}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example">
                        {labels.map((label, index) => {
                                return (
                                    <StyledTab
                                        key={index}
                                        onClick={() => window.scrollTo({
                                            top: props.positions[index] - 160,
                                            left: 0,
                                            behavior: 'smooth'
                                        })}
                                        label={label}
                                    />
                                )
                        })}
                    </StyledTabs>
                </Grid>
            </Grid>
        </Container>
    );
}
