import _get from "lodash/get"

/**
 * PARSE ERROR RESPONSE
 *
 * @param {Object} {errors} The response errors object
 * @returns {Object} Returns the error object if present, triggers a raven captureException
 */
export default function handleErrors(data) {
    const requestErrorStatus = _get(data,"request.status","unknown");

    if (requestErrorStatus == 401 || requestErrorStatus == 423) {
        return false;

    } else if (requestErrorStatus == 500 || requestErrorStatus == "unknown") {
        // Sentry.captureException(data);
    }

    const errors = _get(data, "response.data.errors", {});
    const message = _get(data, "response.data.message", '');

    return {
        errors: errors,
        message: message
    };
}


