import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import _uniqueId from "lodash/uniqueId"
import ProductCard from "../ProductCard";
import {SwiperSlide} from 'swiper/react';
import Slider from "./Slider";
/*demo image*/
import clsx from "clsx";
import _get from "lodash/get";
import _map from "lodash/map";
import toLocale from "../../helpers/toLocale";
import getSuppotedFileType from "../../helpers/getSuppotedFileType";
import {sliderStyle} from "../../theme/appStyle";

const BestOfferSlider = (props) => {
    const classes = useStyles();
    const paginationEl = _uniqueId("best-offers-pagination-");
    return (
        <div className={classes.sliderRoot}>
            <Slider
                spaceBetween={8}
                slidesPerView={1}
                slidesPerGroup={1}
                centeredSlides={false}
                // navigation
                pagination={{
                    el: `.${paginationEl}`,
                    dynamicBullets: false,
                    clickable: true
                }}
                // Disable preloading of all images
                preloadImages={false}
                // Enable lazy loading
                lazy={{
                    // loadPrevNext: true,
                    preloaderClass: "preloader-skeleton"
                }}
                breakpoints={{
                    // when window width is >= 768
                    420: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 16
                    },
                    640: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        spaceBetween: 16,
                        centeredSlides:false
                    },
                    // when window width is >= 1200:
                    1024: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 24
                    },
                    // when window width is >= 1600
                    1600: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 24
                    }
                }}
            >
                {_map(props.data, (item, index) => {
                    return <SwiperSlide key={index}>
                        <ProductCard
                            title={_get(item, "name", '')}
                            // image={_get(item,"car.photos",[])}
                            image={getSuppotedFileType(_get(item, "thumb", []))}
                            price={`${toLocale(_get(item, "car.client_invoice_price", ''))}€`}
                            to={_get(item, "car.slug", '')}
                            category={_get(item, "slug", '')}
                        />
                    </SwiperSlide>
                })}
            </Slider>
            <div className={clsx(paginationEl, classes.paginationContainer)}>
            </div>
        </div>

    );
};

const useStyles = makeStyles((theme) => ({
    ...sliderStyle(theme),
}))

export default BestOfferSlider
