import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
// import Link from '@material-ui/core/Link';
import {makeStyles} from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import {PAGES_SLUGS} from "../constants";
import toLocale from "../helpers/toLocale";
import LazyImage from "./LazyImage";

const ProductCardVariantTwo = (props) => {
    const classes = useStyles();
    const handleCloseModal = () => {
        setTimeout(() => {
            props.onClose()
        }, 100)
    }
    return <ButtonBase
        focusRipple
        component={RouterLink}
        onClick={handleCloseModal}
        to={`/${PAGES_SLUGS.PRODUCTS}?model=${props.to}`}
        className={classes.root}
    >
        {/*<div*/}
        {/*    className={classes.imageWrapper}*/}
        {/*>*/}
        {/*    <img src={props.image} className={classes.image}/>*/}
        {/*</div>*/}
        <LazyImage ratio={49.21} src={props.image} />
        <div className={classes.body}>
            <div>
                <Typography align={"center"} gutterBottom={false} component={'p'}
                            className={classes.title}>{props.title}</Typography>
            </div>
            <div>
                <Typography align={"center"} gutterBottom={false} component={"p"} className={classes.text}
                            align={"center"}>Incepand de la: <span
                    className={classes.price}>{toLocale(props.price)}€ + TVA</span></Typography>
            </div>
        </div>
    </ButtonBase>
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "block",
    },
    // imageWrapper: {
    //     width: "100%",
    //     paddingTop: "49.21%",
    //     position: "relative",
    //     background: "#fff",
    // },
    // image: {
    //     // objectFit: "cover",
    //     // objectPosition: '50% 50%',
    //     width: "100%",
    //     height: "100%",
    //     position: "absolute",
    //     top: "50%",
    //     left: "50%",
    //     transform: "translate(-50%,-50%)",
    // },

    body: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0),
    },

    title: {
        letterSpacing: "0.32px",
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(22),
        lineHeight: theme.typography.pxToRem(32),
    },
    text: {
        letterSpacing: "0",
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        paddingBottom: theme.spacing(2),
    },
    price: {
        color: theme.palette.primary.main,
    },

    link: {
        fontSize: "14px",
        lineHeight: "1",
        padding: `${theme.spacing(1) / 2}px`,
        position: "relative",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "none",
        },
        "&:before": {
            content: `''`,
            position: "absolute",
            width: "0",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            background: theme.palette.primary.main,
            transition: theme.transitions.create(['width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        "&:hover:before": {
            content: `''`,
            position: "absolute",
            width: "100%",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            background: theme.palette.primary.main
        }
    },
    icon: {
        fontSize: 10,
    },
}));
export default ProductCardVariantTwo;