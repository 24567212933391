import React, {Component} from 'react';
/**
 * Polyfill, but may not be needed
 * Only opera mini with 0,88% usage doesn't have support
 */
import "intersection-observer";


// 32 was the value used for a 64 height header
// const triggerOffset = 32;
const triggerOffset = -100;

class HeaderOfferObserver extends Component {
    /**
     * Quick fix to disable component when page is accessed by Prerender.io
     */
    state = {
        stickToTop: navigator.userAgent.toLocaleLowerCase().includes("prerender")
    };

    targetContainerRef = React.createRef();
    /**
     * rootMargin MUST always be smaller or equal than .intersectionObserver's height
     * threshold 0 -> 1 values representing percent (50% = 0.5)
     */

    options = {
        root: this.props.root || null,
        rootMargin: this.props.margin || `${triggerOffset}px`,
        threshold: this.props.threshold || 0
    };

    observer;

    componentDidMount() {
        this.observer = new IntersectionObserver((records, observer) => {
            for (const record of records) {
                const targetBounds = record.boundingClientRect;

                if (targetBounds.top < -triggerOffset) {
                    this.setState({ stickToTop: true });
                }
                if (targetBounds.top >= -triggerOffset) {
                    this.setState({ stickToTop: false });
                }
            }
        }, this.options);

        this.observer.observe(this.targetContainerRef.current);
    }

    componentWillUnmount() {
        this.observer.unobserve(this.targetContainerRef.current);
    }


    render() {
        const {children = null, className = '', stickyClass = '' } = this.props;
        return (
            <React.Fragment>
                <div
                    ref={this.targetContainerRef}> </div>
                <div
                    className={`${this.state.stickToTop ? stickyClass : ''} ${className}`}
                >
                    {children}
                </div>
            </React.Fragment>
        );
    }
}
export default HeaderOfferObserver;
