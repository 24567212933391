export const WL = {
    ACCESSORY: 'wlAccessories',
    CAR: 'wlCars',
    UUID: 'uuid'
}
/**
 * Add an item to wishlist array
 * @param {String} name  WL.ACCESSORY or WL.CAR
 * @param {String} value The slug of the item
 */
export const addToLocalStorageArray = (name, value) => {
    const wishlistArray = getWishlistData(name);

    // Push it if it's not present
    if (!wishlistArray.includes(value)) wishlistArray.push(value);

    localStorage.setItem(name, wishlistArray.toString());
};

export const removeFromLocalStorageArray = (name, value) => {
    let wishlistArray = getWishlistData(name);

    if (wishlistArray.includes(value)) wishlistArray = wishlistArray.filter(item => item !== value)
    localStorage.setItem(name, wishlistArray.toString());
};

export const emptyLocalStorageArray = (name) => {
    localStorage.removeItem(name);
};

export const getWishlistData = (name) => {
    // Get the stored wishlist
    const wishlistString = localStorage.getItem(name);
    // Create/convert array
    return wishlistString ? wishlistString.split(',') : [];
}
