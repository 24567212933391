import React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import placeholder from "../../images/placeholder.jpg"
import clsx from "clsx";
import Fade from "react-reveal/Fade";
import ReactHtmlParser from "react-html-parser";
import {Text} from "./Typografy";

const PacheteCard = ({image, title, content, reverse}) => {
    const classes = useStyles();

    return <Grid item xs={12} sm={12}>

        <Grid container spacing={3} alignItems={"center"} className={clsx({[classes.reverse]: reverse})}>
            <Grid item xs={12} sm={6} className={classes.imageRoot}>
                <Fade left={!reverse} right={reverse} distance={"40px"}>
                    <div
                        className={classes.imageWrapper}
                    >
                        {!!image && <img src={image} className={classes.image}/>}
                        {image == "" && <img src={placeholder} className={classes.image}/>}
                    </div>
                </Fade>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div className={classes.body}>
                    <Fade>
                        <div>
                            <Typography paragraph className={classes.title} align={"left"}>{title}</Typography>
                        </div>
                        <Text>
                            {ReactHtmlParser(content)}
                        </Text>
                    </Fade>
                </div>

            </Grid>
        </Grid>

    </Grid>
}

const useStyles = makeStyles((theme) => ({
    reverse: {},
    imageRoot: {
        "$reverse &": {
            [theme.breakpoints.up('sm')]: {
                order: 2,
                // marginRight: theme.spacing(0),
                // marginLeft: theme.spacing(3),
            },

        },
    },
    imageWrapper: {
        width: "100%",
        marginBottom: theme.spacing(3),
        position: "relative",
        paddingTop: "68.36%",
        background: "#fafafa",
    },
    image: {
        objectFit: "cover",
        objectPosition: '50% 50%',
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
    },

    body: {
        // marginLeft: theme.spacing(3),
        // marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(0),
        },
        [theme.breakpoints.down('xs')]: {

            paddingBottom: theme.spacing(2),
        },
    },

    title: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(26),
        lineHeight: theme.typography.pxToRem(32),
        letterSpacing: "0.4px",
        marginBottom: theme.spacing(2),
    },


}));
export default PacheteCard;