import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {contrastColor} from "../theme";

const SectionBox = ({children,  contrast, gutterBottom=true, gutterTop=true}) => {
    const classes = useStyles();

    return <div className={clsx(classes.sectionBoxRoot, {
        [classes.gutterBottom]: gutterBottom,
        [classes.gutterTop]: gutterTop,
        [classes.contrast]: contrast,
        ["contrast-section"]: contrast,
    })}>
        {children}
    </div>
}

const useStyles = makeStyles((theme) => ({
    sectionBoxRoot: {
        position: "relative",
    },
    contrast: {
        backgroundColor: contrastColor,
    },
    gutterBottom: {
        paddingBottom:  theme.spacing(4),
    },
    gutterTop: {
        paddingTop: theme.spacing(5),
    },

}));
export default SectionBox
