import axios from 'axios';
import _get from "lodash/get";
import * as ActionTypes from './constants';
import {API_ACCESSORY_DETAILS} from "../../api";
import handleErrors from "../../helpers/handleErrors";
import setHeaders from "../../helpers/setHeaders";

export function fetchAccessory(slug) {
    return function (dispatch) {
        dispatch({
            type: ActionTypes.ACCESSORY_REQUEST
        });
        axios.get(`${API_ACCESSORY_DETAILS}/${slug}`, setHeaders()).then((response) => {
            dispatch({
                type: ActionTypes.ACCESSORY_SUCCESS,
                payload: _get(response, "data.data", {})
            });
        }).catch((response) => {
            const errorResponse = _get(response, 'request.status', 'unknown');
            dispatch({
                type: ActionTypes.ACCESSORY_ERROR,
                payload: {slug: slug, errorResponse: errorResponse}
            });
        });
    }
}
