import axios from 'axios';
import _get from "lodash/get";
import * as ActionTypes from '../constants/actionTypes';
import * as api from '../api';
import setHeaders from "../helpers/setHeaders";
import handleErrors from "../helpers/handleErrors";

export function fetchProduct(slug) {
    return function (dispatch) {
        dispatch({
            type: ActionTypes.PRODUCT_REQUEST
        });
        axios.get(`${api.API_PRODUCT}/${slug}`, setHeaders()).then((response) => {
            dispatch({
                type: ActionTypes.PRODUCT_SUCCESS,
                payload: _get(response, "data.data", {})
            });
        }).catch((response) => {
            const errorResponse = _get(response, 'request.status', 'unknown');
            dispatch({
                type: ActionTypes.PRODUCT_ERROR,
                payload: {slug: slug, errorResponse: errorResponse}
            });
        });
    }
}


export function fetchAllModels() {
    return function (dispatch) {

        dispatch({
            type: ActionTypes.ALL_MODELS_REQUEST
        });
        // return new Promise(
        //     (resolve, reject) => {
        axios.get(`${api.API_MODELS_LIST}`, setHeaders()).then((response) => {
            dispatch({
                type: ActionTypes.ALL_MODELS_SUCCESS,
                payload: _get(response, "data.data", {})
            });
            // resolve(data.data);
        }).catch((response) => {
            const {errors, message} = handleErrors(response);
            dispatch({
                type: ActionTypes.ALL_MODELS_ERROR,
                payload: errors
            });
            // reject();
        });
        // })
    }
}


export function fetchDealerships() {
    return function (dispatch) {
        dispatch({
            type: ActionTypes.DEALERSHIPS_REQUEST
        });

        axios.get(`${api.API_DEALERSHIPS_LIST}`, setHeaders()).then((response) => {
            dispatch({
                type: ActionTypes.DEALERSHIPS_SUCCESS,
                payload: _get(response, "data.data", {})
            });
        }).catch((response) => {
            const {errors, message} = handleErrors(response);
            dispatch({
                type: ActionTypes.DEALERSHIPS_ERROR,
                payload: errors
            });
        });
    }
}


export function fetchBestPriceByModel() {
    return function (dispatch) {

        dispatch({
            type: ActionTypes.BEST_PRICE_REQUEST
        });
        // return new Promise(
        //     (resolve, reject) => {
        axios.get(`${api.API_BEST_PRICE_BY_MODEL}`, setHeaders()).then((response) => {
            dispatch({
                type: ActionTypes.BEST_PRICE_SUCCESS,
                payload: _get(response, "data.data", {})
            });
            // resolve(data.data);
        }).catch((response) => {
            const {errors, message} = handleErrors(response);
            dispatch({
                type: ActionTypes.BEST_PRICE_ERROR,
                payload: errors
            });
            // reject();
        });
        // })
    }
}

export function fetchBestOfferByModel() {
    return function (dispatch) {

        dispatch({
            type: ActionTypes.BEST_OFFER_REQUEST
        });
        // return new Promise(
        //     (resolve, reject) => {
        axios.get(`${api.API_BEST_OFFER_BY_MODEL}`, setHeaders()).then((response) => {
            dispatch({
                type: ActionTypes.BEST_OFFER_SUCCESS,
                payload: _get(response, "data.data", {})
            });
            // resolve(data.data);
        }).catch((response) => {
            const {errors, message} = handleErrors(response);
            dispatch({
                type: ActionTypes.BEST_OFFER_ERROR,
                payload: errors
            });
            // reject();
        });
        // })
    }
}
