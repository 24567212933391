import * as ActionTypes from './constants';
import {getWishlistData, WL} from "../../helpers/localStorage";

/**
 * request: boolean when adding and removing items to WL. Used to add loader on buttons
 * cars: [] slug array from localStorage
 * accessories: [] slug array from localStorage
 * uuid: string | null uuid from localStorage; used to generate share link
 * offersSlugArray: [] array used for accessory request page. It can either contain 1 item (click from acc details) or multiples ones (requesting offer from WL page)
 * pageData: {cars: [], accessories: [], uuid: null} data used to render WL page; it can either use localStorage values or from API (using passed uuid from URL)
 * wishlistRequest: boolean indicates that the WL data is fetched from the API (WL page with uuid and multiple acc offer request)
 */
export const initialState = {
    request: false,
    accessories: getWishlistData(WL.ACCESSORY),
    cars: getWishlistData(WL.CAR),
    uuid: localStorage.getItem(WL.UUID) || null,
    pageData: {
        accessories: [],
        cars: [],
        uuid: null
    },
    wishlistRequest: false,
    offersSlugArray: []
};

export default function wishlist(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.WISHLIST_UUID_REQUEST:
            return {
                ...state,
                request: true
            };

        case ActionTypes.WISHLIST_REQUEST:
            return {
                ...state,
                wishlistRequest: true
            };

        case ActionTypes.UPDATE_WISHLIST:
            return {
                ...state,
                accessories: getWishlistData(WL.ACCESSORY),
                cars: getWishlistData(WL.CAR),
                uuid: action.payload,
                pageData: {
                    accessories: getWishlistData(WL.ACCESSORY),
                    cars: getWishlistData(WL.CAR),
                    uuid: action.payload,
                },
                request: false
            };

        case ActionTypes.UUID_TO_SLUGS:
            return {
                ...state,
                offersSlugArray: action.payload,
                wishlistRequest: false
            };

        case ActionTypes.ADD_SLUG:
            return {
                ...state,
                offersSlugArray: action.payload
            };

        case ActionTypes.USE_LOCAL_STORAGE:
            return {
                ...state,
                pageData: {
                    accessories: getWishlistData(WL.ACCESSORY),
                    cars: getWishlistData(WL.CAR),
                    uuid: localStorage.getItem(WL.UUID),
                }
            };

        case ActionTypes.DATA_FROM_UUID:
            return {
                ...state,
                wishlistRequest: false,
                pageData: {
                    accessories: action.payload.accessories,
                    cars: action.payload.cars,
                    uuid: action.payload.uuid
                }
            };

        default :
            return state;
    }
}
