import {appStyle} from "./appStyle";

const filterStyles = theme => ({
    ...appStyle(theme),
    root: {
        width: "100%",
        '&:not(:first-of-type)': {
            borderTop: `1px solid #eee`
        },
    },
    mechanicsTitle: {
        display: `inline-block`,
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(4)
    },

    formControl: {
        width: `100%`,
        "& $formControl": {
            paddingLeft: theme.spacing(2)
        }
    },
    filterBox: {
        width: "100%"
    },
    expansionRoot: {
        border: 'none',
        boxShadow: 'none',

        // Only required see more accordion/button (no idea why!)
        margin: `0 !important`,
        '&:before': {
            content: 'none'
        }
    },
    expansionButton: {
        minHeight: `0 !important`,
        padding: 0,
        margin: `16px 0 8px`,
    },
    expansionButtonContent: {
        margin: `0 !important`,
    },
    selectedFilters: {},
    categoryTitle: {
        lineHeight: 1.5,
        fontWeight: 500,
        '&:first-letter': {
            textTransform: `capitalize`,
        },

        '&$selectedFilters::after': {
            content: `''`,
            width: theme.spacing(1),
            height: theme.spacing(1),
            borderRadius: theme.spacing(1),
            marginLeft: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
            display: `inline-block`,
            lineHeight: `inherit`,
            verticalAlign: `super`,
        }
    },
    pickDealer: {
        display: `block`,
        marginTop: theme.spacing(3)
    },
    tag: {
        paddingLeft: `${theme.spacing(2)}px !important`,
        marginBottom: theme.spacing(2),
        fontSize: `0.75rem`,
        height: `24px !important`,
        letterSpacing: .32,
        boxShadow: "none",
        '&:not(:last-child)': {
            marginRight: theme.spacing(1),
        },

        '& .brand-icon-close': {
            display: `inline-block`,
            marginLeft: theme.spacing(2),
            fontSize: `0.5rem`
        }

    },
    tabLabel: {
        textTransform: "capitalize"
    },
    icon: {
        padding: 0,
        alignSelf: `stretch`,
        marginRight: 0,
        color: theme.palette.primary.main,
        fontSize: `1rem`,
    },
    formLabel: {
        marginLeft: 0,
        lineHeight: `20px`,
        marginBottom: theme.spacing(2)
    },
    details: {
        padding: 0,
        marginTop: theme.spacing(2)
    },
    smallerPadding: {
        padding: 0
    },
    customLabel: {
        marginLeft: theme.spacing(1),
        fontSize: `0.875rem`
    },
    resetButton: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
        display: `block`
    },
    disabled: {
        color: `#CCCCCC`,
        pointerEvents: `none`
    },
    sortButton: {
        justifyContent: `space-between`,
        padding: theme.spacing(0, 1, 0, 1),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 1.5, 0, 2)
        }
    },
    openSortButtonStyle: {
        backgroundColor: `${theme.palette.secondary.main} !important`,
        color: theme.palette.secondary.contrastText,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,

        '& $sortIcon, & $sortButtonText': {
            color: theme.palette.secondary.contrastText
        }
    },
    sortButtonText: {
        textTransform: `initial`,
        margin: `11px 0 11px 0`,
        display: `flex`,
        alignItems: `center`,

        [theme.breakpoints.down('xs')]: {
            fontSize: `0.9rem`
        },
    },
    iconRightMargin: {
        marginRight: theme.spacing(0.5),
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(2)
        },
    },
    sortIcon: {
        fontSize: `1rem`
    },
    hidden: {
        display: 'none',
    }
});

export default filterStyles;
