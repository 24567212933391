import React from 'react';
import trimCharacters from 'trim-characters';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";

const OverviewCard = ({image, title, description, reverse = false}) => {
    const classes = useStyles();
    return <Grid item container xs={12} md={6} className={clsx(classes.grid, {
        [classes.reverse]: reverse,
    })}>
        <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            className={clsx(classes.root, {
                [classes.reverse]: reverse,
            })}
        >
            <Avatar alt="Remy Sharp" src={image} className={classes.avatar} classes={{root: classes.rootAvatar}}/>
            <div className={classes.body}>
                {title && <Typography component={"h2"} className={classes.title}>{title}</Typography>}
                {description && <Typography className={classes.text}>{trimCharacters(description, 70, false, ' ...')}</Typography>}
            </div>
        </Box>
    </Grid>
}
const useStyles = makeStyles((theme) => ({
    grid: {
        justifyContent: "flex-start",
        "&$reverse": {
            [theme.breakpoints.up('lg')]: {
                justifyContent: "flex-end",
            },
        },
        "&:nth-child(even) ": {
            [theme.breakpoints.down('md')]: {
                justifyContent: "flex-end",
            },
        },
    },
    root: {
        padding: theme.spacing(3),

        [theme.breakpoints.up('md')]: {
            width: "80%",
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
    rootAvatar: {
        border: "10px solid #fff",
        [theme.breakpoints.down('sm')]: {
            border: "5px solid #fff",
        },
    },
    avatar: {
        width: 180,
        height: 180,
        marginRight: theme.spacing(3),
        "$reverse &": {
            [theme.breakpoints.up('lg')]: {
                order: 2,
                marginRight: theme.spacing(0),
                marginLeft: theme.spacing(3),
            },

        },
        "$grid:nth-child(even) &": {
            [theme.breakpoints.down('md')]: {
                order: 2,
                marginRight: theme.spacing(0),
                marginLeft: theme.spacing(3),
            },

        },

        [theme.breakpoints.down('sm')]: {
            width: 90,
            height: 90,
        },
    },
    body: {
        "$reverse &": {
            [theme.breakpoints.up('lg')]: {
                textAlign: "right"
            },
        },
        "$grid:nth-child(even) &": {
            [theme.breakpoints.down('md')]: {
                textAlign: "right"
            },
        }
    },
    title: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(22),
        lineHeight: theme.typography.pxToRem(32),
        paddingBottom: theme.spacing(2),
        ".contrast-section &": {
            color: "#fff",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(18),
            lineHeight: theme.typography.pxToRem(24),
        },
    },
    titlePlaceholder: {
        height: 48,
    },
    text: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(24),
        paddingBottom: theme.spacing(0),
        ".contrast-section &": {
            color: "#fff",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(20),
        },
    },
    reverse: {},
    even: {},

}));
export default OverviewCard;
