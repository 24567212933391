import React, {useEffect} from 'react'
import clsx from "clsx";
import {useDispatch, useSelector} from 'react-redux'
import Fade from "react-reveal/Fade";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';

import * as actions from "../../actions/pages"
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SectionBox from "../../components/SectionBox";

import ProgressFullScreen from "../../components/ProgressFullScreen";
import {PAGES_SLUGS} from "../../constants";
import Seo from "../../components/seo/Seo";
import ReactHtmlParser from "react-html-parser";
import _map from "lodash/map";
import DealershipCard from "../../components/DealershipCard";

function AboutUsPage(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const pageData = useSelector(state => _get(state.pages.data, PAGES_SLUGS.DEALERSHIPS, {}));
    const loading = useSelector(state => state.pages.request);
    const error = useSelector(state => state.pages.error);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (_isEmpty(pageData)) {
            dispatch(actions.fetchPage(PAGES_SLUGS.DEALERSHIPS))
        }
    }, [])

    return (
        <React.Fragment>
            {!_isEmpty(_get(pageData, 'data.meta_data', {})) && <Seo data={pageData.data.meta_data}/>}
            {!loading && <>
                {!_isEmpty(_get(pageData, 'data.header_section', {})) && <Fade>

                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        flexDirection={"column"}
                        className={classes.banner}
                        style={{
                            "--background-image": `url(${_get(pageData, 'data.header_section.header_image', "")})`
                        }}
                    >
                        <Fade delay={1000}>
                            <Typography component={'div'}
                                        className={classes.bannerTitle}>{ReactHtmlParser(_get(pageData, "data.header_section.title", ''))}</Typography>
                            <Typography align={"center"} component={'div'}
                                        className={classes.bannerText}>{ReactHtmlParser(_get(pageData, "data.header_section.subtitle", ''))}</Typography>
                        </Fade>
                    </Box>

                </Fade>}
                {!_isEmpty(_get(pageData, 'data.dealerships_section.content', {})) && <SectionBox>

                        <Container>

                            <Grid container spacing={3} className={classes.container}>
                                {_map(_get(pageData, 'data.dealerships_section.content', []), (item, index) => {
                                    return <DealershipCard
                                        key={_get(item, "attributes.data.id", index)}
                                        data={_get(item, "attributes.data", {})}
                                        image={_get(item, "attributes.section_image", '')}
                                    />
                                })}
                            </Grid>

                        </Container>

                </SectionBox>}

            </>}
            {loading &&
            <ProgressFullScreen loading={loading}/>
            }
        </React.Fragment>
    )
}


const useStyles = makeStyles((theme) => ({

    banner: {
        backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.35), rgba(0,0,0,0.35)), var(--background-image)`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        minHeight: "calc(100vh - 64px)",
        padding: theme.spacing(3),
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            minHeight: 350,
        },
        // "&:before":{
        //     content:`""`,
        //     position:"absolute",
        //     top:0,
        //     left:0,
        //     bottom:0,
        //     right:0,
        //     background:"rgba(0,0,0,0.35)"
        //
        // }
    },
    container: {
        "& > div:nth-child(odd)": {}
    },
    bannerTitle: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(38),
        lineHeight: theme.typography.pxToRem(56),
        letterSpacing: "0.4",
        color: "#fff",
    },
    bannerText: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(24),
        color: "#fff",
        [theme.breakpoints.up('md')]: {
            maxWidth: 350,
        },
    },


}))


export default AboutUsPage;
