import React from 'react';
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import {dealershipFacetName} from "../constants/algolia";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import Fade from "react-reveal/Fade";
import ReactHtmlParser from "react-html-parser";
import {Text} from "./Typografy";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import {Link as RouterLink} from "react-router-dom";
import {PAGES_SLUGS} from "../constants";
import {appStyle} from "../theme/appStyle";

const DealershipsCard = (props) => {
    const {data} = props;
    const classes = useStyles();

    return <Grid item xs={12} sm={12} className={classes.root}>
        <Grid container spacing={3} alignItems={"center"}>
            <Grid item xs={12} sm={6}>
                <Fade>
                    <div className={classes.body}>

                        <div>
                            <Typography paragraph className={classes.name}
                                        align={"left"}>{_get(data, "name", '')}</Typography>
                        </div>
                        {!_isEmpty(_get(data, 'showroom_info', {})) && <div className={classes.box}>
                            <Typography component={"div"} className={classes.subtitle}>Showroom</Typography>
                            <Typography component={'div'} gutterBottom={true}
                                        variant={"body1"}>{_get(data, 'showroom_address', "")}</Typography>
                            <Text>
                                {ReactHtmlParser(_get(data, "showroom_info", ''))}
                            </Text>
                        </div>}

                        {!_isEmpty(_get(data, 'service_info', {})) && <div className={classes.box}>
                            <Typography component={"div"} className={classes.subtitle}>Service</Typography>
                            <Typography component={'div'} gutterBottom={true}
                                        variant={"body1"}>{_get(data, 'service_address', "")}</Typography>
                            <Text>
                                {ReactHtmlParser(_get(data, "service_info", ''))}
                            </Text>
                        </div>}

                    </div>
                </Fade>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.imageRoot}>
                <Fade right distance={"40px"}>
                    <div
                        className={classes.imageWrapper}
                    >
                        <img src={props.image} className={classes.image}/>

                    </div>
                </Fade>
            </Grid>
        </Grid>
        <Divider/>
        <Box display={"flex"} flexWrap={"wrap"} justifyContent={"center"} alignItems={"center"}>
            <ButtonBase
                focusRipple
                component={RouterLink}
                to={`/${PAGES_SLUGS.PRODUCTS}?${dealershipFacetName}=${_get(data, "name", '')}`}
                className={classes.underlineLinkButton}
            >
                <Box display={"flex"} alignItems={"center"} className={classes.buttonItem}>
                    <Icon className={clsx("brand-icon-offers", classes.icon)}/>
                    Vezi toate oferte dealerului
                </Box>
            </ButtonBase>

            {!_isEmpty(_get(data, "showroom_url", null)) && <ButtonBase
                focusRipple
                component={Link}
                href={_get(data, "showroom_url", '')}
                target={"_blank"}
                className={classes.underlineLinkButton}
            >
                <Box display={"flex"} alignItems={"center"} className={classes.buttonItem}>
                    <Icon className={clsx("brand-icon-directions", classes.icon)}/>
                    Mergi la Showroom
                </Box>
            </ButtonBase>}

            {!_isEmpty(_get(data, "service_url", null)) && <ButtonBase
                focusRipple
                component={Link}
                href={_get(data, "service_url", '')}
                target={"_blank"}
                className={classes.underlineLinkButton}
            >
                <Box display={"flex"} alignItems={"center"} className={classes.buttonItem}>
                    <Icon className={clsx("brand-icon-directions", classes.icon)}/>
                    Mergi la Service
                </Box>
            </ButtonBase>}
        </Box>
        <Divider/>
    </Grid>
}

const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    root: {
        marginBottom: theme.spacing(4),
    },

    imageWrapper: {
        width: "100%",
        marginBottom: theme.spacing(3),
        position: "relative",
        paddingTop: "68.36%",
        background: "#fafafa",
        [theme.breakpoints.down('sm')]: {
            order: 2
        },
    },

    image: {
        objectFit: "cover",
        objectPosition: '50% 50%',
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
    },
    name: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(26),
        lineHeight: theme.typography.pxToRem(32),
        letterSpacing: "0.4px",
        marginBottom: theme.spacing(2),
    },

    body: {
        // marginLeft: theme.spacing(3),
        // marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(0),
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(2),
        },
    },
    box: {
        paddingBottom: theme.spacing(2),
    },


    subtitle: {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(28),
        letterSpacing: "0.24px",
        marginBottom: theme.spacing(1),
    },
    itemText: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(24),
    }
}));
export default DealershipsCard;
