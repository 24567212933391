import React, {Component} from 'react';
import {connectRange} from 'react-instantsearch-dom';
import {Box, withStyles} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import filterStyles from "../../../theme/filterStyles";

const customStyles = theme => ({
    ...filterStyles(theme),
    inputContainer: {
        height: 32,
        marginBottom: theme.spacing(2)
    },
    dash: {
        width: 32,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        textAlign: `center`
    },
    priceInput: {
        width: 1,
        flex: 1,
        fontFamily: 'Volvo Novum,Arial,sans-serif',
        fontSize: 14,
        lineHeight: `20px`,
        padding: 6,
        border: `1px solid #ccc`,
    }
});

class StockRangeInput extends Component {
    state = {
        min: '',
        max: ''
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.currentRefinement.min !== this.props.currentRefinement.min
            || prevProps.currentRefinement.max !== this.props.currentRefinement.max
        ) {
            this.setFiltersValues();
        }
    }
    setFiltersValues = () => {
        const priceRange = this.props.currentRefinement;
        if (!priceRange.min && !priceRange.max) return null;

        /**
         * TODO: Algolia sends min and max values from the API (default?) which overwrite the currentRefinement object's values
         * We'll overwrite those with undefined when initializing and when filtering
         */
        const algoliaStatsMin = this.props.min;
        const algoliaStatsMax = this.props.max;
        priceRange.min = (priceRange.min === algoliaStatsMin)
            ? undefined
            : priceRange.min;
        priceRange.max = (priceRange.max === algoliaStatsMax)
            ? undefined
            : priceRange.max;
        /**
         * There will be "issues" when an interval contains values outside the min-max range
         * The URL will be set to 0-max instead of min-max
         */

        if (priceRange.min === undefined && priceRange.max === undefined) {
            /**
             * If the input was cleared from navigation (or anything else?)
             * No need to check if state is already emptied; it runs the same number of times,
             * and it doesn't re-trigger renders
             */
            this.setState({
                min: '',
                max: ''
            });

            return null;
        }

        if (priceRange.min === undefined && priceRange.max !== undefined) {
            /// If there's only a max value
            this.setState({
                max: priceRange.max,
            });

        } else if (priceRange.min !== undefined && priceRange.max === undefined) {
            /// If there's only a min value or an exceeding range was selected (50k-200k)
            this.setState({
                min: priceRange.min,
            });
        } else if (priceRange.min !== undefined && priceRange.max !== undefined) {
            /// If both values are present
            this.setState({
                min: priceRange.min,
                max: priceRange.max,
            });
        }
    }

    render() {
        const {currentRefinement, min, max, classes, expanded} = this.props;
        return (
            <div className={`${classes.root} ${classes.hidden}`}>
                <div className={classes.filterBox}>
                    <Accordion defaultExpanded={expanded} classes={{root: classes.expansionRoot}}>
                        <AccordionSummary
                            classes={{
                                root: classes.expansionButton,
                                content: classes.expansionButtonContent,
                                expandIcon: classes.icon
                            }}
                            expandIcon={<span className={`brand-icon-down-arrow`}/>}>
                            <Typography variant={"body1"}
                                        className={`${classes.categoryTitle} ${(currentRefinement.min || currentRefinement.max) && classes.selectedFilters}`}>
                                Available
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                    <Box
                                        className={classes.inputContainer}
                                        display="flex"
                                        flexDirection="row">
                                        <input
                                            className={classes.priceInput}
                                            type="number"
                                            value={this.state.min || ''}
                                            onChange={() => {}}
                                        />
                                        <Box alignSelf={"center"} className={classes.dash}>-</Box>
                                        <input
                                            className={classes.priceInput}
                                            type="number"
                                            min={min}
                                            max={max}
                                            value={this.state.max || ''}
                                            onChange={() => {}}
                                        />
                                    </Box>

                                </FormGroup>
                            </FormControl>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        );
    }
}

StockRangeInput = withStyles(customStyles)(StockRangeInput);

StockRangeInput = connectRange(StockRangeInput);
export default StockRangeInput;
