import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import {Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form'
import * as Yup from 'yup';
import {Checkboxes, DatePicker, makeRequired, makeValidate, Select, TextField} from 'mui-rff';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import grey from "@material-ui/core/colors/grey";
import {createMuiTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';

import roLocale from "date-fns/locale/ro";
import Container from "@material-ui/core/Container";
import Fade from "react-reveal/Fade";
import Link from "@material-ui/core/Link";
import * as actions from "../../actions/products";
import {submitTestdriveForm} from "../../actions/forms";
import {useDispatch, useSelector} from "react-redux";
import {Text} from "../Typografy";
import ReactHtmlParser from "react-html-parser";
import {PAGES_SLUGS} from "../../constants";
import {
    GTM_TESTDRIVE_INIT_FORM_PARAMS,
    GTM_TESTDRIVE_SUCCESS_FORM_PARAMS,
    withGtmTraking
} from "../../constants/tracking";


const TestDriveSection = (props) => {
    const {data} = props;
    const classes = useStyles();
    const [age, setAge] = React.useState('');

    const subscription = {submitting: true};
    const [subscriptionState, setSubscriptionState] = useState(subscription);

    const onChange = () => {
        setSubscriptionState(subscriptionState === undefined ? subscription : undefined);
    };

    return (
        <div className={classes.root}>
            <Fade>
                <Container>
                    <Grid container alignItems="stretch" spacing={0} className={classes.container}>
                        <Grid item sm={6} xs={12}>
                            <img src={_get(data, 'side_image', '')} className={classes.image}/>
                        </Grid>
                        <Grid item sm={6} xs={12} container alignItems="center">
                            <Box className={classes.titleBox}>
                                <Typography
                                    gutterBottom
                                    variant="h2"
                                    component="h2"
                                    className={classes.title}
                                >{ReactHtmlParser(_get(data, 'title', ''))}</Typography>
                                <Text>
                                    {ReactHtmlParser(_get(data, 'content', ''))}
                                </Text>
                            </Box>
                        </Grid>
                    </Grid>

                    <MainForm subscription={subscriptionState}/>
                </Container>
            </Fade>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#FAFAFA",
    },
    container: {
        width: "100%",
    },
    image: {
        width: "100%",
        objectFit: "cover",
        objectPosition: '50% 50%',
    },
    titleBox: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingLeft: 88,
        marginLeft: -88,
        background: "#fff",
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            marginLeft: 0
        },
    },
    bannerTitle: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(38),
        lineHeight: theme.typography.pxToRem(56),
        letterSpacing: "0.4",
        color: "#fff",
    },
    title: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(26),
        lineHeight: theme.typography.pxToRem(32),
        letterSpacing: "0.4px",
        marginBottom: theme.spacing(2),
    },
    text: {
        "& p": {
            color: theme.palette.secondary.main,
            fontWeight: theme.typography.fontWeightLight,
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(24),
            letterSpacing: "0",
            marginBottom: theme.spacing(2),
            wordBreak: "break-word",
        },
        "& ul": {
            paddingLeft: theme.spacing(3),
            "& li": {
                color: theme.palette.secondary.main,
                fontWeight: theme.typography.fontWeightLight,
                fontSize: theme.typography.pxToRem(16),
                lineHeight: theme.typography.pxToRem(24),
                letterSpacing: "0",
                marginBottom: theme.spacing(0),
                wordBreak: "break-word",
            }
        },
        "& a": {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightLight,
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(24),
            letterSpacing: "0",
            marginBottom: theme.spacing(0),
            wordBreak: "break-word",
            position: 'relative',
            textDecoration: "none",
            "&:hover": {
                textDecoration: "none",
            },
            "&:before": {
                content: `''`,
                position: "absolute",
                width: "0",
                height: "1px",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                background: theme.palette.primary.main,
                transition: theme.transitions.create(['width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            "&:hover:before": {
                content: `''`,
                position: "absolute",
                width: "100%",
                height: "1px",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                background: theme.palette.primary.main
            }
        }
    },
}));


const schema = Yup.object().shape({
    model_id: Yup.number().required("Camp obligatoriu"),
    dealership_id: Yup.number().required("Camp obligatoriu"),
    proposed_date: Yup.date().required("Camp obligatoriu"),
    first_name: Yup.string().required("Camp obligatoriu"),
    last_name: Yup.string().required("Camp obligatoriu"),
    email: Yup.string().required("Camp obligatoriu").email("Email invalid"),
    phone: Yup.string().required("Camp obligatoriu").matches(/^[0-9]{10}$/, 'Telefon invalid'),
    terms: Yup.boolean()
        .oneOf([true], 'Camp obligatoriu')
        .required("Camp obligatoriu"),
});

/**
 * Uses the optional helper makeValidate function to format the error messages
 * into something usable by final form.
 */
const validate = makeValidate(schema);

/**
 * Grabs all the required fields from the schema so that they can be passed into
 * the components without having to declare them in both the schema and the component.
 */
const required = makeRequired(schema);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function MainForm({subscription}) {

    const classes = useFormStyles();
    const history = useHistory();
    const [openTermsDialog, setOpenTermsDialog] = useState(false);
    const [currentModel, setCurrentModel] = useState('');
    const [currentDealership, setCurrentDealership] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openTyDialog, setOpenTyDialog] = useState(false);
    const dispatch = useDispatch();

    const allModels = useSelector(state => state.modelsList.data);
    // const allModelsLoading = useSelector(state => state.modelsList.request);
    // const allModelsError = useSelector(state => state.modelsList.error);

    const allDealerships = useSelector(state => state.dealerships.data);
    const allDealersLoading = useSelector(state => state.dealerships.request);
    // const allDealersError = useSelector(state => state.dealerships.error);

    useEffect(() => {
        dispatch(actions.fetchAllModels())
        dispatch(actions.fetchDealerships())
    }, [])

    useEffect(() => {
        if (window.snowplow) {
            window.snowplow('enableFormTracking');
        }
    }, []);

    const getModels = () => {
        if (_isEmpty(allModels)) {
            return [];
        } else {
            let options = [
                {label: 'Alege...', value: '', disabled: true},
            ];
            _map(allModels, (item) => {
                options.push(
                    {label: item.name, value: item.id}
                )
            })
            // return options;
            return _map(options, (item) => {
                return <MenuItem key={item.value} onClick={(e) => {
                    // handleModelChange(item.value);
                }} value={item.value}>{item.label}</MenuItem>
            })
        }
    }

    const getDealerships = () => {
        if (_isEmpty(allDealerships)) {
            return [];
        } else {
            let options = [
                {label: 'Alege...', value: '', disabled: true},
            ];
            _map(allDealerships, (item) => {
                options.push(
                    {label: item.name, value: item.id}
                )
            })
            return options;
        }
    }

    const initialValues = {
        // best: [],
        // terms: false,
        // gender: '',
        // birthday: new Date('2014-08-18'),
    };

    const onSubmit = async (values, form) => {
        try {
            await dispatch(submitTestdriveForm(values))
            // setTimeout(form.restart);
            // setOpenTyDialog(true);
            history.push(`/${PAGES_SLUGS.TESTDRIVE_THANK_YOU}`);
        } catch (errors) {
            setOpenSnackbar(true);
            return {[FORM_ERROR]: _get(errors, "message", "Opps, Submit faild!")}
        }
    };

    const handleTermsAndConditionsDialog = () => {
        // console.log("checked:",value)
        setOpenTermsDialog(!openTermsDialog);
    };

    /*Error notification*/

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const _handleTyDialogClose = () => {
        setOpenTyDialog(false);
    };
    return (
        <div className={classes.formRoot}>
            <Form
                onSubmit={onSubmit}
                // initialValues={submittedValues ? submittedValues : initialValues}
                subscription={subscription}
                validate={validate}
                key={subscription}
                render={({
                             submitError,
                             handleSubmit,
                             form,
                             submitting,
                             pristine,
                             values
                         }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate={true} autoComplete="new-password"
                              id="test_drive_form">
                            <Grid container spacing={3} className={classes.formRow}>
                                <Grid item sm={4} xs={12}>

                                    <Select
                                        label="Selecteaza model"
                                        name="model_id"
                                        required={required.model_id}
                                        // data={getModels()}
                                        variant="outlined"
                                        inputProps={{
                                            classes: {
                                                root: classes.inputRoot,
                                                icon: classes.dropdownIcon
                                            }
                                        }}
                                        IconComponent={ExpandMoreIcon}
                                        // value={currentModel}
                                        // onChange={handleModelChange}
                                        // InputProps={{
                                        //     startAdornment: (
                                        //         <InputAdornment position="start">
                                        //             <Icon
                                        //                 className={clsx("brand-icon-car", classes.icon)}/>
                                        //         </InputAdornment>
                                        //     ),
                                        // }}
                                    >
                                        {getModels()}
                                    </Select>

                                </Grid>

                                <Grid item sm={4} xs={12}>
                                    <Select
                                        label="Selecteaza dealer"
                                        name="dealership_id"
                                        required={required.dealership_id}
                                        data={getDealerships()}
                                        variant="outlined"
                                        inputProps={{
                                            classes: {
                                                root: classes.inputRoot,
                                                icon: classes.dropdownIcon
                                            }
                                        }}
                                        IconComponent={ExpandMoreIcon}
                                        disabled={/*currentModel == "" ||*/ allDealersLoading}

                                        // InputProps={{
                                        //     startAdornment: (
                                        //         <InputAdornment position="start">
                                        //             <Icon
                                        //                 className={clsx("brand-icon-dealer", classes.icon)}/>
                                        //         </InputAdornment>
                                        //     ),
                                        // }}
                                    />
                                </Grid>

                                <Grid item sm={4} xs={12}>
                                    <ThemeProvider theme={datePickerTheme}>
                                        <DatePicker
                                            // disableToolbar
                                            // variant="inline"
                                            // orientation="landscape"
                                            autoOk
                                            format="dd/MM/yyyy"
                                            locale={roLocale}
                                            label="Data dorita"
                                            name="proposed_date"
                                            required={required.proposed_date}
                                            dateFunsUtils={DateFnsUtils}
                                            inputVariant="outlined"
                                            InputProps={{
                                                classes: {
                                                    root: classes.inputRoot
                                                }
                                            }}
                                            minDate={new Date()}
                                            // InputProps={{
                                            //     startAdornment: (
                                            //         <InputAdornment position="start">
                                            //
                                            //             <Icon
                                            //                 className={clsx("brand-icon-date", classes.icon)}/>
                                            //
                                            //         </InputAdornment>
                                            //     ),
                                            // }}
                                        />
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                                     className={classes.driverData}>
                                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}
                                         className={classes.driverDataInner}>
                                        <Icon
                                            className={clsx("brand-icon-contact-details", classes.icon)}/>
                                        <Typography>Detalii contact</Typography>
                                    </Box>
                                    <Divider className={classes.divider}/>
                                </Box>
                                <Grid container justify={"center"} spacing={3} className={classes.formRow}>
                                    <Grid item sm={5} xs={12}>
                                        <TextField
                                            label="Prenume"
                                            name="first_name"
                                            required={required.first_name}
                                            variant="outlined"
                                            InputProps={{
                                                classes: {root: classes.inputRoot}
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={5} xs={12}>
                                        <TextField
                                            label="Nume"
                                            name="last_name"
                                            required={required.last_name}
                                            variant="outlined"
                                            InputProps={{
                                                classes: {root: classes.inputRoot}
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={5} xs={12}>
                                        <TextField
                                            label="Email"
                                            name="email"
                                            type="email"
                                            required={required.email}
                                            variant="outlined"
                                            InputProps={{
                                                classes: {root: classes.inputRoot}
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={5} xs={12}>
                                        <TextField
                                            label="Telefon"
                                            name="phone"
                                            type="tel"
                                            required={required.phone}
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{
                                                classes: {root: classes.inputRoot}
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                                     className={classes.driverData}>
                                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}
                                         className={classes.driverDataInner}>
                                        <Typography>Mesaj</Typography>
                                    </Box>
                                    <Divider className={classes.divider}/>
                                </Box>

                                <Grid container justify={"center"} className={classes.formRow}>
                                    <Grid item sm={5} xs={12} className={classes.formTextbox}>
                                        <TextField
                                            name="message"
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            fullWidth
                                            classes={{
                                                root: classes.inputRoot
                                            }}

                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={"center"} direction={"column"}
                                      className={classes.formCheckbox}>
                                    <Grid container item xs={12} sm={5}
                                    >
                                        <Checkboxes
                                            name="terms"
                                            formControlLabelProps={{
                                                classes: {label: classes.checkboxLabel}
                                            }}
                                            required={required.terms}
                                            color="primary"
                                            data={{
                                                label: <span>Sunt de accord cu <Link
                                                    href={`/${PAGES_SLUGS.TERMS_AND_CONDITIONS}`} target={"_blank"}>termenii si conditiile</Link>.</span>,
                                                value: false,
                                            }}
                                        />
                                    </Grid>
                                    <Grid container item xs={12} sm={5}>

                                        <Checkboxes
                                            name="contact_optin"
                                            formControlLabelProps={{
                                                classes: {label: classes.checkboxLabel}
                                            }}
                                            color="primary"
                                            data={{
                                                label: <span>Doresc sa primesc informatii si oferte legate de modelele Volvo.</span>,
                                                value: false,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                {/*<Grid container justify={"center"} className={classes.formRow}>*/}
                                {/*    <Grid container justify={"center"} item sm={5} xs={12}*/}
                                {/*          className={classes.formCheckbox}>*/}
                                {/*        <Debug/>*/}
                                {/*    </Grid>*/}
                                {/*</Grid>*/}
                                {/*{submitError && <Grid container justify={"center"} className={classes.formRow}>*/}
                                {/*    <Grid container justify={"center"} item sm={5} xs={12}*/}
                                {/*          className={classes.formTextbox}>*/}
                                {/*        <div className="error">{submitError}</div>*/}
                                {/*    </Grid>*/}
                                {/*</Grid>}*/}
                                <Grid container justify={"center"} className={classes.formRow}>
                                    <Grid item>
                                        <Button variant="outlined" color="primary" type="submit" disabled={submitting}>
                                            REZERVA TEST DRIVE
                                            {submitting &&
                                            <CircularProgress size={24} className={classes.buttonProgress}/>}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                                <Alert onClose={handleCloseSnackbar} severity="error">
                                    Oops! Something went wrong! Please check your info and retry.
                                </Alert>
                            </Snackbar>
                        </form>
                    )
                }
                }
            />

            {/*<TermsDialogWidget*/}
            {/*    open={openTermsDialog}*/}
            {/*    onClose={handleTermsAndConditionsDialog}*/}
            {/*/>*/}

            {/*<TestdriveTyDialogWidget*/}
            {/*    slug={PAGES_SLUGS.TESTDRIVE_THANK_YOU}*/}
            {/*    open={openTyDialog}*/}
            {/*    onClose={_handleTyDialogClose}*/}
            {/*    onOpen={() => {*/}
            {/*    }}*/}
            {/*/>*/}

        </div>
    );
}

const datePickerTheme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: grey['900'],
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                // backgroundColor: lightBlue.A200,
                // color: "white",
            },
        },
        MuiPickersDay: {
            day: {
                color: grey.A700,
            },
            daySelected: {
                backgroundColor: grey["400"],
            },
            dayDisabled: {
                color: grey["100"],
            },
            current: {
                color: grey["900"],
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: grey["400"],
            },
        },
    },
});

const useFormStyles = makeStyles((theme) => ({

    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    withLineDivider: {
        position: "relative",
    },
    formRoot: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(8),
    },
    inputRoot: {
        background: "#fff",
    },
    formRow: {},
    formTextbox: {
        paddingTop: theme.spacing(0),
    },
    formCheckbox: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(5),
    },
    formTextboxLabel: {
        textAlign: "center",
        paddingBottom: theme.spacing(3),
    },
    formControl: {},
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    driverData: {
        position: "relative",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    driverDataInner: {
        margin: "0 auto",
        padding: `0 ${theme.spacing(3)}px`,
        background: "#FAFAFA",
        zIndex: 2,
    },
    divider: {
        width: '100%',
        position: "absolute",
        top: "50%",
        left: 0,
        transform: "translateY(-50%)",
    },
    icon: {
        fontSize: 32,
        marginRight: 10,
    },
    dropdownIcon: {
        color: theme.palette.primary.main,
    },
    checkboxLabel: {
        fontSize: theme.typography.pxToRem(14),
        // lineHeight: theme.typography.pxToRem(32),
    }
}));
export default TestDriveSection
