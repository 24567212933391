import React from 'react';
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import ShowMoreText from 'react-show-more-text';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import placeholder from "../../images/placeholder.jpg"
import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import Fade from "react-reveal/Fade";
import toLocale from "../helpers/toLocale";
import getSuppotedFileType from "../helpers/getSuppotedFileType";

const preventDefault = (event) => event.preventDefault();
const PacheteCard = (props) => {
    const classes = useStyles();
    const {data} = props;

    const getImage = () => {
        if (!_isEmpty(_get(data, "photos", []))) {
            return _get(data.photos[0], "thumb", '');
        }
        return null
    }

    return <Grid item xs={12} sm={6}>
        <Fade>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <div
                        className={classes.imageWrapper}
                    >
                        {/*{getImage() &&*/}
                        {!_isEmpty(_get(data, "photos[0].thumb", [])) ? <img src={getSuppotedFileType(_get(data, "photos[0].thumb", []))} className={classes.image}/> : <img src={placeholder} className={classes.image}/>}
                        {/*}*/}
                        {/*{!getImage() && }*/}
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={classes.body}>
                        <div>
                            <Typography paragraph className={classes.title} align={"left"}>{data.name}</Typography>
                        </div>
                        <div>
                            <Typography paragraph className={classes.price} align={"left"}>{toLocale(data.price)}€ + TVA</Typography>
                        </div>
                        <div className={classes.text}>
                            <ShowMoreText
                                /* Default options */
                                lines={5}
                                more={<span className={classes.linkRoot}>
                             <span className={classes.cta}>View more</span>
                <Icon className={clsx("brand-icon-down-arrow", classes.icon)}/>
                        </span>}
                                less={<span className={classes.linkRoot}>
                             <span className={classes.cta}>View less</span>
                <Icon className={clsx("brand-icon-up-arrow", classes.icon)}/>
                        </span>}
                                anchorClass={classes.link}
                                expanded={false}
                                width={0}
                            >
                                <Typography variant={"body1"} component={"p"}>{data.description}</Typography>
                            </ShowMoreText>
                        </div>
                    </div>

                </Grid>
            </Grid>
        </Fade>
    </Grid>
}

const useStyles = makeStyles((theme) => ({
    imageWrapper: {
        width: "100%",
        marginBottom: theme.spacing(3),
        position: "relative",
        paddingTop: "56.25%",
        background: "#fafafa",
    },
    image: {
        objectFit: "cover",
        objectPosition: '50% 50%',
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
    },

    body: {
        marginLeft: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(0),
            paddingBottom:theme.spacing(2),
        },
    },

    title: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(24),
        marginBottom: theme.spacing(1),
    },

    price: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(24),
        letterSpacing:"0",
        marginBottom: theme.spacing(1),
    },
    text: {
        "& p": {
            fontWeight: theme.typography.fontWeightLight,
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(20),
            marginBottom: theme.spacing(2),
            wordBreak: "break-word",
        }
    },
    link: {

        color: theme.palette.primary.main,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "none",
        },
        "&:before": {
            content: `""`,
            display: "block",
            marginTop: theme.spacing(3),
        },
    },
    linkRoot: {
        display: "inline-flex",
        alignItems: "center",
    },
    cta: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(24),
    },
    icon: {
        fontSize: "14px",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
        color: theme.palette.primary.main,
    },


}));
export default PacheteCard;