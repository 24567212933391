import React, {Component} from 'react';
import {connectPagination} from "react-instantsearch-dom";
/*UI*/
import {withStyles} from '@material-ui/core/styles';
import {appStyle} from "../../../theme/appStyle";
import {Box} from "@material-ui/core";

const customStyles = theme => ({
    ...appStyle(theme),
    paginationBackground: {
        backgroundColor: `#fff`
    },
    paginationItem: {
        display: `flex`,
        cursor: `pointer`,
        width: 32,
        height: 32,
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
        backgroundColor: `transparent`,
        '&:hover': {
            backgroundColor: `rgba(82, 82, 82, 0.08)`
        }
    },
    paginationAnchor: {
        color: `rgb(82, 82, 82)`,
        textDecoration: `none`,
        display: `flex`,
        alignItems: `center`,
        flex: 1,
        justifyContent: `center`,
    },
    disabledItem: {
        backgroundColor: `transparent`,
        cursor: `default`,
        '&:hover': {
            backgroundColor: `transparent`,
            cursor: `default`,
        },
        '& a': {
            color: `rgba(0, 0, 0, 0.26)`,
            pointerEvents: `none`
        }
    },
    arrowItem: {
        '& a': {
            color: theme.palette.primary.main,
        }
    },
    activePage: {
        backgroundColor: theme.palette.primary.main,
        color: `#fff`
    }
});

class AlgoliaPagination extends Component {
    navigate = (page) => (event) => {
        event.preventDefault();
        this.props.refine(page);
        // /**
        //  * REMOVED: Smooth scrolling
        //  * else statement is the callback
        //  * for longer animation, increase the smooth const
        //  */
        // const smooth = 2;
        // const scrollToTop = () => {
        //     const documentTop = document.documentElement.scrollTop || document.body.scrollTop;
        //     if (documentTop > 0) {
        //         window.requestAnimationFrame(scrollToTop);
        //         window.scrollTo(0, documentTop - documentTop / smooth);
        //     } else {
        //         this.props.refine(page);
        //     }
        // };
        // scrollToTop();
    };

    render() {
        const {currentRefinement, nbPages, createURL, showPrevious, showNext, classes} = this.props;
        // console.log(this.props);
        const itemsInPagination = 5;
        const renderedPages = Math.min(itemsInPagination, nbPages);

        let startPage = 1
        if (renderedPages < itemsInPagination) {
            startPage = 1
        } else if (currentRefinement + Math.floor((renderedPages - 1) / 2) > nbPages) {
            startPage = nbPages - renderedPages + 1;
        } else {
            startPage = Math.max(1, currentRefinement - Math.floor((renderedPages - 1) / 2));
        }

        return (
            <Box display={"flex"} className={classes.paginationBackground}>
                {showPrevious &&
                <div className={`${classes.paginationItem} ${currentRefinement !== 1 ? classes.arrowItem : classes.disabledItem}`}>
                    <a className={`${classes.colorPrimary} ${classes.paginationAnchor}`}
                       href={createURL(Math.max(1, currentRefinement - 1))}
                       onClick={this.navigate(Math.max(1, currentRefinement - 1))}
                    >
                        <span className={`brand-icon-left-nav-arrow`}/>
                    </a>
                </div>
                }
                {new Array(renderedPages).fill(null).map((_, index) => {
                    const page = startPage + index;

                    return (
                        <div className={classes.paginationItem} key={index}>
                            <a
                                className={`${classes.paginationAnchor} ${currentRefinement === page ? classes.activePage : ''}`}
                                href={createURL(page)}
                                onClick={this.navigate(page)}
                            >
                                {page}
                            </a>
                        </div>
                    );
                })}
                {showNext &&
                <div className={`${classes.paginationItem} ${currentRefinement < nbPages ? classes.arrowItem : classes.disabledItem}`}>
                    <a className={`${classes.paginationAnchor}`}
                       href={createURL(Math.min(nbPages, currentRefinement + 1))}
                       onClick={this.navigate(Math.min(nbPages, currentRefinement + 1))}
                    >
                        <span className={`brand-icon-right-nav-arrow`}/>
                    </a>
                </div>
                }
            </Box>
        );
    }
}

AlgoliaPagination = connectPagination(AlgoliaPagination);
export default withStyles(customStyles)(AlgoliaPagination);
