import React, {useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

// import tyBackgoundImage from "../../images/testdrive-ty.jpg"
import {headerTheme} from "../../theme";
import {useDispatch, useSelector} from "react-redux";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import * as actions from "../../actions/pages";
import ReactHtmlParser from "react-html-parser";
import {PAGES_SLUGS} from "../../constants";
import Seo from "../../components/seo/Seo";

const FormTyPage = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const pageData = useSelector(state => _get(state.pages.data, PAGES_SLUGS.TESTDRIVE_THANK_YOU, {}));
    const loading = useSelector(state => state.pages.request);
    const error = useSelector(state => state.pages.error);

    useEffect(() => {
        window.scrollTo(0, 0)
        if (_isEmpty(pageData)) {
            dispatch(actions.fetchPage(PAGES_SLUGS.TESTDRIVE_THANK_YOU))
        }
    }, [])

    return (
<>
    {!_isEmpty(_get(pageData, 'data.meta_data', {})) && <Seo data={_get(pageData, 'data.meta_data', {})}/>}
        <div className={classes.root}>
            {!loading && <div className={classes.body}
                              style={{
                                  "--background-image": `url(${_get(pageData, 'data.content.image', "")})`
                              }}
            >
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"100%"}
                    width={"100%"}
                >
                    <Box display={"flex"}
                         justifyContent={"center"}
                         alignItems={"center"}
                         flexDirection={"column"}
                    >
                        <Typography component={'div'}
                                    className={classes.title}>{ReactHtmlParser(_get(pageData, "data.content.title", ''))}</Typography>
                        <Typography component={'div'}
                                    className={classes.text}>{ReactHtmlParser(_get(pageData, "data.content.content", ''))}</Typography>
                        <ThemeProvider theme={headerTheme}>
                            <Button
                                variant="outlined"
                                color="primary"
                                component={RouterLink} to={_get(pageData, "data.content.url", '/')}
                                className={classes.button}
                                size="large"
                            >
                                Vezi toate ofertele
                            </Button>
                        </ThemeProvider>
                    </Box>
                </Box>
            </div>}
        </div>

</>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.35), rgba(0,0,0,0.35)), var(--background-image)`,
        backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.35), rgba(0,0,0,0.35)), var(--background-image)`,
        backgroundSize: "cover",
        backgroundPosition: "center bottom",
        flex: 1,
        display: "flex",
        flexDirection:"column",
        height: "100%",
        justifyContent:"center",
    },
    container: {
        [theme.breakpoints.up('lg')]: {
            maxWidth: "1100px !important"
        },
    },
    dialogRoot: {
        zIndex: `${theme.zIndex.appBar - 1}!important`
    },
    body: {
        backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.35), rgba(0,0,0,0.35)), var(--background-image)`,
        backgroundSize: "cover",
        backgroundPosition: "center bottom",
        flex: 1,
        overflowY: "auto",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
    },
    all: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(8),
    },
    rootClose: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    icon: {
        fontSize: "14px",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
    },
    close: {
        fontSize: "24px",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
    },
    linkIcon: {
        fontSize: 10,
    },
    title: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(38),
        lineHeight: theme.typography.pxToRem(56),
        letterSpacing: "0.4",
        color: "#fff",
    }, text: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(24),
        color: "#fff",
    },
    button: {
        marginTop: theme.spacing(8),
    }
}));

export default FormTyPage;
