import React from 'react';
import clsx from 'clsx';
import {Link, Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

import {Logo} from "./BrandItems";
import AddToHomescreenCard from "./AddToHomescreenCard";
import {PAGES_SLUGS} from "../constants";
import ButtonBase from "@material-ui/core/ButtonBase";

import {toggleModelModal} from "../actions/models"
import {useDispatch, useSelector} from "react-redux";
import _get from "lodash/get";

export default function SwipeableTemporaryDrawer(props) {
    const classes = useStyles();
    const pwa_install = useSelector(state => state.pwa.install);
    const dispatch = useDispatch();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const handleCloseModal = () => {
        dispatch(toggleModelModal(false));
        setTimeout(() => {
            props.onClose()
        }, 100)
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };


    return (
        <div>
            <SwipeableDrawer
                anchor={'right'}
                open={props.open}
                onClose={props.onClose}
                onOpen={props.onOpen}
                classes={{
                    paper:classes.root
                }}
            >
                <div className={classes.menu}>

                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                         className={classes.header}>
                        <ButtonBase onClick={handleCloseModal} component={Link} to={"/"} className={classes.logo}>
                            <Logo/>
                        </ButtonBase>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={props.onClose}
                            edge="end"
                        >
                            <Icon className={clsx("brand-icon-close", classes.close)}/>

                        </IconButton>
                    </Box>

                    <Divider/>
                    <List disablePadding classes={{
                        root: classes.listRoot,
                    }}>
                        <ListItem button onClick={handleCloseModal} component={RouterLink} to={`/${PAGES_SLUGS.PRODUCTS}`} classes={{
                            gutters: classes.listItemGutters,
                        }}>
                            <ListItemText primary={"Cere oferta"} secondary={"Alege modelul Volvo preferat"} classes={{
                                // gutters: classes.listItemGutters,
                                primary: classes.listItemPrimary,
                                secondary: classes.listItemSecondary
                            }}/>
                        </ListItem>
                        <ListItem button onClick={handleCloseModal} component={RouterLink} to={`/${PAGES_SLUGS.ACCESSORIES_INTRO}`} classes={{
                            gutters: classes.listItemGutters,
                        }}>
                            <ListItemText primary={"Accesorii"}
                                          classes={{
                                              primary: classes.listItemPrimary
                                          }}/>
                        </ListItem>
                        <Divider variant="middle"/>
                        <ListItem button onClick={handleCloseModal} component={RouterLink} to={`/${PAGES_SLUGS.ABOUT_US}`} classes={{
                            gutters: classes.listItemGutters,
                        }}>
                            <ListItemText primary={"Despre"}
                                          classes={{
                                              // gutters: classes.listItemGutters,
                                              primary: classes.listItemPrimary
                                          }}/>
                        </ListItem>
                        <ListItem button onClick={handleCloseModal} component={RouterLink} to={`/${PAGES_SLUGS.EXTENDED_WARRANTY}`} classes={{
                            gutters: classes.listItemGutters,
                        }}>
                            <ListItemText primary={"Garantie extinsa"}
                                          classes={{
                                              // gutters: classes.listItemGutters,
                                              primary: classes.listItemPrimary
                                          }}/>
                        </ListItem>
                        <ListItem button onClick={handleCloseModal} component={RouterLink} to={`/${PAGES_SLUGS.FINANCE}`} classes={{
                            gutters: classes.listItemGutters,
                        }}>
                            <ListItemText primary={"Finantare"}
                                          classes={{
                                              // gutters: classes.listItemGutters,
                                              primary: classes.listItemPrimary
                                          }}/>
                        </ListItem>
                        <ListItem button onClick={handleCloseModal} component={RouterLink} to={`/${PAGES_SLUGS.DEALERSHIPS}`} classes={{
                            gutters: classes.listItemGutters,
                        }}>
                            <ListItemText primary={"Dealeri"}
                                          classes={{
                                              // gutters: classes.listItemGutters,
                                              primary: classes.listItemPrimary
                                          }}/>
                        </ListItem>
                        <Divider variant="middle"/>
                        <ListItem button  onClick={handleCloseModal} component={RouterLink} to={`/${PAGES_SLUGS.TERMS_AND_CONDITIONS}`}  classes={{
                            gutters: classes.listItemGutters,
                        }}>
                            <ListItemText secondary={"Termeni si conditii"}/>
                        </ListItem>
                        <Divider variant="middle"/>
                        {pwa_install && <AddToHomescreenCard/> }
                    </List>
                </div>

            </SwipeableDrawer>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root:{
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
    },
    menu: {
        width: 400,
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
        // justifyContent: "space-between"
    },
    close: {
        fontSize: 16,
    },
    header: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        height: 64,
        // justifyContent: "space-between"
    },
    listRoot: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
    listItemGutters: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    listItemPrimary: {
        fontWeight: 500,
        fontSize: "1.25rem"
    },
    listItemSecondary: {
        fontSize: "1rem"
    },
    fullList: {
        width: 'auto',
    },
}));
