import React, {Component} from 'react';
import {connectCurrentRefinements} from 'react-instantsearch-dom';
// UI
import _isEmpty from "lodash/isEmpty";
import {withStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import {Box} from "@material-ui/core";
import {resetLabel} from "../../../constants/algolia";
import filterStyles from "../../../theme/filterStyles";

class AlgoliaClearRefinements extends Component {
    render() {
        const {items, refine, classes} = this.props
        return (
            <Box alignSelf="flex-start">
                <Typography
                    variant={"body2"}
                    onClick={() => refine(items)}
                    disabled={_isEmpty(items)}
                    className={`${classes.resetButton} ${classes.capitalize} ${_isEmpty(items) ? classes.disabled : classes.pointer}`}
                >
                    {resetLabel}
                </Typography>
            </Box>
        )
    }
}

AlgoliaClearRefinements = withStyles(filterStyles)(AlgoliaClearRefinements);
AlgoliaClearRefinements = connectCurrentRefinements(AlgoliaClearRefinements);
export default AlgoliaClearRefinements;
