import { createBrowserHistory } from 'history'
import {applyMiddleware, createStore} from 'redux';
import {routerMiddleware} from 'connected-react-router'
import thunkMiddleware from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import rootReducer from '../reducers';

export const history = createBrowserHistory()
const preloadedState = {};
const middlewares = [
    thunkMiddleware,
    routerMiddleware(history)
];
const middlewareEnhancer = applyMiddleware(...middlewares);

// const composedEnhancers = compose(...enhancers)
const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const store = createStore(
    rootReducer(history),
    /* preloadedState,*/
    composedEnhancers
)

export default store

