import axios from 'axios';
import _get from "lodash/get";
import * as ActionTypes from '../constants/actionTypes';
import * as api from '../api';
import setHeaders from "../helpers/setHeaders";
import handleErrors from "../helpers/handleErrors";
import {formDateformat} from "../helpers/formatDate";

import { setSnowplowUserId } from '../helpers/snowplow';


export function submitTestdriveForm(formdata) {

    const clonedFormdata = {...formdata};

    clonedFormdata.terms = _get(clonedFormdata, "terms", 0) ? 1 : 0;
    clonedFormdata.contact_optin = _get(clonedFormdata, "contact_optin", 0) ? 1 : 0;
    clonedFormdata.proposed_date = formDateformat(_get(clonedFormdata, "proposed_date", ""));

    return function (dispatch) {
        dispatch({
            type: ActionTypes.TESTDRIVE_REQUEST,
        });
        return new Promise(
            (resolve, reject) => {
                axios.post(`${api.API_TESTDRIVE}`, {...clonedFormdata}, setHeaders()).then(({data}) => {
                    dispatch({
                        type: ActionTypes.TESTDRIVE_SUCCESS,
                        payload: data
                    });
                    resolve(data);
                }).catch((response) => {
                    const {errors, message} = handleErrors(response);
                    dispatch({
                        type: ActionTypes.TESTDRIVE_ERROR,
                        payload: message
                    });
                    reject(message);
                });
            })
    }
}

export function submitQuotationForm(formdata) {
    const clonedFormdata = {...formdata};

    clonedFormdata.terms = _get(clonedFormdata, "terms", 0) ? 1 : 0;
    clonedFormdata.contact_optin = _get(clonedFormdata, "contact_optin", 0) ? 1 : 0;

    return function (dispatch) {
        dispatch({
            type: ActionTypes.QUOTATION_REQUEST,
        });
        return new Promise(
            (resolve, reject) => {
                axios.post(`${api.API_QUOTATION}`, clonedFormdata, setHeaders()).then(({data}) => {
                    // console.log("data", data);
                    dispatch({
                        type: ActionTypes.QUOTATION_SUCCESS,
                        payload: data
                    });
                    console.log('clonedFormdata', clonedFormdata)

                    /*Set email as snowplow uid*/
                    setSnowplowUserId(_get(clonedFormdata, "email", ''));

                    resolve(data);
                }).catch((response) => {
                    const {errors, message} = handleErrors(response);
                    dispatch({
                        type: ActionTypes.QUOTATION_ERROR,
                        payload: message
                    });
                    reject(message);
                });
            })
    }
}

export function submitAccessoryQuotationForm(formData) {
    formData.terms = formData.terms & 1;
    formData.contact_optin = formData.contact_optin & 1;

    return function (dispatch) {
        return new Promise((resolve, reject) => {
            axios.post(`${api.API_ACCESSORY_QUOTATION}`, formData, setHeaders()).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                const {errors, message} = handleErrors(response);
                reject(message);
            });
        })
    }
}
