import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {Link as RouterLink} from "react-router-dom";
import {PAGES_SLUGS} from "../constants";
import SvgIcon from "@material-ui/core/SvgIcon";
import {emptyWishlist} from "../containers/WishlistPage/actions";
import Icon from "@material-ui/core/Icon";
import _get from "lodash/get";
import React from "react";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import {makeStyles} from "@material-ui/core/styles";
import {appStyle} from "../theme/appStyle";
import itemDetailsStyles from "../theme/itemDetailsStyles";

/**
 * Share the URL if available, otherwise copy it
 * @param {String} title
 * @param {String} text
 * @param {String} url optional
 */
export const shareURL = (title, text, url = window.location.href) => {
    if (navigator.share) {
        navigator.share({
            title: title,
            text: text,
            url: url,
        })
            .then(() => console.log('Successful share'))
            .catch((error) => {
                console.log('Error sharing', error);
            });
    } else {
        /**
         * TODO: Add pop-up
         */
        navigator.clipboard.writeText(url)
    }
}

/**
 * React wrapper to show `Linkul a fost copiat` text on copy
 * @param props
 * @returns {*}
 * @constructor
 */
export const ShareUrl = (props) => {
    const classes = useStyles();
    const {title, text, url = window.location.href, children} = props;
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const share = () => {
        console.log('url', url);
        if (navigator.share) {
            navigator.share({
                title: title,
                text: text,
                url: url,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => {
                    console.log('Error sharing', error);
                });
        } else {
            /// TODO: THIS URL!
            navigator.clipboard.writeText(url)
            setOpen(true);
        }
    }

    return (
        <Tooltip
            classes={{
                tooltip: classes.toolTip,
                popperInteractive: classes.disableClick
            }}
            title="Linkul a fost copiat"
            interactive leaveDelay={700}
            onClick={share}
            open={open} onClose={handleClose}
        >
            {children}
        </Tooltip>
    )
}

const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    toolTip: {
        backgroundColor: theme.palette.success.main,
    },
    disableClick: {
        pointerEvents: `none`
    }
}))
