import React, {useState} from 'react'
import {PAGES_SLUGS} from "../constants";
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

import {Logo} from "./BrandItems"
import {headerTheme} from "../theme"

import SideMenuDrawer from "./SideMenuDrawer";
import UpdateAvailable from "./UpdateAvailable";
import ModelsWidget from "./ModelsWidget";
import {Link as RouterLink, Link} from "react-router-dom";
import {toggleModelModal} from "../actions/models"
import {useDispatch, useSelector} from "react-redux";
import _isEmpty from "lodash/isEmpty";

export default (props) => {
    const isDealerPage= useSelector(state => state.dealer.data);
    const dispatch = useDispatch();
    const isModelsOpen = useSelector(state => state.models.isModelsOpen);
    const classes = useStyles();
    const [sideMenuOpen, setSideMenuOpen] = useState(false)
    const wishListedAccessories = useSelector(state => state.wishlist.accessories);
    const wishListedCars = useSelector(state => state.wishlist.cars);

    const _toggleSideMenu = () => {
        setSideMenuOpen(!sideMenuOpen);
    };

    const _handleSideMenuClose = () => {
        setSideMenuOpen(false);
    };

    const _toggleModelsMenu = () => {
        dispatch(toggleModelModal(!isModelsOpen));
    };

    const _handleModelsClose = () => {
        dispatch(toggleModelModal(false));
    };

    if( !!isDealerPage){
        return  null;
    }
    return (
        <>
            <ThemeProvider theme={headerTheme}>
                <AppBar
                    position="sticky"
                    className={classes.appbar}
                    elevation={0}
                >
                    <Toolbar className={classes.toolbar}>
                        <div className={classes.title}>
                            <ButtonBase component={Link} to={"/"} className={classes.logo}>
                                <Logo/>
                            </ButtonBase>
                        </div>

                       <div>
                           {(!_isEmpty(wishListedAccessories) || !_isEmpty(wishListedCars))  &&
                           <IconButton
                               color="inherit"
                               component={RouterLink} to={`/${PAGES_SLUGS.WISHLIST}`}
                               onClick={() => dispatch(toggleModelModal(false))}
                               >
                               <Icon className="brand-icon-heart"/>
                           </IconButton>}
                            <ButtonBase
                                focusRipple
                                className={classes.button}
                                focusVisibleClassName={classes.focusVisible}
                                onClick={_toggleModelsMenu}
                            >
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                >
                                    Modele
                                </Typography>
                            </ButtonBase>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={_toggleSideMenu}
                                edge="end"
                            >
                                <Icon className="brand-icon-menu"/>
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <UpdateAvailable/>
            </ThemeProvider>
             <SideMenuDrawer open={sideMenuOpen} onClose={_handleSideMenuClose}
                            onOpen={_toggleSideMenu}/>
           <ModelsWidget
                open={isModelsOpen}
                onClose={_handleModelsClose}
                onOpen={()=>{}}
            />
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    appbar: {
        backgroundColor: "#FAFAFA",
    },
    toolbar: {
        minHeight: 64,
        paddingLeft: 20,
        paddingRight: 20,
    },
    logo: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginLeft: -4,
    },
    title: {
        flexGrow: 1,
    },
    button: {
        position: 'relative',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {},
        cursor: "pointer",
        "&:hover": {
            textDecoration: "none",
        },
        "&:before": {
            content: `''`,
            position: "absolute",
            width: "0",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform:"translateX(-50%)",
            background: theme.palette.primary.main,
            transition: theme.transitions.create(['width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        "&:hover:before": {
            content: `''`,
            position: "absolute",
            width: "100%",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform:"translateX(-50%)",
            background: theme.palette.primary.main
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },

    rootExemple: {
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.up('md')]: {
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.up('lg')]: {},
        [theme.breakpoints.between('sm', 'md')]: {},
    },

    '@media (max-width: 1024px)': {},
}));
