import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Container from "@material-ui/core/Container";
import Fade from "react-reveal/Fade";
import clsx from "clsx";
import Skeleton from "@material-ui/lab/Skeleton";

const BannerCard = (props) => {
    const classes = useStyles();

    return <ButtonBase disableRipple={true} className={classes.slide} component={RouterLink} to={props.to}>
        {/*<img src={imgDummy} className={classes.image}/>*/}
        <img data-src={props.image}
            //      srcSet={`
            // ${medium} 768w,
            // ${large} 1920w,
            // ${xlarge} 3200w`
            // }
            //      sizes="
            //      (max-width: 768px) 768px,
            //      (max-width: 1920px) 1920px,
            //     3200px"
            //      onLoad={onLoad}
             className={clsx("swiper-lazy", classes.image)}/>
        <Skeleton variant={'rect'} width="100%" height={"100%"}
                  className={"preloader-skeleton"}/>
        <div className={classes.slideText}>
            <Container classes={{
                maxWidthXl: classes.container,
                maxWidthLg: classes.container,
                maxWidthMd: classes.container,
                maxWidthSm: classes.container,
                maxWidthXs: classes.container,
            }}
                // justifyContent={"space-between"} direction={"column"}
            >
                <Grid container justify={"flex-end"} direction={"column"}
                      alignItems={"flex-start"} className={classes.slideTextPosition}
                >
                    <Fade delay={1000}>
                        <div className={classes.primaryText}>{ReactHtmlParser(props.title)}</div>

                        <div className={classes.secondaryText}>{ReactHtmlParser(props.content)}</div>
                    </Fade>
                </Grid>
            </Container>
        </div>

    </ButtonBase>
}


const useStyles = makeStyles((theme) => ({
    slideText: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        "& $container": {
            height: "calc(100% - 160px)",
        },
        [theme.breakpoints.down('sm')]: {
            background: "#141414",
            position: "static",
            bottom: "auto",
            left: "auto",
            right: "auto",
            top: "auto",
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(4),
        }
    },

    container: {
        [theme.breakpoints.between('lg', 'xl')]: {
            maxWidth: "1440px",
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },

    slideTextPosition: {
        height: "100%",
        maxWidth: "45%",
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
        }
    },

    primaryText: {
        color: "#FFFFFF",
        // fontFamily: "Volvo Broad, Arial Black",
        fontSize: theme.typography.pxToRem(38),
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: theme.typography.pxToRem(42),
        letterSpacing: "0.95px",
        [theme.breakpoints.down('sm')]: {
            fontFamily: "inherit",
            fontSize: theme.typography.pxToRem(28),
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: theme.typography.pxToRem(28),
            paddingBottom: 12,
        }
    },

    secondaryText: {
        color: "#FFFFFF",
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightRegular,
        letterSpacing: "0.4px",
        lineHeight: theme.typography.pxToRem(24),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(15),
            lineHeight: theme.typography.pxToRem(16),
            fontWeight: theme.typography.fontWeightRegular,
        }
    },

    slide: {
        display: "block",
        position: "relative",
        width: "100%",
        fontSize: 0,
    },

    image: {
        // height: "calc(100vh - 2*min(calc(60px + 1920px - 100vw),80px))",
        [theme.breakpoints.up('md')]: {
            height: 600,
            objectFit: "cover",
            objectPosition: '50% 50%',
            width: "100%"
        },
        [theme.breakpoints.down('sm')]: {
            height: 350,
            objectFit: "cover",
            objectPosition: '100% 50%',
            width: "100%"
        },
        // [theme.breakpoints.down('xs')]: {
        //     height: 180,
        //     objectFit: "cover",
        //     objectPosition: '100% 50%',
        //     width: "100%"
        // }
    },
    // mobileImage: {
    //     height: "calc(100vh - 3*min(calc(60px + 1920px - 100vw),80px))",
    //     objectFit: "cover",
    //     objectPosition: '50% 50%',
    // },
}));
export default BannerCard;