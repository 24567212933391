import * as ActionTypes from '../constants/actionTypes';

export const initialState = {
    data: "",
};

export default function (state = initialState, action) {

    switch (action.type) {

        case ActionTypes.DEALER_SET_CURRENT_VALUE:
            return {
                ...state,
                data: action.payload
            };

        default :
            return state;
    }

}