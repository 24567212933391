import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const SnowplowAnalytics = () => {
    const history = useHistory();

    useEffect(() => {
        // Track the initial page view
        if (window.snowplow) {
            window.snowplow('trackPageView');

            /* Link click tracking */
            window.snowplow('enableLinkClickTracking');
        }

        // Listen for route changes to track page views
        const unlisten = history.listen(() => {
            if (window.snowplow) {
                window.snowplow('trackPageView');

                /* Link click tracking */
                window.snowplow('enableLinkClickTracking');
            }
        });

        // Cleanup the listener when the component is unmounted
        return () => {
            unlisten();
        };
    }, [history]);

    return null; // This component doesn't render anything to the DOM
};

export default SnowplowAnalytics;