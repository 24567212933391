import {createMuiTheme} from '@material-ui/core/styles';

export const breakpointsValues = {
    // xxs: 0,
    xs: 0,
    sm: 767,
    md: 960,
    lg: 1280,
    xl: 1920,
}
export const contrastColor = "#141414";

const defaultTheme = createMuiTheme({
    breakpoints: {
        values: breakpointsValues,
    },
    typography: {
        fontFamily: [
            'Volvo Novum',
            'Arial',
            'sans-serif',
        ].join(','),

        fontSize: 14,
        h1: {
            color: "#141414",
            fontSize: "1.6rem",
            fontWeight: 500,
        },

        h2: {
            color: "#141414",
            fontWeight: 300,
            fontSize: "1.63rem",
        },

        h3: {color: "#141414", fontWeight: 500,},

        h4: {color: "#141414", fontWeight: 500,},

        h5: {color: "#141414",  fontSize: `1.125rem`},

        h6: {color: "#141414", fontWeight: 500,},

        subtitle1: {
            color: "#141414",

        },

        subtitle2: {
            color: "#141414",

        },
        body2: {color: "#141414"},
        body1: {color: "#141414"},
        button: {
            fontWeight: 500,
            color: "#141414",
            letterSpacing: 0
        },
        caption: {color: "#707070"},
        overline: {color: "#141414"},
    },
    palette: {
        primary: {
            light: '#1C6BBA',
            main: '#1C6BBA',
            dark: '#1C6BBA',
            contrastText: '#fff',
        },
        secondary: {
            light: '#CCCCCC',
            main: '#141414',
            dark: '#141414',
            contrastText: '#fff',
        },
        background: {
            paper: "#fff",
            default: '#fff',
        },
        error: {
            light: '#d93448',
            main: '#D0021B',
            dark: '#910112'
        },
        success: {
            light: '#32D74B',
            main: '#32D74B',
            dark: '#32D74B'
        }
    },
    // Convert px spacing to rem using the same factor; 1 unit = 8px = 0.5rem
    spacing: factor => `${0.5 * factor}rem`,
    props: {
        MuiTypography: {
            variantMapping: {
                h1: 'h2',
                h2: 'h2',
                h3: 'h2',
                h4: 'h2',
                h5: 'h2',
                h6: 'h2',
                subtitle1: 'h2',
                subtitle2: 'h2',
                body1: 'span',
                body2: 'span',
            },
        },
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 3
            },
            sizeLarge: {
                paddingTop: 13,
                paddingBottom: 11
            },
            outlined: {
                // buttonWidePadding class for the Left and Right padding when using size=small
                paddingTop: 12,
                paddingBottom: 10,

            },
            contained: {
                boxShadow: `0 0 2px 0 rgba(0,0,0,0.12), 0 0 2px 0 rgba(0,0,0,0.24)`,
                paddingTop: 13,
                paddingBottom: 11,
                // maxWidthButton class from appStyles
                // maxWidth: 752,
                letterSpacing: 1,
                fontSize: `0.9375rem`,
                lineHeight: 1.65
            }
        },
        MuiPaper: {
            // Default elevation is 2 even if in docs it's 1
            elevation2: {}
        },
        MuiModal: {
            root: {
                minWidth: 320
            }
        },
        MuiContainer: {
            maxWidthLg: {
                [`@media (min-width: ${breakpointsValues.xl}px)`]: {
                    maxWidth: "1440px",
                },
            }
        }
    }
});

export default defaultTheme;

export const headerTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
        fontFamily: [
            `"Volvo Novum"`,
            'Arial',
            'sans-serif',
        ].join(','),
        subtitle1: {
            color: "#141414",
            fontWeight: 500
        },
        subtitle2: {
            color: "#141414",
            fontWeight: 500
        },
    },
    palette: {
        primary: {
            light: '#fff',
            main: '#fff',
            dark: '#fff',
            contrastText: 'rgb(82, 82, 82)',
        },
        secondary: {
            light: '#141414',
            main: '#141414',
            dark: '#141414',
            contrastText: '#fff',
        },
    },

});
