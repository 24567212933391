import * as ActionTypes from './constants';

const initialState = {
    request: true,
    error: '',
    data: []
};

export default function accessoryModels(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ACCESSORY_REQUEST:
            return {
                ...state,
                request: true,
                error: '',
            };

        case ActionTypes.ACCESSORY_SUCCESS:
            return {
                ...state,
                request: false,
                error: '',
                data: action.payload
            };

        case ActionTypes.ACCESSORY_ERROR:
            return {
                ...state,
                request: false,
                error: action.payload
            };

        default :
            return state;
    }
}
