import {DEFAULT_LOCALE} from "../constants"

/**
 * Will always return a formated value even if it's 0 (0.00)
 * @param value
 * @returns {string}
 */
export default function toLocale(value) {
    return Math.trunc(Number(value)).toLocaleString(DEFAULT_LOCALE);
}
