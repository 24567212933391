import axios from 'axios';
import _get from "lodash/get";
import * as ActionTypes from './constants';
import {API_ACCESSORY_MODELS} from "../../api";
import handleErrors from "../../helpers/handleErrors";
import setHeaders from "../../helpers/setHeaders";

export function fetchModels() {
    return function (dispatch) {
        dispatch({
            type: ActionTypes.ACCESSORY_REQUEST
        });

        axios.get(API_ACCESSORY_MODELS, setHeaders()).then((response) => {
            const data = _get(response, "data", {});
            dispatch({
                type: ActionTypes.ACCESSORY_SUCCESS,
                payload: data
            });
        }).catch((response) => {
            const {errors} = handleErrors(response);
            dispatch({
                type: ActionTypes.ACCESSORY_ERROR,
                payload: errors
            });
        });
    }
}
