import * as ActionTypes from '../constants/actionTypes';

export const initialState = {
    request: false,
    error: {},
    data: {},
};

const productById = (state, action) => {
    const {slug} = action.payload;
    return {
        ...state,
        [slug]: {
            ...action.payload
        }
    }
};

const deleteCarErrorById = (state, action) => {
    const {slug} = action.payload;
    if (state.hasOwnProperty(slug)) {
        delete state[slug];
        return {
            ...state,
        }
    }
    return state
};

export default function products(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.PRODUCT_REQUEST:
            return {
                ...state,
                request: true
            };

        case ActionTypes.PRODUCT_SUCCESS:
            return {
                ...state,
                request: false,
                error: deleteCarErrorById(state.error, action),
                data: productById(state.data, action)
            };

        case ActionTypes.PRODUCT_ERROR:
            return {
                ...state,
                request: false,
                error: productById(state.error, action)
            };

        default :
            return state;
    }
}
