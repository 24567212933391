import React from 'react';
import {withStyles} from '@material-ui/core/styles';
/*components*/
import {isMobile} from "react-device-detect";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import {PwaIcon} from "./BrandItems";

const customStyles = theme => ({
    listItemGutters: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(10),
    },
    listItemPrimary: {
        fontWeight: 500,
        fontSize: "1.25rem"
    },
    listItemSecondary: {
        fontSize: "1rem"
    },
});

class AddToHomeCard extends React.Component {
    handleInstall = () => {
        if (window.deferredPrompt) {
            window.deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            window.deferredPrompt.userChoice.then((choiceResult) => {
                // if (choiceResult.outcome === 'accepted') {
                // console.log('User accepted the  prompt');
                // } else {
                // console.log('User dismissed the  prompt');
                // }
                window.deferredPrompt = null;
            });
        }
    };

    detectDevice = () => {
        if (!isMobile) {
            return "desktop"
        }
        return "";
    };

    render() {
        const {classes, t} = this.props;
        if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
            return null
        } else {
            return (
                <ListItem onClick={this.handleInstall} button classes={{
                    gutters: classes.listItemGutters,
                }}>
                    <ListItemText primary={"Add to Home Screen"}
                                  secondary={"Pentru access mai rapid direct din aplicatiile telefonului tau"}
                                  classes={{
                                      // gutters: classes.listItemGutters,
                                      primary: classes.listItemPrimary,
                                      secondary: classes.listItemSecondary
                                  }}/>
                    <ListItemSecondaryAction>
                        <PwaIcon/>
                    </ListItemSecondaryAction>
                </ListItem>
            );
        }
    }
}


export default withStyles(customStyles)(AddToHomeCard);
