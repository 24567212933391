import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import _get from "lodash/get";
import _filter from "lodash/filter";
import 'date-fns';
import {Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form'
import * as Yup from 'yup';
import {Checkboxes, makeRequired, makeValidate, Select, TextField} from 'mui-rff';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {submitQuotationForm} from "../../actions/forms";
import {useDispatch, useSelector} from "react-redux";
import {PAGES_SLUGS} from "../../constants";
import {GTM_GETOFFER_INIT_FORM_PARAMS, GTM_GETOFFER_SUCCESS_FORM_PARAMS} from "../../constants/tracking";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import {withGtmTraking} from "../../constants/tracking";
import {appStyle} from "../../theme/appStyle";

const GetOfferForm = (props) => {
    const classes = useStyles();
    const subscription = {submitting: true};
    const [subscriptionState, setSubscriptionState] = useState(subscription);
    const [initailValues, setInitailValues] = useState({});
    const currentDealer = useSelector(state => state.dealer.data);

    useEffect(() => {

        if (!!currentDealer) {
            let dealers = _filter(props.dealerships, (item) => {
                return item.name == currentDealer
            })
            if (!_isEmpty(dealers)) {
                setInitailValues({"dealership_id": _get(dealers, '[0].id')});
            }
        }
    }, [])

    return (
        <div className={classes.root}>
            <Grid container justify={"center"}>
                <Grid item sm={8} xs={12}>
                    <Box
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                    >
                        <Icon className={clsx("brand-icon-contact-details", classes.sectionIcon)}/>
                        <Typography
                            variant="h2"
                            component="h2"
                            className={classes.sectionTitle}
                        >2. Contact</Typography>
                    </Box>
                </Grid>
            </Grid>


            <MainForm
                subscription={subscriptionState}
                id={props.rom_ref}
                dealerships={props.dealerships}
                initialValues={initailValues}
            />

        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    root: {},
    sectionTitle: {
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    }
}));


const schema = Yup.object().shape({
    first_name: Yup.string().required("Camp obligatoriu"),
    last_name: Yup.string().required("Camp obligatoriu"),
    email: Yup.string().required("Camp obligatoriu").email("Email invalid"),
    phone: Yup.string().required("Camp obligatoriu").matches(/^[0-9]{10}$/, 'Telefon invalid'),
    dealership_id: Yup.number().required("Camp obligatoriu"),
    terms: Yup.boolean()
        .oneOf([true], 'Camp obligatoriu')
        .required("Camp obligatoriu"),
});

/**
 * Uses the optional helper makeValidate function to format the error messages
 * into something usable by final form.
 */
const validate = makeValidate(schema);

/**
 * Grabs all the required fields from the schema so that they can be passed into
 * the components without having to declare them in both the schema and the component.
 */
const required = makeRequired(schema);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function MainForm({subscription, id, dealerships, initialValues}) {
    useEffect(() => {
        if (window.snowplow) {
            window.snowplow('enableFormTracking');
        }
    }, []);
    const classes = useFormStyles();
    const history = useHistory();
    const [openTermsDialog, setOpenTermsDialog] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openTyDialog, setOpenTyDialog] = useState(false);
    const dispatch = useDispatch();
    const onSubmit = async (values, form) => {
        try {
            values['configuration_id'] = id;
            /**TODO
             * change field name when backend api makes de update
             */
            // values['rom_ref'] = id;
            await dispatch(submitQuotationForm(values))

            // Track successful form submission with Snowplow
            // if (window.snowplow) {
            //     window.snowplow('trackStructEvent', 'Form', 'Submit', 'QuotationForm', 'Success');
            // }

            // setTimeout(form.restart);
            // setOpenTyDialog(true);
            history.push(`/${PAGES_SLUGS.OFFER_THANK_YOU}`);
        } catch (errors) {
            setOpenSnackbar(true);
            return {[FORM_ERROR]: _get(errors, "message", "Opps, Submit faild!")}
        }
    };

    const handleTermsAndConditionsDialog = () => {
        // console.log("checked:",value)
        setOpenTermsDialog(!openTermsDialog);
    };

    /*Error notification*/

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const _handleTyDialogClose = () => {
        setOpenTyDialog(false);
    };

    const getDealerships = () => {
        if (_isEmpty(dealerships)) {
            return [];
        } else {
            let options = [
                {label: 'Alege...', value: '', disabled: true},
            ];
            _map(dealerships, (item) => {
                options.push(
                    {label: item.name, value: item.id}
                )
            })
            return options;
        }
    }

    return (
        <div className={classes.formRoot}>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                subscription={subscription}
                validate={validate}
                key={subscription}
                render={({
                             submitError,
                             handleSubmit,
                             form,
                             submitting,
                             pristine,
                             values
                         }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate={true} autoComplete="new-password"
                              id="offer_request_form">
                            <Grid container justify={"center"}>
                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <TextField
                                        label="Prenume"
                                        name="first_name"
                                        required={required.first_name}
                                        variant="outlined"
                                        InputProps={{
                                            classes: {root: classes.inputRoot}
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <TextField
                                        label="Nume"
                                        name="last_name"
                                        required={required.last_name}
                                        variant="outlined"
                                        InputProps={{
                                            classes: {root: classes.inputRoot}
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <TextField
                                        label="Telefon"
                                        name="phone"
                                        type="tel"
                                        required={required.phone}
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            classes: {root: classes.inputRoot}
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <TextField
                                        label="Email"
                                        name="email"
                                        type="email"
                                        required={required.email}
                                        variant="outlined"
                                        InputProps={{
                                            classes: {root: classes.inputRoot}
                                        }}
                                    />
                                </Grid>

                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <TextField
                                        label="Mesaj"
                                        name="message"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        fullWidth
                                        classes={{
                                            root: classes.inputRoot
                                        }}
                                    />
                                </Grid>


                                <Grid item sm={8} xs={12} className={clsx(classes.formRow,{[classes.hideIfDealerInstance]:!_isEmpty(initialValues)})}>
                                    <Select
                                        label="Selecteaza dealer"
                                        name="dealership_id"
                                        required={required.dealership_id}
                                        data={getDealerships()}
                                        variant="outlined"
                                        readOnly={!_isEmpty(initialValues)}
                                        inputProps={{
                                            classes: {
                                                root: classes.inputRoot,
                                                icon: classes.dropdownIcon
                                            }
                                        }}
                                        IconComponent={ExpandMoreIcon}
                                    />
                                </Grid>

                                <Grid item sm={8} xs={12}

                                      className={clsx(classes.formRow, classes.hafPad)}>
                                    <Checkboxes
                                        name="terms"
                                        required={required.terms}
                                        formControlLabelProps={{
                                            classes: {label: classes.checkboxLabel}
                                        }}
                                        color="primary"
                                        data={{
                                            label: <span>Sunt de acord cu <Link href={`/${PAGES_SLUGS.TERMS_AND_CONDITIONS}`}
                                                                          target={"_blank"}>termenii si conditiile</Link>.</span>,
                                            value: false,
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={8} xs={12}
                                      className={classes.formRow}>
                                    <Checkboxes
                                        name="contact_optin"
                                        formControlLabelProps={{
                                            classes: {label: classes.checkboxLabel}
                                        }}
                                        color="primary"
                                        data={{
                                            label: <span>Doresc sa primesc informatii si oferte legate de modelele Volvo.</span>,
                                            value: false,
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <Button variant="outlined" color="primary" type="submit" disabled={submitting}>
                                        Trimite
                                        {submitting &&
                                        <CircularProgress size={24} className={classes.buttonProgress}/>}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                                <Alert onClose={handleCloseSnackbar} severity="error">
                                    Oops! Something went wrong! Please check your info and retry.
                                </Alert>
                            </Snackbar>
                        </form>
                    )
                }
                }
            />

        </div>
    )

}

const useFormStyles = makeStyles((theme) => ({

    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    withLineDivider: {
        position: "relative",
    },
    formRoot: {},
    inputRoot: {
        background: "#fff",
    },
    hafPad: {},
    formRow: {
        paddingBottom: theme.spacing(3),
        "&$hafPad": {
            paddingBottom: theme.spacing(1),
        }
    },
    formTextbox: {
        paddingTop: theme.spacing(0),
    },
    formCheckbox: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(5),
    },
    formTextboxLabel: {
        textAlign: "center",
        paddingBottom: theme.spacing(3),
    },
    formControl: {},
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    driverData: {
        position: "relative",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    driverDataInner: {
        margin: "0 auto",
        padding: `0 ${theme.spacing(3)}px`,
        background: "#FAFAFA",
        zIndex: 2,
    },
    divider: {
        width: '100%',
        position: "absolute",
        top: "50%",
        left: 0,
        transform: "translateY(-50%)",
    },
    icon: {
        fontSize: 32,
        marginRight: 10,
    },
    dropdownIcon: {
        color: theme.palette.primary.main,
    },
    checkboxLabel: {
        fontSize: theme.typography.pxToRem(14),
        // lineHeight: theme.typography.pxToRem(32),
    },
    hideIfDealerInstance: {
        display: "none",
    }
}));
export default GetOfferForm
