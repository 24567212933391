import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {PAGES_SLUGS} from "../constants";
import Header from "../components/Header";
import Box from "@material-ui/core/Box";
import Footer from "../components/Footer";
import FallbackComponent from "../components/FallbackComponent";
import {isIE} from "react-device-detect"
import HomePage from '../containers/HomePage';
import DetailsPage from '../containers/DetailsPage';
import Cars from '../containers/CarsListPage';
import Accessories from "../containers/AccessoriesListPage";
import AccessoryDetailsPage from '../containers/AccessoryDetailsPage';
import GetOfferPage from '../containers/GetOfferPage';
import NotFound from '../containers/NoMatchPage';
import AboutUsPage from '../containers/AboutUsPage';
import FinancePage from '../containers/FinancePage';

import ExtendedWarrantyPage from '../containers/ExtendedWarrantyPage';
import TermsAndConditionsPage from '../containers/TermsAndConditionsPage';
import DealershipsPage from '../containers/DealershipsPage';
import CookiePolicyPage from '../containers/CookiePolicyPage';
import ConfidentialityPolicyPage from '../containers/ConfidentialityPolicyPage';
import TestDriveThankYouPage from '../containers/TestDriveThankYouPage';
import GetOfferThankYouPage from '../containers/GetOfferThankYouPage';
import BrowserNotSupportedPage from '../containers/BrowserNotSupportedPage';
import AccessoriesIntro from "../containers/AccessoriesIntroPage";
import WishlistPage from "../containers/WishlistPage";
import AccessoryGetOfferPage, {parameterType} from "../containers/AccessoryGetOfferPage";
import SnowplowAnalytics from '../components/SnowplowAnalytics';

export default () => {
    if (isIE) {
        return <Router>
            <Switch>
                <Route>
                    <BrowserNotSupportedPage/>
                </Route>
            </Switch>
        </Router>
    }
    return (
        <Box display={"flex"} flexDirection={"column"} minHeight={"calc(100vh + 230px)"}>
            <Router>
                <SnowplowAnalytics />
                <Header/>
                    <Box display={"flex"} flexDirection={"column"} flex={"1 auto"}>
                        <Switch>
                            <Route exact path='/'>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <HomePage/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.DETAILS}/:slug`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <DetailsPage/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.PRODUCTS}`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <Cars/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.ACCESSORY_DETAIL}/:slug`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <AccessoryDetailsPage/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.ACCESSORIES}`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <Accessories/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.ACCESSORIES_INTRO}`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <AccessoriesIntro/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.DEALER}`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <Cars/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.OFFERS}/:slug`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <GetOfferPage/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.ACCESSORY_OFFER}/:urlParameter`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <AccessoryGetOfferPage parameterType={parameterType.SLUG}/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.ACCESSORIES_OFFERS}/:urlParameter`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <AccessoryGetOfferPage parameterType={parameterType.UUID}/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.OFFERS}`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <Redirect to="/"/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.ABOUT_US}`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <AboutUsPage/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.FINANCE}`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <FinancePage/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.EXTENDED_WARRANTY}`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <ExtendedWarrantyPage/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.DEALERSHIPS}`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <DealershipsPage/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.TERMS_AND_CONDITIONS}`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <TermsAndConditionsPage/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.COOKIE_POLICY}`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <CookiePolicyPage/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.CONFIDENTIALITY_POLICY}`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <ConfidentialityPolicyPage/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.TESTDRIVE_THANK_YOU}`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <TestDriveThankYouPage/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.OFFER_THANK_YOU}`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <GetOfferThankYouPage/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.WISHLIST}`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <WishlistPage/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route exact path={`/${PAGES_SLUGS.WISHLIST}/:urlUuid`}>
                                <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={true}>
                                    <WishlistPage/>
                                </Sentry.ErrorBoundary>
                            </Route>
                            <Route>
                                <NotFound/>
                            </Route>
                        </Switch>
                    </Box>
                <Footer/>
            </Router>
        </Box>
    )
}
