import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import products from "./products";
import models from "./models";
import modelsList from "./modelsList";
import dealer from "./dealer";
import dealerships from "./dealerships";
import bestPrice from "./bestPrice";
import bestOffers from "./bestOffers";
import pages from "./pages";
import testdrive from "./testdrive";
import quotation from "./quotation";
import pwa from "./pwa";
import carsReducer from '../containers/CarsListPage/reducer';
import accessories from '../containers/AccessoriesListPage/reducer';
import accessoryModels from '../containers/AccessoriesIntroPage/reducer';
import accessoryDetails from '../containers/AccessoryDetailsPage/reducer';
import wishlist from '../containers/WishlistPage/reducer';

export default (history) => combineReducers({
    router: connectRouter(history),
    products,
    models,
    modelsList,
    dealer,
    dealerships,
    bestPrice,
    bestOffers,
    quotation,
    testdrive,
    cars: carsReducer,
    accessories,
    accessoryModels,
    accessoryDetails,
    pages,
    pwa,
    wishlist
});


