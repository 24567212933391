import React, {useState} from 'react';
import useNativeLazyLoading from '@charlietango/use-native-lazy-loading';
import {useInView} from 'react-intersection-observer';
import Skeleton from "@material-ui/lab/Skeleton";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";


const LazyImage = ({ratio, src,isAnimated=false, ...rest}) => {
    const classes = useStyles();
    const supportsLazyLoading = useNativeLazyLoading();
    const [loaded, setLoaded] = useState(false);
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '200px 0px',
    });

    const handleLoad = () => {
        setLoaded(true)
    }
    return (
        <div
            ref={!supportsLazyLoading ? ref : undefined}
            className={classes.imageWrapper}
            style={  {paddingTop: `${ratio}%`,}}
        >
            {inView || supportsLazyLoading ? (
                <img
                    {...rest}
                    src={src}
                    // width={width}
                    // height={height}
                    loading="lazy"
                    onLoad={handleLoad}
                className={clsx(classes.image,{
                    "lazy-loaded":loaded,
                    [classes.animation]:isAnimated
                })}
                />
            ) : null}
            {!loaded && <Skeleton variant={'rect'} width="100%" height={"100%"}
                                  className={"preloader-skeleton"}/>}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    imageWrapper: {
        width: "100%",
        position: "relative",
        overflow:"hidden",
    },
    image: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "contain"
    },

    animation:{
        transform: "scale(1.01)",
        transition: "transform 300ms",
        "&:hover": {
            transform: "scale(1.08)",
        }
    }

}))

export default LazyImage;
