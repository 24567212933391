import React, {Component} from 'react';
import {InstantSearch} from "react-instantsearch-dom";
// UI
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import extractFilterName from "../../../helpers/extractFilterName";
import {
    dealershipFacetName,
    expandedPanels,
    hiddenPanels,
    indexName,
    priceItems,
    searchClient
} from "../../../constants/algolia";
import Icon from "@material-ui/core/Icon";
import filterStyles from "../../../theme/filterStyles";
import AlgoliaFiltersDesktop from "./AlgoliaFiltersDesktop";
import CustomRangeInput from "./AlgoliaInputMenu";
import Box from "@material-ui/core/Box";
import _sortBy from "lodash/sortBy";
import RenderCarFilters from "../../Algolia/RenderCarFilters";

const customStyles = theme => ({
    ...filterStyles(theme),
    customBar: {
        minHeight: 56,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderBottom: `1px solid #EEE`,
    },
    appTitle: {
        display: `flex`,
        alignItems: `center`
    },
    dialogContent: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(1),
        // Height of DialogActions: font size * rem * line height + button padding + component margins + border
        paddingBottom: 53,
        // paddingBottom: 83
        // overflow:"hidden"
    },
    viewResultsButton: {
        letterSpacing: 0,
        borderRadius: 0,
        fontSize: `0.875rem`
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class AlgoliaFiltersMobile extends Component {
    render() {
        const {classes, searchState, onSearchStateChange} = this.props;
        return (
            <Dialog
                className={classes.hiddenMdUp}
                fullScreen
                // open={true}
                open={this.props.open}
                onClose={this.props.onClose}
                TransitionComponent={Transition}
            >
                <AppBar position={'sticky'} color={"transparent"} elevation={0} >
                    <Toolbar className={classes.customBar}>
                    <Box justifyContent={"space-between"} display={"flex"} flexGrow={1}>
                        <Typography variant="body1" color="inherit" className={`${classes.weight300} ${classes.appTitle}`}>
                            <Icon className={`${classes.iconRightMargin} brand-icon-filter`}/>
                            Filtreaza dupa specificatii
                        </Typography>
                        <IconButton color="inherit" onClick={this.props.onClose} aria-label="Close">
                            <span className={`brand-icon-close brand-icon-medium`}>
                            </span>
                        </IconButton>
                    </Box>
                    </Toolbar>
                </AppBar>
                {/*To keep track of the refinements applied inside the Modal (or another screen if you have navigation),
                you’ll need to use another InstantSearch component and synchronize the search state between them.*/}
                <InstantSearch
                    indexName={indexName} searchClient={searchClient}
                    searchState={searchState}
                    onSearchStateChange={onSearchStateChange}
                >
                    <DialogContent classes={{root:classes.dialogContent}}>
                        <RenderCarFilters
                            facets={this.props.facets}
                            isDealerPage={this.props.isDealerPage}
                            isMobileFilter={true}
                        />
                    </DialogContent>
                </InstantSearch>

                <DialogActions className={classes.dialogActions}>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={this.props.onClose}
                        fullWidth
                        className={`${classes.weight300} ${classes.viewResultsButton}`}
                        // classes={{label: }}
                    >
                        Vezi rezultate
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(customStyles)(AlgoliaFiltersMobile);
