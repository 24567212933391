import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";

const OverviewCard = (props) => {
    const classes = useStyles();
    return(
        <Container>
            <Divider/>
            <div className={classes.root}>
                {props.children}
            </div>
            {props.endingDivider && <Divider className={classes.endingDivider}/>}
        </Container>
    )
}
const useStyles = makeStyles((theme) => ({
    root:{
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(3),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        "& a": {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightLight,
            fontSize: theme.typography.pxToRem(12),
            lineHeight: theme.typography.pxToRem(24),
            letterSpacing: "0",
            marginBottom: theme.spacing(0),
            wordBreak: "break-word",
            position: 'relative',
            textDecoration: "none",
            "&:hover": {
                textDecoration: "none",
            },
            "&:before": {
                content: `''`,
                position: "absolute",
                width: "0",
                height: "1px",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                background: theme.palette.primary.main,
                transition: theme.transitions.create(['width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            "&:hover:before": {
                content: `''`,
                position: "absolute",
                width: "100%",
                height: "1px",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                background: theme.palette.primary.main
            }
        }
    },
    endingDivider: {
        marginTop: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
        },
    }
}));

OverviewCard.propTypes = {
    endingDivider: PropTypes.bool
}

OverviewCard.defaultProps = {
    endingDivider: false
};
export default OverviewCard;
