import React from 'react';
import _get from "lodash/get";
/*ui*/
import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
/*component*/
import listingCardGeneralStyles from "../../../theme/listingCardGeneralStyles";
import toLocale from "../../../helpers/toLocale";

const useStyles = makeStyles((theme) => ({
    ...listingCardGeneralStyles(theme),
}))

/**
 * Confused it with price renderer from search list
 * Leaving this functional component even if it has one usage
 */
const DetailsCarPrice = (carData) => {
    const classes = useStyles();

    if (!carData.total_car_price) return;

    const showDiscountPrice = Boolean(carData.client_invoice_price) && carData.client_invoice_price !== carData.total_car_price;

    if (!showDiscountPrice) {
        return <div className={classes.noOldPrice}>{toLocale(carData.total_car_price)}€ +TVA</div>
    } else {
        return (
            <React.Fragment>
                <div>
                    <div>
                        {toLocale(carData.client_invoice_price)}€ + TVA
                    </div>
                    <Typography align={"left"} component={"div"} variant="caption" className={classes.oldPrice}>
                        <span className={classes.lineThrough}>
                            {toLocale(carData.total_car_price)}€
                        </span>
                        <span className={classes.oldPriceDiscount}>
                            + TVA (-{`${toLocale(_get(carData, "discount_percentage", ""))}`}%)
                        </span>
                    </Typography>
                </div>
            </React.Fragment>
        )
    }
}

export default DetailsCarPrice;
