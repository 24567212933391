import React from 'react';
import {makeStyles} from "@material-ui/core/styles";


const SpaceBox =()=>{
    const classes = useStyles();
    return <div className={classes.root} ></div>
}

const useStyles = makeStyles((theme) => ({
  root:{
      width:"100%",
      height:60,
      [theme.breakpoints.down('sm')]: {
          height:32,
      },
  }
}));
export default SpaceBox