import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import clsx from "clsx";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import _map from "lodash/map";
import ReactHtmlParser from "react-html-parser";

const RederThead = (props) => {
    const {data} = props;
    const classes = useTheadStyles();

    return <Grid container className={classes.root}>
        <Grid item xs={3} className={classes.col}>
            {ReactHtmlParser(data[0])}
        </Grid>
        <Grid item xs={3} className={classes.col}>
            {ReactHtmlParser(data[1])}
        </Grid>
        <Grid item xs={3} className={classes.col}>
            {ReactHtmlParser(data[2])}
        </Grid>
        <Grid item xs={3} className={classes.col}>
            {ReactHtmlParser(data[3])}
        </Grid>
    </Grid>
}

const useTheadStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#141414",
        width: "100%",
    },
    col: {
        padding: theme.spacing(2),
        textAlign: "center",
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(26),
        lineHeight: theme.typography.pxToRem(32),
        letterSpacing: "0.4",
        color: "#fff",
        "& span": {
            display: "block",
            fontWeight: theme.typography.fontWeightLight,
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(24),
            letterSpacing: "0",
        }
    }
}))

const RederRow = (props) => {
    const {data} = props;
    const classes = useRowStyles();

    return <Grid container className={classes.root}>
        <Grid item xs={3} className={clsx(classes.col, classes.first)}>
            {ReactHtmlParser(data[0])}
        </Grid>
        <Grid item xs={3} className={classes.col}>
            {ReactHtmlParser(data[1])}
        </Grid>
        <Grid item xs={3} className={classes.col}>
            {ReactHtmlParser(data[2])}
        </Grid>
        <Grid item xs={3} className={classes.col}>
            {ReactHtmlParser(data[3])}
        </Grid>
    </Grid>
}


const useRowStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        width: "100%",
    },
    col: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(2),
        textAlign: "center",
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(26),
        lineHeight: theme.typography.pxToRem(32),
        letterSpacing: "0.4",
        color: theme.palette.secondary.main,
        borderBottom: "1px solid  #EEEEEE",
        "& span": {
            display: "block",
            fontWeight: theme.typography.fontWeightLight,
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(24),
            letterSpacing: "0",
        }
    },
    first: {
        background: "#FAFAFA",
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(28),
        letterSpacing: "0.24",
    }

}))

const RenderTableMobile = (props) => {
    const {data} = props;
    const classes = useTableMobileStyles();
    const items = []

    _map(data[0], (item, index) => {
        let period;
        let rows = [];
        if (index != 0) {
            period = item;
            for (let i = 1; i < data.length; i++) {

                rows.push({
                    text: data[i][0],
                    value: data[i][index]
                })
            }
            items.push({
                period: period,
                rows: rows,
            })
        }


    })

    return _map(items, (item, index) => {
        return <div key={index} className={classes.root}>
            <Grid container>
                <Grid item xs={12} className={classes.title}>
                    {ReactHtmlParser(item.period)}
                </Grid>
            </Grid>
            <Grid container className={classes.body}>
                {_map(item['rows'], (row, idx) => {
                    return <React.Fragment key={`col${idx}`}>
                        <Grid item xs={8} className={clsx(classes.col, classes.first)}>
                            {row.text}
                        </Grid>
                        <Grid key={idx} item xs={4} className={classes.col}>
                            {row.value}
                        </Grid>
                    </React.Fragment>
                })}
            </Grid>

        </div>
    })
}


const useTableMobileStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        width: "100%",
        marginBottom: theme.spacing(3),
    },

    title: {
        backgroundColor: "#141414",
        padding: theme.spacing(2),
        textAlign: "center",
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(20),
        lineHeight: theme.typography.pxToRem(24),
        letterSpacing: "0.4",
        color: "#fff",
        "& span": {
            display: "block",
            fontWeight: theme.typography.fontWeightLight,
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(18),
            letterSpacing: "0",
        }
    },
    col: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(2),
        textAlign: "center",
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(18),
        letterSpacing: "0.4",
        color: theme.palette.secondary.main,
        borderBottom: "1px solid  #EEEEEE",
    },
    first: {
        background: "#FAFAFA",
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(20),
        letterSpacing: "0.24",
    }

}))

const TableWidget = (props) => {
    const classes = useStyles();
    if (_isEmpty(_get(props, "data", []))) {
        return null
    }
    return <>
        <Hidden xsDown>
            <div className={classes.root}>
                <RederThead data={props.data[0]}/>
                <Grid container>
                    {_map(props.data, (item, index) => {
                        if (index != 0) {
                            return <RederRow key={index} data={item}/>
                        }
                    })}
                </Grid>
            </div>
        </Hidden>
        <Hidden smUp>
            <RenderTableMobile data={props.data}/>
        </Hidden>

    </>
}


const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(3)
    }
}))


export default TableWidget