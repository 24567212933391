import React from 'react';
import _sortBy from "lodash/sortBy";
import _isEmpty from "lodash/isEmpty";

import {expandedAccPanels, hiddenAccPanels, priceAccItems} from "../../constants/algolia";

import AlgoliaHierarchicalMenu from "../accessories/AlgoliaHierarchicalMenu";
import CustomRangeInput from "../ContentBlocks/Cars/AlgoliaInputMenu";
import AlgoliaFiltersDesktop from "../ContentBlocks/Cars/AlgoliaFiltersDesktop";
import extractFilterName from "../../helpers/extractFilterName";
import {showLimit} from "../../containers/AccessoriesListPage/constants";

const RenderAccessoryFilters = (props) => {
    const {facets} = props;

    if (facets.length !== 0) {
        const hierarchicalArray = [];
        facets.map((item) => {
            if (item.includes('hierarchical')) {
                hierarchicalArray.push(item);
            }
        });
        return (
            <>
                {!_isEmpty(hierarchicalArray) &&
                <AlgoliaHierarchicalMenu
                    expanded={expandedAccPanels.includes(("hierarchical"))}
                    attributes={hierarchicalArray}
                    attribute={hierarchicalArray[0].replace("hierarchical", "")}
                />}

                {facets.map((item, index) => {
                    if (item.includes('category')) return null;
                    if (item.includes('hierarchical')) return null;
                    if (hiddenAccPanels.includes(item)) return null;

                    /**
                     * Using custom numeric input filtering for prices
                     */
                    if (item === "final_price") return <CustomRangeInput
                        key={`${index}${item}`}
                        attribute='final_price'
                        expanded={expandedAccPanels.includes("final_price")}
                        staticPriceRanges={priceAccItems}
                    />

                    return (
                        <AlgoliaFiltersDesktop
                            expanded={expandedAccPanels.includes(extractFilterName(item))}
                            key={`${index}${item}`}
                            attribute={item}
                            transformItems={items =>
                                _sortBy(items, 'label')
                            }
                            limit={showLimit}
                            showMore={true}
                        />
                    )
                })}
            </>
        )
    }

    return null;
};

export default RenderAccessoryFilters
