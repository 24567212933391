import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import _get from "lodash/get";
import Link from '@material-ui/core/Link';
import { useLocation, useHistory } from "react-router-dom";
/*components*/
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Icon from "@material-ui/core/Icon";
import clsx from "clsx";
import {PAGES_SLUGS} from "../constants";

/**
 * All links to car details must have a `from` parameter otherwise the back button will fallback to car listing
 * Passing in location.pathname value, but whole location object can be passed for finer manipulation (search) because
 * we only check if it exists
 */
export const Breadcrumb = (props) => {
    const {name, buttonName} = props;
    const classes = useStyles();
    let history = useHistory();
    const location = useLocation();
    const _previousPage = _get(location, "state.from", null);

    function handleNavigation(event) {
        event.preventDefault();
        if (_previousPage === null) {
            history.push(`/${PAGES_SLUGS.PRODUCTS}`);
        } else {
            history.goBack();
        }
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                <Link color="inherit"
                    onClick={handleNavigation}
                      className={classes.link}>
                    <Icon className={clsx("brand-icon-back", classes.icoBack)}/>
                    {buttonName}
                </Link>
                <Typography color="textPrimary" className={classes.currentLink}>
                    {name}
                </Typography>
            </Breadcrumbs>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    link: {
        display: 'flex',
        alignItems: "center",
        color: "#707070",
        fontSize: "14px",
        fontWeight: 300,
        letterSpacing: 0,
        lineHeight: "24px",
        cursor:"pointer",
    },
    currentLink: {
        display: 'flex',
        alignItems: "center",
        color: theme.palette.secondary.main,
        fontSize: "14px",
        fontWeight: 300,
        letterSpacing: 0,
        lineHeight: "24px"

    },
    icoBack: {
        fontSize: "20px",
        marginRight: theme.spacing(1),
    },
    breadcrumb: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
        },
    }
}));
