import React from 'react';
import _get from "lodash/get";
/*ui*/
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
/*component*/
import listingCardGeneralStyles from "../../../theme/listingCardGeneralStyles";
import toLocale from "../../../helpers/toLocale";
import _isEmpty from "lodash/isEmpty";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import _map from "lodash/map";

const searchCarPrice = (carData, classes) => {
    if (!carData.total_car_price) return;

    if (!carData.client_invoice_price) {
        return `${toLocale(carData.total_car_price)}€ +TVA`
    } else {
        return (
            <React.Fragment>
                <div>{toLocale(carData.client_invoice_price)}€ + TVA
                    <Typography variant="caption"
                                className={classes.oldPrice}>{toLocale(carData.total_car_price)}€</Typography> + TVA</div>
            </React.Fragment>
        )
    }
}

export default searchCarPrice;
