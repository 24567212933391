import axios from 'axios';
import _get from "lodash/get";
import * as ActionTypes from '../constants/actionTypes';
import * as api from '../api';
import setHeaders from "../helpers/setHeaders";
import handleErrors from "../helpers/handleErrors";

export function fetchModels() {
    return function (dispatch) {

        dispatch({
            type: ActionTypes.MODELS_REQUEST
        });
        // return new Promise(
        //     (resolve, reject) => {
        axios.get(`${api.API_MODELS}`, setHeaders()).then((response) => {
            dispatch({
                type: ActionTypes.MODELS_SUCCESS,
                payload: _get(response,"data.data",{})
            });
            // resolve(data.data);
        }).catch((response) => {
            const {errors, message} = handleErrors(response);
            dispatch({
                type: ActionTypes.MODELS_ERROR,
                payload: errors
            });
            // reject();
        });
        // })
    }
}

export function toggleModelModal(state) {
    return {
        type: ActionTypes.TOGGLE_MODELS_MODEL,
        payload: state
    };
}
