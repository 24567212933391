import * as ActionTypes from '../constants/actionTypes';

export const initialState = {
    install: false,
};

export default function products(state = initialState, action) {
    switch (action.type) {

        case ActionTypes.ADD_TO_HOME_SCREEN:
            return {
                ...state,
                install: action.payload,
            };

        default :
            return state;
    }
}
