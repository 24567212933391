import React, {useEffect} from 'react'
import clsx from "clsx";
import {useDispatch, useSelector} from 'react-redux'
import Fade from "react-reveal/Fade";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import * as actions from "../../actions/pages"
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SectionBox from "../../components/SectionBox";
import ProgressFullScreen from "../../components/ProgressFullScreen";
import {PAGES_SLUGS} from "../../constants";
import Seo from "../../components/seo/Seo";
import ReactHtmlParser from "react-html-parser";
import {Text, Title} from "../../components/Typografy";
import _map from "lodash/map";
import TableWidget from "../../components/TableWidget";
import OfferCard from "../../components/OfferCard";

import chrome from "../../../images/chrome.png";
import edge from "../../../images/edge.png";
import firefox from "../../../images/firefox.png";
import opera from "../../../images/opera.png";
import safari from "../../../images/safari.png";
import {headerTheme} from "../../theme";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ButtonBase from "@material-ui/core/ButtonBase";
import {Link as RouterLink} from "react-router-dom";
import {Logo, LogoContrast} from "../../components/BrandItems";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

function BrowserNotSupportedPage(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const pageData = useSelector(state => _get(state.pages.data, PAGES_SLUGS.EXTENDED_WARRANTY, {}));
    const loading = useSelector(state => state.pages.request);
    const error = useSelector(state => state.pages.error);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (_isEmpty(pageData)) {
            dispatch(actions.fetchPage(PAGES_SLUGS.EXTENDED_WARRANTY))
        }
    }, [])

    return (
        <React.Fragment>
            <ThemeProvider theme={headerTheme}>
                <AppBar
                    position="sticky"
                    className={classes.appbar}
                    elevation={0}
                >
                    <Toolbar className={classes.toolbar}>
                        <div className={classes.headerTitle}>
                            <ButtonBase component={Link} to={"/"} className={classes.logo}>
                                <Logo/>
                            </ButtonBase>
                        </div>
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
            <div className={classes.bodyRoot}>
                <SectionBox>
                    <Fade>
                        <Container maxWidth={"md"}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        align={"center"}
                                        className={classes.title}
                                    >
                                        Ne pare rau! Browser-ul dumneavoastra nu poate incarca site ul.
                                    </Typography>
                                    <Typography align={"center"} variant={"body1"} component={"div"}   className={classes.text}>
                                        Va recomandam sa accesati <Link href={"https://www.ofertevolvo.ro/"}>OferteVolvo.ro</Link> cu unul din browserele de mai jos:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar alt="Remy Sharp" src={chrome} className={classes.large}/>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Google Chrome"
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar alt="Remy Sharp" src={edge} className={classes.large}/>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Microsoft Edge"
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar alt="Remy Sharp" src={firefox} className={classes.large}/>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Mozilla Firefox"
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar alt="Remy Sharp" src={opera} className={classes.large}/>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Opera"
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar alt="Remy Sharp" src={safari} className={classes.large}/>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Safari"
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>

                            </Grid>
                        </Container>
                    </Fade>
                </SectionBox>
            </div>
            <footer className={classes.footerRoot}>
                <Container maxWidth="sm">
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography component={"p"} align={"center"} className={classes.caption}>
                                Drepturi de autor ©{new Date().getFullYear()} Volvo Car Corporation (afiliaţii sau
                                licenţiatorii acestora).
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container justify={"center"} className={classes.footerLogo}>
                            <LogoContrast/>
                        </Grid>
                    </Grid>


                </Container>
            </footer>
        </React.Fragment>
    )
}


const useStyles = makeStyles((theme) => ({
    bodyRoot: {
        minHeight:"calc(100vh - 252px)"
    },
    title: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(4),
    },
    text: {
        paddingBottom: theme.spacing(8),
    },
    appbar: {
        backgroundColor: "#FAFAFA",
    },
    toolbar: {
        minHeight: 64,
        paddingLeft: 20,
        paddingRight: 20,
    },
    logo: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginLeft: -4,
    },
    footerRoot: {
        background: "#161618",
        paddingTop: 60,
        paddingBottom: 60,
    },
    footerLogo: {
        paddingTop: theme.spacing(5),
    },
    caption: {
        fontSize: 14,
        lineHeight: "20px",
        fontWeight: 300,
        letterSpacing: 0,
        color: "#999999"
    },
}))


export default BrowserNotSupportedPage;
