import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import _uniqueId from "lodash/uniqueId"
import _map from "lodash/map";
import {SwiperSlide} from 'swiper/react';
import Slider from "../sliders/Slider";
/*demo image*/
import clsx from "clsx";
import {sliderStyle} from "../../theme/appStyle";
import DetailedAccCard from "./DetailedAccCard";

const RelatedAccessoriesSlider = (props) => {
    const classes = useStyles();
    const paginationEl = _uniqueId("related-pagination-");

    return (
        <div className={classes.sliderRoot}>
            <Slider
                spaceBetween={16}
                slidesPerView={1}
                slidesPerGroup={1}
                pagination={{
                    el: `.${paginationEl}`,
                    dynamicBullets: false,
                    clickable: true
                }}
                preloadImages={false}
                lazy={{
                    preloaderClass: "preloader-skeleton"
                }}
                breakpoints={{
                    // when window width is >= 768
                    420: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 16
                    },
                    640: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        spaceBetween: 16,
                        centeredSlides:false
                    },
                    // when window width is >= 1200:
                    1024: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 24
                    },
                    // when window width is >= 1600
                    1600: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 24
                    }
                }}
            >
                {_map(props.data, (item) => {
                    return <SwiperSlide key={item.slug} style={{height: `auto`}}>
                        <DetailedAccCard data={item} fromDetailsPage={true}/>
                    </SwiperSlide>
                })}
            </Slider>
            <div className={clsx(paginationEl, classes.paginationContainer)}>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    ...sliderStyle(theme)
}))

export default RelatedAccessoriesSlider
