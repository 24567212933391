import {appStyle} from "./appStyle";

const listingCardGeneralStyles = theme => ({
    ...appStyle(theme),
    carCard: {
        height: `100%`,
        backgroundColor: "#FAFAFA",
    },
    imageContainer: {
        width: "100%",
        backgroundColor: "#FAFAFA",
        display: "block",
        overflow: "hidden",
        marginBottom: theme.spacing(1)
    },
    image: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        transform: "scale(1.01)",
        transition: "transform 300ms",
        "&:hover": {
            transform: "scale(1.08)",
        }
    },
    linkDecoration: {
        textDecoration: `none`,
        display: `flex`,
        color: `initial`,
        height: `100%`,
        flexDirection: `column`,
    },
    sku: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(12),
    },
    idCaption: {
        display: `block`,
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    detailsContainer: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(1.25),
    },
    carName: {
        fontSize: `1.125rem`,
        lineHeight: 1.33,
        color: `#333333`,
        flexGrow: 1
    },
    noOldPrice: {
    /*Add oldPrice height as margins*/
        marginTop: `${1.75 * .875 / 2}rem`,
        marginBottom: `${1.75 * .875 / 2}rem`
    },
    oldPrice: {
        fontSize: `0.875rem`,
        lineHeight: 1.75
    },
    lineThrough: {
        textDecoration: `line-through`,
    },
    chipRow: {
        padding: theme.spacing(1),
    },
    oldPriceDiscount:{
        "&:before": {
            content: `""`,
            display: "inline-block",
            width: "2px"
        }
    },
    wishlistHeart: {
        position: `absolute`,
        width: 48,
        height: 48,
        padding: 12,
        bottom: 12,
        right: 12,
        borderRadius: 4,
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
        boxSizing: `border-box`,
        pointerEvents: `none`
    },
    wlIcon: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(24),
    }
});

export default listingCardGeneralStyles;
