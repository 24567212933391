import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

/**
 * Disable Sentry on localhost via env variable
 */

export default function () {
    if (process.env.REACT_APP_SENTRY_DSN === 'https://invalid.local') return;

    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            new Integrations.BrowserTracing(),
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.1,
    });
}
