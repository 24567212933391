import React from 'react';
import Helmet from "react-helmet";
import _get from "lodash/get";

/**
 "meta_image": "https://volvo-cab-dev.s3-eu-west-1.amazonaws.com/uploads/pages/Hh5yV5Q4lLddGUE6kWone4tgmVLgMnFxiFzv7tWi.jpeg",
 "meta_title": "Despre Volvo Cars",
 "meta_og_title": "Despre Volvo Cars",
 "meta_description": "Despre Volvo Cars",
 "meta_og_description": "Despre Volvo Cars"
 */

const Seo = (props) => {
    const {data} = props;
    const helmetImage = _get(data, 'meta_image', '');
    const helmetDescription = _get(data, 'meta_description', 'Descopera cele mai bune oferte ale momomentului pentru modelul Volvo preferat');
    const helmetTitle = _get(data, 'meta_title', 'Home | Oferte Volvo');
    const helmetOgTitle = _get(data, 'meta_og_title', 'Home | Oferte Volvo');
    const helmetOgDescription = _get(data, 'meta_og_description', 'Descopera cele mai bune oferte ale momomentului pentru modelul Volvo preferat');
    return <Helmet
        title={helmetTitle}
        description={helmetDescription}
        meta={[
            {name: "title", content: helmetTitle},
            {name: "description", content: helmetDescription},
            // Facebook
            {property: "og:title", content: helmetOgTitle},
            {property: "og:image", content: helmetImage},
            {property: "og:description", content: helmetOgDescription},
            {property: "og:image:width", content: _get(data, 'width', 800)},
            {property: "og:image:height", content: _get(data, 'height', 800)},
            {property: "og:url", content: window.location.href},
            // Twitter specific; it shares the other from above
            {name: "twitter:card", content: "summary"},
        ]}
    />
}

export default Seo
