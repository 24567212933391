/*PRODUCTS*/
export const PRODUCT_REQUEST = 'product_request';
export const PRODUCT_SUCCESS = 'product_success_request';
export const PRODUCT_ERROR = 'product_error_request';

/*MODELS*/
export const MODELS_REQUEST = 'models_request';
export const MODELS_SUCCESS = 'models_success_request';
export const MODELS_ERROR = 'models_error_request';
export const TOGGLE_MODELS_MODEL = 'toggle_models_model';

/*ALL MODELS*/
export const ALL_MODELS_REQUEST = 'all_models_request';
export const ALL_MODELS_SUCCESS = 'all_models_success_request';
export const ALL_MODELS_ERROR = 'all_models_error_request';

/*CUURENT DEALER*/
export const DEALER_SET_CURRENT_VALUE = 'dealer_current_value';

/*ALL DEALERSHIPS*/
export const DEALERSHIPS_REQUEST = 'DEALERSHIPS_REQUEST';
export const DEALERSHIPS_SUCCESS = 'DEALERSHIPS_SUCCESS';
export const DEALERSHIPS_ERROR = 'DEALERSHIPS_ERROR';

/*ALL FORM TESTDRIVE*/
export const TESTDRIVE_REQUEST = 'testdrive_request_request';
export const TESTDRIVE_SUCCESS = 'testdrive_success_request';
export const TESTDRIVE_ERROR = 'testdrive_error_request';

/*ALL MODELS*/
export const PAGES_REQUEST = 'pages_request_request';
export const PAGES_SUCCESS = 'pages_success_request';
export const PAGES_ERROR = 'pages_error_request';

/* best-offer-by-model */
export const BEST_OFFER_REQUEST = 'best_offer_request';
export const BEST_OFFER_SUCCESS = 'best_offer_success';
export const BEST_OFFER_ERROR = 'best_offer_error';

/*  best-price-by-model */
export const BEST_PRICE_REQUEST = 'best_price_request';
export const BEST_PRICE_SUCCESS = 'best_price_success';
export const BEST_PRICE_ERROR = 'best_price_error';

/*  best-price-by-model */
export const QUOTATION_REQUEST = 'quotation_request';
export const QUOTATION_SUCCESS = 'quotation_success';
export const QUOTATION_ERROR = 'quotation_error';

/*PWA*/
export const ADD_TO_HOME_SCREEN = 'add_to_home_screen';





