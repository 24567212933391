import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Button from "@material-ui/core/Button/Button";
import IconButton from '@material-ui/core/IconButton';
import Icon from "@material-ui/core/Icon";
import clsx from "clsx";


function TransitionUp(props) {
    return <Slide {...props} direction="down"/>;
}

const styles = theme => ({
    snackbar: {
        zIndex: `${theme.zIndex.appBar - 1}!important`,
        marginTop: theme.spacing(7),
        padding: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
            width: "80%",
            maxWidth: "460px",
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(7),
            left: 0,
            right: 0,
        },
    },
    messageRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        width: '100%',
        display: 'flex',
        position: 'relative',
        boxSizing: 'border-box',
        textAlign: 'left',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    content: {
        flex: '1 1 auto',
        padding: '0',
        minWidth: '0',

        [theme.breakpoints.down('xs')]: {
            marginRight: theme.spacing(1),
        },
    },
    snackbarContent: {
        background: "#1C6BBA",
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(17),
        color: "#fff",
        padding: theme.spacing(0),
        position: "relative",
    },
    fullWidth: {
        flex: "1 auto",
    },
    action: {
        margin: theme.spacing(0),
        padding: theme.spacing(0),
    },
    btn: {
        margin: theme.spacing(0),
        color: "#1C6BBA",
    },
    close: {
        fontSize: 14,
        color: "#fff",
    },
});

class UpdateAvailable extends Component {
    state = {
        open: true,
    };
    

    handleClose = () => {
        this.setState({open: false});
    };

    refresh = () => {
        window.location.reload();
    };

    render() {
        const {classes, t} = this.props;
        return (
            <div className={classes.wrapper}>
                <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    id='updateNotification'
                    style={{display: `none`}}
                    open={this.state.open}
                    // onClose={this.handleClose}
                    TransitionComponent={TransitionUp}
                    classes={{
                        root: classes.snackbar,
                    }}
                    ContentProps={{
                        'aria-describedby': 'update-app',
                        classes: {
                            root: classes.snackbarContent,
                            message: classes.fullWidth,
                            action: classes.action,
                        },
                    }}
                    message={
                        <span className={classes.messageRoot}>
                            <span id="update-app" className={classes.content}>
                                O versiune noua disponibila
                            </span>
                            <Button color='primary' variant="contained" size="small" id={"updateNotificationBTN"}>
                                Refresh
                            </Button>

                        </span>
                    }
                    action={
                        <IconButton
                            onClick={this.handleClose}
                            aria-label="close" className={classes.btn}>
                            <Icon className={clsx("brand-icon-close", classes.close)}/>
                        </IconButton>
                    }
                />
            </div>
        );
    }
}


export default withStyles(styles)(UpdateAvailable);
