import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import _uniqueId from "lodash/uniqueId"
import {SwiperSlide} from 'swiper/react';
import Slider from "./Slider";
import clsx from "clsx";
import _get from "lodash/get";
import _map from "lodash/map";
import {sliderStyle} from "../../theme/appStyle";
import AccessoryCard from "../accessories/AccessoryCard";

const AccessoriesSlider = (props) => {
    const classes = useStyles();
    const paginationEl = _uniqueId("accessories-pagination-");
    return (
        <div className={classes.sliderRoot}>
            <Slider
                spaceBetween={8}
                slidesPerView={1}
                slidesPerGroup={1}
                centeredSlides={false}
                // navigation
                pagination={{
                    el: `.${paginationEl}`,
                    dynamicBullets: false,
                    clickable: true
                }}
                preloadImages={false}
                lazy={{
                    preloaderClass: "preloader-skeleton"
                }}
                breakpoints={{
                    420: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 16
                    },
                    1024: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        spaceBetween: 24
                    }
                }}
            >
                {_map(props.data, (item, index) => {
                    return <SwiperSlide key={index}>
                        <AccessoryCard
                            title={_get(item, "attributes.title", '')}
                            content={_get(item, "attributes.content", '')}
                            image={_get(item, "attributes.slide_image", [])}
                            to={_get(item, "attributes.url", '')}
                            callToAction={_get(item, "attributes.call_to_action_name", '')}
                        />
                    </SwiperSlide>
                })}
            </Slider>
            <div className={clsx(paginationEl, classes.paginationContainer)}>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    ...sliderStyle(theme),
}))

export default AccessoriesSlider
