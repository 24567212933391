import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
/*ui*/
import Fade from '@material-ui/core/Fade';

function ProgressFullScreen(props) {
    const classes = useStyles();
    const {loading} = props;
    /**
     * loading parameter is always true and component is useless because it's rendered conditionally
     * All fade effects are from each Fade component in each page
     */
    return (
        <Fade in={loading} mountOnEnter unmountOnExit>
            <section className={classes.baseRoot}>
            <div className={classes.root}>
                {/*<CircularProgress className={classes.progress}/>*/}
            </div>
            </section>
        </Fade>
    )
}

const useStyles = makeStyles((theme) => ({
    baseRoot: {
        background: "#fafafa",
        flex: 1,
    },
    root: {
        height: "calc(100vh - 64px)",
        width: "100%",
        background: "rgba(255,255,255,0.8)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "64px",
        left: "0px",
        [theme.breakpoints.down('sm')]: {
            top: "56px",
            height: "calc(100vh - 56px)",
        }
    }
}));

export default ProgressFullScreen;
