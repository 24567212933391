import React from 'react';
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import {PAGES_SLUGS} from "../../constants";

import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import Skeleton from "@material-ui/lab/Skeleton";
import {appStyle} from "../../theme/appStyle";
import ReactHtmlParser from "react-html-parser";

const AccessoryCard = (props) => {
    const classes = useStyles();
    const location = useLocation();

    const navigateTo = (() => {
        const propsTo = props.to;
        if (propsTo === '' || propsTo === '#') return `/${PAGES_SLUGS.ACCESSORIES}`

        return `/${PAGES_SLUGS.ACCESSORIES}?models=${props.to}`
    })();

    return <div>
        <ButtonBase
            focusRipple
            component={RouterLink}
            to={navigateTo}
            className={classes.imageWrapper}
        >
            <img data-src={props.image} className={clsx("swiper-lazy", classes.image)}/>
            <Skeleton variant={'rect'} width="100%" height={"100%"}
                      className={"preloader-skeleton"}/>
        </ButtonBase>
        <div className={classes.body}>
            <div>
                <Typography paragraph className={classes.title} align={"center"}>{props.title}</Typography>
            </div>
            <div>
                <Typography component={"div"} className={classes.text} align={"center"}>
                    {ReactHtmlParser(props.content)}
                </Typography>
            </div>
            <Box display={"flex"} justifyContent={"center"}>
                <Link className={classes.underlineLink} component={RouterLink}
                      to={navigateTo}>
                    {props.callToAction} <Icon
                    className={clsx("brand-icon-right-nav-arrow", classes.icon)}/>
                </Link>
            </Box>
        </div>

    </div>
}

const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    imageWrapper: {
        width: "100%",
        paddingTop: "100%",
        position: "relative",
        background: "#fff",
        borderRadius: "8px",
        overflow: "hidden",
    },
    image: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        transform: "scale(1.01)",
        transition: "transform 300ms",
        "&:hover": {
            transform: "scale(1.08)",
        }
    },

    body: {
        padding: theme.spacing(2),
    },

    title: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(22),
        lineHeight: theme.typography.pxToRem(32),
        letterSpacing: "0.32px",
        marginBottom: 0,
        ".contrast-section &": {
            color: "#fff",
        }
    },
    text: {
        color: "#707070",
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        marginBottom: theme.spacing(1)
    },
    icon: {
        fontSize: 10,
    },
}));

export default AccessoryCard;
