import React, {Component} from 'react';
import {connectRefinementList} from "react-instantsearch-dom";
import _take from "lodash/take";
import _isEmpty from "lodash/isEmpty";
// UI
import {withStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import filterStyles from "../../../theme/filterStyles";
import Fab from '@material-ui/core/Fab';
import AlgoliaClearRefinements from "./AlgoliaClearRefinements";
import extractFilterName from "../../../helpers/extractFilterName";
import {dealershipFacetName, dictionary} from "../../../constants/algolia";
import {Hidden} from "@material-ui/core";
import clsx from "clsx";

const customStyles = theme => ({
    ...filterStyles(theme),
    seeMoreContent: {
        flex: 0,
        order: 1,
        margin: `0 0 0 12px !important`
    },
    seeMoreButton: {
        justifyContent: `flex-start`,
        margin: `0 0 8px`
    },
    seeMoreArrow: {
        marginLeft: `2px`
    },
    noMargin: {
        margin: `0 !important`
    },
    seeMore: {
    }
});

class AlgoliaFiltersDesktop extends Component {
    state = {
        expanded: this.props.expanded,
        showLimit: this.props.limit || 99
    }

    changeLimit = () => {
        if(this.props.limit < this.state.showLimit) {
            this.setState({showLimit: this.props.limit})
        } else {
            this.setState({showLimit: 99})
        }
    }

    accordionChange = (event, expanded) => {
        this.setState({expanded: expanded})
    }

    render() {
        const {items, currentRefinement, refine, classes, expanded} = this.props;
        const parentAttribute = this.props.attribute
        const filterName = Boolean(dictionary[extractFilterName(parentAttribute)])
            ? dictionary[extractFilterName(parentAttribute)]
            : extractFilterName(parentAttribute);
        return (
            <div className={clsx(classes.root,{[classes.hidden]:(this.props.isDealerPage && extractFilterName(parentAttribute) === dealershipFacetName )})}>
                {/*Hide dealership on dealer page. Even if component is used for both acc and cars, it will affect only car listing*/}
                {/*Extra title for "Dealership" facet*/}
                {extractFilterName(parentAttribute) === dealershipFacetName &&
                <Hidden smDown><Typography variant={"body1"} className={classes.pickDealer}>Alege un dealer</Typography></Hidden>
                }
                <div className={classes.filterBox}>
                    <Accordion defaultExpanded={expanded} classes={{root: classes.expansionRoot}}
                               onChange={this.accordionChange}
                    >
                        <AccordionSummary
                            classes={{
                                root: classes.expansionButton,
                                content: classes.expansionButtonContent,
                                expandIcon: classes.icon
                            }}
                            expandIcon={<span className={`brand-icon-down-arrow`}/>}>
                            <Typography variant={"body1"} className={`${classes.categoryTitle} ${!_isEmpty(currentRefinement) && classes.selectedFilters}`}>{filterName}</Typography>
                        </AccordionSummary>
                        {/*<div className={classes.tags}>*/}
                        <AccordionDetails className={classes.details} classes={{root: classes.expansion}}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                    {_take(items, this.state.showLimit).map((item, index) => {
                                        return (
                                            <FormControlLabel
                                                key={index}
                                                className={classes.formLabel}
                                                classes={{label: classes.customLabel}}
                                                control={
                                                    <Checkbox
                                                        checkedIcon={<div className={classes.customCheckbox}> </div>}
                                                        icon={<div className={classes.emptyCustomCheckbox}> </div>}
                                                        color={'primary'}
                                                        className={classes.smallerPadding}
                                                        checked={item.isRefined}
                                                        onClick={event => {
                                                            event.preventDefault();
                                                            refine(item.value);
                                                        }}
                                                        value={item.label}/>
                                                }
                                                label={item.label}
                                            />
                                        )
                                    })}

                                    {(this.props.showMore && this.props.limit < this.props.items.length) &&
                                    <Accordion  classes={{root: classes.expansionRoot}}
                                                onChange={this.changeLimit}
                                    >
                                        <AccordionSummary
                                            classes={{
                                                root: clsx(classes.expansionButton, classes.seeMoreButton),
                                                content: classes.seeMoreContent,
                                                expandIcon: clsx(classes.icon, classes.seeMoreArrow)
                                            }}
                                            expandIcon={<span className={`brand-icon-down-arrow`}/>}>
                                            <Typography variant={"body1"} className={classes.seeMore} >...</Typography>
                                        </AccordionSummary>
                                    </Accordion>
                                    }
                                    <AlgoliaClearRefinements
                                        transformItems={items =>
                                            items.filter(({ attribute }) => attribute === parentAttribute)
                                        }
                                    />

                                </FormGroup>
                            </FormControl>
                        </AccordionDetails>

                    </Accordion>
                </div>
                {!this.state.expanded && <div>
                    {items.map((item, index) => {
                        if (item.isRefined)
                            return <Fab
                                variant={"extended"}
                                className={classes.tag}
                                classes={{
                                    root:classes.tabLabel
                                }}
                                key={index}
                                size="small"
                                color="primary"
                                onClick={event => {
                                    event.preventDefault();
                                    refine(item.value);
                                }}>
                                {item.label}<span className={'brand-icon-close'}> </span>
                            </Fab>
                    })}
                </div>
                }
            </div>
        );
    }
}

AlgoliaFiltersDesktop = connectRefinementList(AlgoliaFiltersDesktop);
export default withStyles(customStyles)(AlgoliaFiltersDesktop);
