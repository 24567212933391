import React, {Component} from 'react';
import {connectSortBy} from "react-instantsearch-dom";
/*UI*/
import {withStyles} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import filterStyles from "../../../theme/filterStyles";

const customStyles = theme => ({
    ...filterStyles(theme),
    sortContainer: {
        paddingLeft: theme.spacing(5),
    },
    sortType: {
        marginTop: theme.spacing(4)
    },
    topMarginDivider: {
        marginTop: theme.spacing(1)
    },
    label: {
        margin: 0,
    },
    labelText: {
        fontSize: `0.875rem`
    }
});

class AlgoliaSortByDesktop extends Component {
    state = {
        value: this.props.items.find(item => item.isRefined).value,
        priceSortArray: [],
        discountSortArray: [],
    };

    componentDidMount() {
        const {items} = this.props;
        items.map(item => {
            if (item.value.includes('price')) this.state.priceSortArray.push(item)
            if (item.value.includes('discount')) this.state.discountSortArray.push(item)
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let newValue = this.props.items.find(item => item.isRefined).value;
        if (prevState.value != newValue) {
            this.setState({value: newValue});
        }
    }

    handleRefineChange = (event) => {
        const selectedSort = event.target.value;
        this.setState({
            value: selectedSort
        }, () => this.props.refine(selectedSort));
    };

    renderFormControls = (item) => {
        const {classes} = this.props;
        return (
            <FormControlLabel
                className={classes.label}
                classes={{label: classes.labelText}}
                label={item.label}
                key={item.value}
                value={item.value}
                control={
                    <Radio
                        color={'primary'}
                        checkedIcon={<div className={`${classes.rounded} ${classes.customCheckbox}`}> </div>}
                        icon={<div className={`${classes.rounded} ${classes.emptyCustomCheckbox}`}> </div>}
                    />}
            />
        )
    }

    render() {
        const {defaultRefinement, classes} = this.props;

        return (
            <React.Fragment>
                <FormControl fullWidth component="fieldset" className={`${classes.sortContainer}`}>
                    <Typography variant={"body1"} className={`${classes.weight300} ${classes.mechanicsTitle}`}>Sorteaza lista</Typography>

                    <RadioGroup aria-label="sort" name="sort" value={this.state.value}
                                onChange={this.handleRefineChange}>
                        <FormControlLabel
                            className={classes.label}
                            classes={{label: classes.labelText}}
                            value={defaultRefinement}
                            label={'Predefinit'}
                            control={
                                <Radio color={'primary'}
                                       checkedIcon={<div className={`${classes.rounded} ${classes.customCheckbox}`}> </div>}
                                       icon={<div className={`${classes.rounded} ${classes.emptyCustomCheckbox}`}> </div>}
                                />}
                        />
                        <Typography className={classes.sortType}>
                            <strong>Pret</strong>
                        </Typography>
                        {this.state.priceSortArray.map(item => {
                            return this.renderFormControls(item)
                        })}
                        <Divider className={classes.topMarginDivider}/>
                        <Typography className={classes.sortType}>
                            <strong>Discount %</strong>
                        </Typography>
                        {this.state.discountSortArray.map(item => {
                            return this.renderFormControls(item)
                        })}
                        <Divider className={classes.topMarginDivider}/>
                    </RadioGroup>
                </FormControl>
            </React.Fragment>
        );
    }
}

AlgoliaSortByDesktop = withStyles(customStyles)(AlgoliaSortByDesktop);
AlgoliaSortByDesktop = connectSortBy(AlgoliaSortByDesktop);
export default AlgoliaSortByDesktop;
