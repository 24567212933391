import React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import ReactHtmlParser from "react-html-parser";
import {PAGES_SLUGS} from "../constants";
import LazyImage from "./LazyImage";

const OfferCard = (props) => {
    const classes = useStyles();

    return <Grid item sm={4} xs={12} >

        <ButtonBase
            focusRipple
            component={RouterLink}
            to={`/${PAGES_SLUGS.PRODUCTS}?car_body=${props.to}`}
            className={classes.btn}
        >
            <LazyImage src={props.image} ratio={75} />

        </ButtonBase>
        <div className={classes.body}>
            <div>
                <Typography component={"div"} className={classes.title}
                            align={"center"}>{ReactHtmlParser(props.title)}</Typography>
            </div>
            <div className={classes.description}>
                <Typography component={"div"} className={classes.text}
                            align={"center"}>{ReactHtmlParser(props.content)}</Typography>
            </div>
            <Box display={"flex"} justifyContent={"center"}>
                <Link className={classes.link} component={RouterLink}
                      to={`/${PAGES_SLUGS.PRODUCTS}?car_body=${props.to}`}>Vezi toate ofertele<Icon
                    className={clsx("brand-icon-right-nav-arrow", classes.icon)}/>
                </Link>

            </Box>
        </div>

    </Grid>
}

const useStyles = makeStyles((theme) => ({
    btn:{
      display:"block"
    },
    imageWrapper: {
        width: "100%",
        paddingTop: "75%",
        position: "relative",

    },
    image: {
        position: "absolute",
        // objectFit: "cover",
        // objectPosition: '50% 50%',
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
    },

    body: {
        padding: theme.spacing(2),
        paddingBottom: 0,
    },

    title: {
        fontFamily: "Volvo Broad, Arial Black",
        fontSize: theme.typography.pxToRem(36),
        letterSpacing: "0px",
        lineHeight: "1",
        paddingBottom: theme.spacing(2),
    },
    description: {
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            margin: "0 auto",
            width: "80%"
        },
    },

    link: {
        fontSize: "14px",
        lineHeight: "1",
        padding: `${theme.spacing(1) / 2}px`,
        position: "relative",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "none",
        },
        "&:before": {
            content: `''`,
            position: "absolute",
            width: "0",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            background: theme.palette.primary.main,
            transition: theme.transitions.create(['width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        "&:hover:before": {
            content: `''`,
            position: "absolute",
            width: "100%",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            background: theme.palette.primary.main
        }
    },
    icon: {
        fontSize: 10,
    },
}));
export default OfferCard;