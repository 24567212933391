import React, {useState} from 'react';
import _map from "lodash/map";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Icon from "@material-ui/core/Icon";
import clsx from "clsx";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import {Collapse} from "react-collapse";
import itemDescriptionStyles from "../theme/itemDescriptionStyles";


const TehnicalDataCard = ({name, data}) => {
    const classes = useStyles();

    const [isOpened, setIsOpened] = useState(false);
    const handleToggle = (event) => {
        event.preventDefault();
        setIsOpened(!isOpened);
    }

    const renderToogleText = () => {
        if (isOpened) {
            return <Link href={"#"} onClick={handleToggle} className={classes.link}>
                <span className={classes.cta}>View less</span>
                <Icon className={clsx("brand-icon-up-arrow", classes.icon)}/>
            </Link>
        } else {
            return <Link href={"#"} onClick={handleToggle} className={classes.link}>
                <span className={classes.cta}>View more</span>
                <Icon className={clsx("brand-icon-down-arrow", classes.icon)}/>
            </Link>
        }
    }

    const renderSampleData = () => {
        if (!_isEmpty(data)) {
            return _map(_slice([...data], 0, 8), (item, index) => {
                return  <Grid key={index} item md={3} sm={4} xs={6} className={classes.sectionBox}>
                    <Typography component={"p"} className={classes.sectionName}>{item.name}</Typography>
                    <Typography component={"p"} className={classes.sectionText}>{item.value}</Typography>
                </Grid>
            })
        }
    }

    const renderColapsedeData = () => {
        if (!_isEmpty(data)) {
            if (data.length > 8) {
                return <>
                    <Collapse isOpened={isOpened} theme={{collapse: classes.collapse, content: classes.content}}>
                        {_map(_slice([...data], 8, data.length), (item, index) => {
                            return <Grid key={index} item md={3} sm={4} xs={6} className={classes.sectionBox}>
                                <Typography component={"p"} className={classes.sectionName}>{item.name}</Typography>
                                <Typography component={"p"} className={classes.sectionText}>{item.value}</Typography>
                            </Grid>
                        })}
                    </Collapse>
                    <Grid item xs={12} className={classes.sectionBox}>
                        {renderToogleText()}
                    </Grid>
                </>
            }

        }
    }

    return <div className={classes.descriptionParent}>
        <Grid container>
            <Grid item xs={12}>
                <Typography component={"p"} className={classes.sectionTitle}>{name}</Typography>
            </Grid>
        </Grid>
        <Grid container item xs={12} sm={10}>
            {/*{renderSampleData()}*/}
            {/*{renderColapsedeData()}*/}
            {_map(data, (item, index) => {
                return <Grid key={index} item md={3} sm={4} xs={6} className={classes.sectionBox}>
                    <Typography component={"p"} className={classes.sectionName}>{item.name}</Typography>
                    <Typography component={"p"} className={classes.sectionText}>{item.value}</Typography>
                </Grid>
            })}
        </Grid>
    </div>
}
const useStyles = makeStyles((theme) => ({
    ...itemDescriptionStyles(theme),
    sectionBox: {
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    link: {
        display: "inline-flex",
        alignItems: "center",
        position: 'relative',
        textDecoration: "none",
        "&:hover": {
            textDecoration: "none",
        },
        "&:before": {
            content: `''`,
            position: "absolute",
            width: "0",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            background: theme.palette.primary.main,
            transition: theme.transitions.create(['width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        "&:hover:before": {
            content: `''`,
            position: "absolute",
            width: "100%",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            background: theme.palette.primary.main
        }
    },
    content: {
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
    },
    collapse: {
        width: "100%",
        transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    cta: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(24),
    },
    icon: {
        fontSize: "14px",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
        color: theme.palette.primary.main,
    },

}));
export default TehnicalDataCard;
