import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connectAutoComplete, Index} from 'react-instantsearch-dom';
import clsx from "clsx";
// UI
import {withStyles} from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import {appStyle} from "../../../theme/appStyle";
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Icon from "@material-ui/core/Icon";
import _get from "lodash/get";
import getSuppotedFileType from "../../../helpers/getSuppotedFileType";
import {accessoriesIndexName, indexName} from "../../../constants/algolia";
import {PAGES_SLUGS} from "../../../constants";
import searchCarPrice from "./searchCarPrice";
import searchAccPrice from "./searchAccPrice";

const customStyles = theme => ({
    ...appStyle(theme),
    searchResults: {
        zIndex: 999,
        position: `absolute`,
        left: 0,
        background: `#fff`,
        width: `100%`,
        boxShadow: `0 4px 6px -1px rgba(0,0,0,0.2)`,
        '&:empty': {
            display: `none`
        }
    },
    carImage: {
        maxHeight: 60,
        marginRight: theme.spacing(2)
    },
    oldPrice: {
        textDecoration: `line-through`,
        marginLeft: theme.spacing(2),
        fontSize: `0.875rem`,
        lineHeight: 1.75
    },
    inputLabelRoot: {
        fontSize: 16,
        fontWeight: 300,
        color: "#fff !important",
        transform: "translate(0, 32px) scale(1)",
    },
    InputLabelShrink: {
        transform: "translate(0, 1.5px) scale(0.75)",
    },
    inputRoot: {
        color: "#fff",
        height: 48.
    },
    inputUnderline: {
        borderColor: "#fff",
        "&:before, &:hover:before": {
            borderColor: "#fff !important",
        },
        "&:after": {
            borderColor: "#fff",
        }
    },
    icon: {
        fontSize: 16,
        color: "#fff"
    },
    arrowIcon: {
        fontSize: 10
    },
    link: {
        fontSize: 14,
        textDecoration: "none"
    }
});

class HomepageAlgoliaAutoComplete extends Component {
    state = {
        showList: true
    };

    goToProducts = (event) => {
        event.preventDefault();
        if (this.props.hits.length && this.props.currentRefinement !== '') {
            this.props.refine('');
            this.props.onClose();
            // history.push(`/products?query=${this.props.currentRefinement}`);
        }
    };

    renderNoResults = () => {
        const {hits} = this.props;
        let noOfHits = 0;
        /**
         * Because we use one index when declaring InstantSearch, on blur there will be another request to Algolia which will populate the hits object
         * Instead of have two objects with indexes, we'll have only the hits of the declared index in InstantSearch
         * We need to check if 'hits' key exists and use the length otherwise
         */
        if (Boolean (_get(hits[0], "hits", false))) {
            hits.map(hit => {
                noOfHits += hit.hits.length;
            })
        } else {
            noOfHits = Object.keys(hits).length;
        }

        if (noOfHits === 0) {
            return (
                <ListItem>
                    Nu am găsit rezultate
                </ListItem>
            )
        }
    }

    renderCarsList = (indexName) => {
        const {hits, classes} = this.props;
        const carObj = hits.find(obj => {
            return obj.index === indexName;
        });
        const carHits = _get(carObj,"hits",[]);

        if (!carHits.length) {
            return
        }

        return (
            <>
                <ListItem>
                    <ListItemText primary={"MASINI"}/>
                    <ListItemSecondaryAction>
                        <Link className={clsx(classes.link, classes.colorPrimary)} underline="none"
                              to={`/${PAGES_SLUGS.PRODUCTS}`}>
                            Toate masinile
                            <Icon className={clsx("brand-icon-right-nav-arrow", classes.arrowIcon)}/>
                        </Link>
                    </ListItemSecondaryAction>
                </ListItem>
                {carHits.map(hit => {
                    return (
                        <ListItem key={hit.rom_ref} button component={Link} to={`/details/${hit.slug}`}
                                  onClick={this.closeDesktopSearch}>
                            <ListItemAvatar>
                                <img className={classes.carImage}
                                     src={getSuppotedFileType(_get(hit, "media[0].thumb", []))} alt={hit.name}/>
                            </ListItemAvatar>
                            <ListItemText primary={_get(hit, "short_name", "")} secondary={searchCarPrice(hit, classes)}/>
                        </ListItem>
                    )
                })}
            </>
        )
    };

    renderAccessoryList = (indexName) => {
        const {hits, classes} = this.props;
        const accObj = hits.find(obj => {
            return obj.index === indexName;
        });
        const accHits = _get(accObj,"hits",[]);

        if (!accHits.length) {
            return
        }

        return (
            <>
                <ListItem>
                    <ListItemText primary={"ACCESORII"}/>
                    <ListItemSecondaryAction>
                        <Link className={clsx(classes.link, classes.colorPrimary)} underline="none"
                              to={`/${PAGES_SLUGS.ACCESSORIES}`}>
                            Toate accesoriile
                            <Icon className={clsx("brand-icon-right-nav-arrow", classes.arrowIcon)}/>
                        </Link>
                    </ListItemSecondaryAction>
                </ListItem>

                {accHits.map(hit => {
                    return (
                        <ListItem key={hit.slug} button component={Link} to={`/details/${hit.slug}`}
                                  onClick={this.closeDesktopSearch}>
                            <ListItemAvatar>
                                <img className={classes.carImage} src={getSuppotedFileType(_get(hit, "media[0].thumb", []))} alt={_get(hit,"base_name","")}/>
                            </ListItemAvatar>
                            <ListItemText primary={_get(hit,"base_name","")} secondary={searchAccPrice(hit, classes)}/>
                        </ListItem>
                    )
                })}
            </>
        )
    };

    closeDesktopSearch = () => {
        this.setState({showList: false})
    };

    openDesktopSearch = () => {
        this.setState({showList: true})
    };

    renderAutocompleteDesktop = () => {
        const {currentRefinement, refine, classes} = this.props;
        return (
            <ClickAwayListener onClickAway={this.closeDesktopSearch}>
                <div>
                    <form onSubmit={this.goToProducts} noValidate action="" role="search">
                        {/*<TextField id="outlined-basic" label="Outlined" variant="outlined" />*/}

                        <TextField placeholder="Cauta …"
                                   fullWidth
                                   className={clsx(classes.margin, classes.textField)}
                                   value={currentRefinement}
                                   onFocus={this.openDesktopSearch}
                                   onChange={event => refine(event.currentTarget.value)}
                                   InputLabelProps={{
                                       classes: {
                                           root: classes.inputLabelRoot,
                                           shrink:classes.InputLabelShrink,
                                       },
                                   }}
                                   InputProps={{
                                       classes: {
                                           root: classes.inputRoot,
                                           underline: classes.inputUnderline,
                                       },
                                       endAdornment: <InputAdornment position="end"> <Icon
                                           className={clsx("brand-icon-search", classes.icon)}/></InputAdornment>
                                   }}
                        />

                    </form>
                    {(this.state.showList && currentRefinement !== '') &&
                        <List dense className={`${classes.searchResults}`}>
                            {this.renderNoResults()}
                            <Index indexName={indexName}>
                                {this.renderCarsList(indexName)}
                            </Index>
                            <Index indexName={accessoriesIndexName}>
                                {this.renderAccessoryList(accessoriesIndexName)}
                            </Index>
                        </List>
                    }
                </div>
            </ClickAwayListener>
        )
    };

    render() {
        return (
            <React.Fragment>
                {this.renderAutocompleteDesktop()}
            </React.Fragment>
        )
    }
}

HomepageAlgoliaAutoComplete = connectAutoComplete(HomepageAlgoliaAutoComplete);
export default withStyles(customStyles)(HomepageAlgoliaAutoComplete);
