import React, {useState} from 'react';
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _map from "lodash/map";
import {Collapse} from 'react-collapse';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import itemDescriptionStyles from "../theme/itemDescriptionStyles";

const EquipmentDataCard = ({name, data, accessoryPage}) => {
    const classes = useStyles();
    const [isOpened, setIsOpened] = useState(false);
    const handleToggle = (event) => {
        event.preventDefault();
        setIsOpened(!isOpened);
    }

    const renderToogleText = () => {
        if (isOpened) {
            return <Link href={"#"} onClick={handleToggle} className={classes.link}>
                <span className={classes.cta}>View less</span>
                <Icon className={clsx("brand-icon-up-arrow", classes.icon)}/>
            </Link>
        } else {
            return <Link href={"#"} onClick={handleToggle} className={classes.link}>
                <span className={classes.cta}>View more</span>
                <Icon className={clsx("brand-icon-down-arrow", classes.icon)}/>
            </Link>
        }
    }

    const renderSampleData = () => {
        if (!_isEmpty(data)) {
            return _map(_slice([...data], 0, 10), (item, index) => {
                // Full row description on accessory page
                return <Grid key={index} item sm={accessoryPage ? 12 :6} xs={12} className={classes.sectionBox}>
                    <Typography component={"p"} className={classes.sectionName}>{item.name}</Typography>
                </Grid>
            })
        }
    }

    const renderColapsedeData = () => {
        if (!_isEmpty(data)) {
            if (data.length > 10) {
                return <>
                    <Collapse isOpened={isOpened} theme={{collapse: classes.collapse, content: classes.content}}>
                        {_map(_slice([...data], 10, data.length), (item, index) => {
                            return <Grid key={index} item sm={6} xs={12} className={classes.sectionBox}>
                                <Typography component={"p"} className={classes.sectionName}>{item.name}</Typography>
                            </Grid>
                        })}
                    </Collapse>
                    <Grid item xs={12} className={classes.sectionBox}>
                        {renderToogleText()}
                    </Grid>
                </>
            }

        }


    }

    return <div className={classes.descriptionParent}>
        <Grid container>
            <Grid item xs={12}>
                {/*On accessory page we use any tag with the data-scrollspy as scroll anchors*/}
                {/*To avoid any possible issues, we render titles w/o this attribute on car details page*/}
                {accessoryPage
                    ? <Typography data-scrollspy component={"p"} className={classes.sectionTitle}>{name}</Typography>
                    : <Typography component={"p"} className={classes.sectionTitle}>{name}</Typography>}
            </Grid>
        </Grid>
        <Grid container item sm={10} xs={12}>
            {renderSampleData()}
            {renderColapsedeData()}
        </Grid>
    </div>
}
const useStyles = makeStyles((theme) => ({
    ...itemDescriptionStyles(theme),
    sectionBox: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
    link: {
        display: "inline-flex",
        alignItems: "center",
        position: 'relative',
        textDecoration: "none",
        "&:hover": {
            textDecoration: "none",
        },
        "&:before": {
            content: `''`,
            position: "absolute",
            width: "0",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            background: theme.palette.primary.main,
            transition: theme.transitions.create(['width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        "&:hover:before": {
            content: `''`,
            position: "absolute",
            width: "100%",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            background: theme.palette.primary.main
        }
    },
    content: {
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
    },
    collapse: {
        width: "100%",
        transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    cta: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(24),
    },
    icon: {
        fontSize: "14px",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
        color: theme.palette.primary.main,
    },

}));
export default EquipmentDataCard;
