import MenuItem from "@material-ui/core/MenuItem";
import _isEmpty from "lodash/isEmpty";
import React from "react";

/**
 * Return Select items with models
 * @param accessoryModels object model: [years]
 * @returns {ReactNode}
 */
export const getModels = (accessoryModels) => {
    return (
        Object.keys(accessoryModels).map(item => {
            return <MenuItem
                value={item}
                key={item}
            >
                {item}
            </MenuItem>
        })
    );
}

/**
 * Based on selected model, it will return the available years
 * @param {Object} accessoryModels object model: [years]
 * @param {String} model
 * @returns {ReactNode}
 */
export const getYears = (accessoryModels, model) => {
    if (_isEmpty(model)) {
        return (
            <MenuItem
                value={""}
                key={""}
                disabled={true}
            >
                Alege un model
            </MenuItem>
        )
    } else {
        return (
            accessoryModels[model].map(item => {
                return <MenuItem
                    value={item}
                    key={item}
                >
                    {item}
                </MenuItem>
            }));
    }
}
