import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import _uniqueId from "lodash/uniqueId"
import _map from "lodash/map";
import _get from "lodash/get";
import toLocale from "../../helpers/toLocale";
import ProductCardVariantTwo from "../ProductCardVariantTwo";
import {SwiperSlide} from 'swiper/react';
import Slider from "./Slider";
/*demo image*/
import clsx from "clsx";
import getSuppotedFileType from "../../helpers/getSuppotedFileType";
import {sliderStyle} from "../../theme/appStyle";

const RelatedProductsSlider = (props) => {
    const classes = useStyles();
    const paginationEl = _uniqueId("related-pagination-");

    return (
        <div className={classes.sliderRoot}>
            <Slider
                spaceBetween={16}
                slidesPerView={1}
                slidesPerGroup={1}
                // navigation
                pagination={{
                    el: `.${paginationEl}`,
                    dynamicBullets: false,
                    clickable: true
                }}
                // Disable preloading of all images
                preloadImages={false}
                // Enable lazy loading
                lazy={{
                    // loadPrevNext: true,
                    preloaderClass: "preloader-skeleton"
                }}
                breakpoints={{
                    // when window width is >= 768
                    420: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 16
                    },
                    640: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        spaceBetween: 16,
                        centeredSlides:false
                    },
                    // when window width is >= 1200:
                    1024: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 24
                    },
                    // when window width is >= 1600
                    1600: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 24
                    }
                }}
            >
                {_map(props.data, (item, index) => {
                    return <SwiperSlide key={item.slug}>
                        <ProductCardVariantTwo
                            image={getSuppotedFileType(_get(item, "photos[0].thumb", []))}
                            title={item.short_name}
                            price={`${toLocale(_get(item, "client_invoice_price", ""))}€`}
                            oldPrice={`${toLocale(_get(item, "total_car_price", ""))}€`}
                            discount={`${toLocale(_get(item, "discount_percentage", ""))}`}
                            slug={item.slug}
                            sku={_get(item, "rom_ref", "")}
                        />
                    </SwiperSlide>
                })}
            </Slider>
            <div className={clsx(paginationEl, classes.paginationContainer)}>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    ...sliderStyle(theme)
}))

export default RelatedProductsSlider
