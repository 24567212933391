import * as ActionTypes from '../constants/actionTypes';

export const initialState = {
    request: true,
    error: '',
    data: {},
};

const pageBySlug = (state = initialState.data, action) => {
    const {slug,data} = action.payload;
    return {
        ...state,
        [slug]: {
           ...data
        }
    }
};

export default function products(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.PAGES_REQUEST:
            return {
                ...state,
                request: true,
                error: '',
            };

        case ActionTypes.PAGES_SUCCESS:
            return {
                ...state,
                request: false,
                error: '',
                data: pageBySlug(state.data, action)
            };

        case ActionTypes.PAGES_ERROR:
            return {
                ...state,
                request: false,
                error: action.payload
            };

        default :
            return state;
    }
}
