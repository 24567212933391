import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
/*ui*/
import {withStyles} from '@material-ui/core/styles';
/*components*/
import getURLParameters from "../../helpers/getURLParameters";
import _get from "lodash/get";
import qs from 'qs'
import SectionBox from "../../components/SectionBox";
import AccessoryListing from "./Listing";
import {fetchPage} from "../../actions/pages";
import {PAGES_SLUGS} from "../../constants";
import _isEmpty from "lodash/isEmpty";
import Seo from "../../components/seo/Seo";

const styles = theme => ({
    productCard: {
        padding: 12,
        [theme.breakpoints.down('xs')]: {
            padding: 8
        }
    },
});

class Accessories extends Component {
    state = {
        queryString: ''
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        const currentQueryString = _get(getURLParameters(), 'query', '');
        if (currentQueryString) {
            this.updateState(currentQueryString);
        }

        if (_isEmpty(this.props.pageData)) {
            this.props.fetchPage(PAGES_SLUGS.ACCESSORIES)
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentQueryString = _get(getURLParameters(), 'query', '');
        if (prevState.queryString !== currentQueryString) {
            this.updateState(currentQueryString);
        }
    }

    updateState = (query) => {
        this.setState({
            queryString: query
        })
    };

    render() {
        return (
            <React.Fragment>
                {!_isEmpty(_get(this.props.pageData, 'data.meta_data', {})) &&
                <Seo data={this.props.pageData.data.meta_data}/>}
                <SectionBox>
                    <AccessoryListing
                        defaultQuery={this.state.queryString}
                    />
                </SectionBox>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        pageData: _get(state.pages.data, PAGES_SLUGS.ACCESSORIES, {}),
        loading: state.pages.request,
        error: state.pages.error,
        location: state.router.location
    }
}

Accessories = withRouter(Accessories);
Accessories = withStyles(styles)(Accessories);
Accessories = connect(mapStateToProps, {fetchPage})(Accessories);
export default Accessories;
