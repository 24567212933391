import 'react-app-polyfill/ie11';
import './css/index.css';
import React from 'react';
import {Provider} from 'react-redux'
import ReactDOM from 'react-dom';
import {ConnectedRouter} from 'connected-react-router'
import defaultTheme from './js/theme';
import Routes from "./js/routes"
import store, {history} from "./js/store"

import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import {fetchModels} from "./js/actions/models";
import {addToHomeScreen} from "./js/actions/pwa";

import * as serviceWorker from './serviceWorker';
import initSentry from "./js/helpers/initSentry";
// import { initSnowplowScript } from './js/helpers/snowplow';

initSentry();

/*Init SnowPlow Script*/
// initSnowplowScript();

setTimeout(() => {
    store.dispatch(fetchModels());
}, 2000)

/*PWA EVENTS*/
window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    window.deferredPrompt = e;
    store.dispatch(addToHomeScreen(true))
});

window.addEventListener('appinstalled', (evt) => {
    store.dispatch(addToHomeScreen(false))
});

ReactDOM.render(
    // <React.StrictMode>
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ThemeProvider theme={defaultTheme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline/>
                <Routes/>
            </ThemeProvider>
        </ConnectedRouter>
    </Provider>
    /*</React.StrictMode>*/,
    document.getElementById('root')
// =======
//     /**
//      * Everything under strict mode runs two times!
//      */
//     // <React.StrictMode>
//     <Provider store={store}>
//         <ConnectedRouter onUpdate={() => window.scrollTo(0, 0)} history={history}>
//             <ThemeProvider theme={defaultTheme}>
//                 {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
//                 <CssBaseline/>
//                 <Routes/>
//             </ThemeProvider>
//         </ConnectedRouter>
//     </Provider>
//     // </React.StrictMode>
//     ,
//     target
// >>>>>>> 021056269dc1122023ec17289161ed30aa27a138
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
