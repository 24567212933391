import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import _get from "lodash/get";
/*ui*/
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";

import {getWishlistData, WL} from "../../helpers/localStorage";
/*component*/
import {appStyle} from "../../theme/appStyle";
import toLocale from "../../helpers/toLocale";
import LazyImage from "../LazyImage";
import getSuppotedFileType from "../../helpers/getSuppotedFileType";
import {PAGES_SLUGS} from "../../constants";
import listingCardGeneralStyles from "../../theme/listingCardGeneralStyles";

const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    ...listingCardGeneralStyles(theme),
    description: {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(16),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: `-webkit-box`,
        lineClamp: 3,
        boxOrient: `vertical`,
        overflow: `hidden`
    }
}));

function DetailedAccCard(props) {
    const classes = useStyles();
    const location = useLocation();
    const accData = props.data;
    const isDetailsPage = props.fromDetailsPage || false;

    const renderAccPrice = () => {
        if (!accData.base_price) return;

        if (!accData.final_price || accData.final_price === accData.base_price) {
            return `${toLocale(accData.final_price)}€ + TVA`
        } else {
            return (
                <React.Fragment>
                    <div>
                        <div>{toLocale(accData.final_price)}€ + TVA</div>
                        <Typography align={"left"} component={"div"} variant="caption"
                                    className={classes.oldPrice}><span
                            className={classes.lineThrough}>{toLocale(accData.base_price)}€ </span>
                            <span
                                className={classes.oldPriceDiscount}> + TVA (-{`${toLocale(_get(accData, "discount_percentage", ""))}`}%)</span>
                        </Typography>
                    </div>
                </React.Fragment>
            )
        }
    }

    return (
        <Card elevation={0} className={classes.carCard}>
            <Link to={{pathname: `/${PAGES_SLUGS.ACCESSORY_DETAIL}/${accData.slug}`, state: {from: location.pathname}}} className={classes.linkDecoration}>
                <div className={`${classes.imageContainer}`}>
                    <Box position='relative'>
                        <LazyImage
                            src={
                                getSuppotedFileType(
                                    isDetailsPage
                                        ? _get(accData, "photos[0].thumb", [])
                                        : _get(accData, "media[0].thumb", [])
                                )}
                            ratio={56}
                            isAnimated={true} alt={accData.name}/>
                        {getWishlistData(WL.ACCESSORY).includes(accData.slug) &&
                        <div className={classes.wishlistHeart}>
                            <Icon className={`${classes.wlIcon} brand-icon-heart-fill`}/>
                        </div>}
                    </Box>

                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} className={classes.chipRow}>
                        <Typography component={"div"} className={classes.sku}>
                            {_get(accData, "external_cab_variant_id", false) ? "# " : ""}
                            {_get(accData, "external_cab_variant_id", "")}</Typography>
                        <span>
                            {_get(accData, "category[0]", false) &&
                            <Typography className={classes.sku}>{accData.category[0]}</Typography>}

                            {_get(accData, "category[1]", false) &&
                            <Typography className={classes.sku}>/{accData.category[1]}</Typography>}
                        </span>
                    </Box>
                </div>
                <Box display={"flex"} flexDirection={"column"} flexGrow={1} justifyContent={"space-between"} className={classes.detailsContainer}>
                    <span>
                        <Typography className={`${classes.carName} ${classes.weight500}`}>
                            {_get(accData, "base_name", "")}
                        </Typography>
                            <Typography className={classes.description}>
                                {_get(accData, "short_description", "")}
                            </Typography>
                    </span>

                    <Box>
                        <Divider/>
                        <Box display={"flex"}
                             alignItems={"center"}
                             justifyContent={"space-between"}
                             pt={1}
                        >
                            <Typography variant={'h5'} color={"primary"} className={classes.weight300}>
                                {renderAccPrice()}
                            </Typography>
                            <Typography variant={'caption'} color={"primary"} className={classes.weight300}>
                                <span className={"brand-icon-right-nav-arrow"}> </span>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Link>
        </Card>
    );
}

export default DetailedAccCard;
