import * as ActionTypes from './constants';

export const initialState = {
    request: false,
    error: {},
    data: {},
};

const accessoryById = (state, action) => {
    const {slug} = action.payload;
    return {
        ...state,
        [slug]: {
            ...action.payload
        }
    }
};

const deleteAccessoryErrorById = (state, action) => {
    const {slug} = action.payload;
    if (state.hasOwnProperty(slug)) {
        // console.log('deleting', slug, 'error body', state[slug]);
        delete state[slug];
        return {
            ...state,
        }
    }
    return state
};

export default function accessoryDetails(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ACCESSORY_REQUEST:
            return {
                ...state,
                request: true
            };

        case ActionTypes.ACCESSORY_SUCCESS:
            return {
                ...state,
                request: false,
                error: deleteAccessoryErrorById(state.error, action),
                data: accessoryById(state.data, action)
            };

        case ActionTypes.ACCESSORY_ERROR:
            return {
                ...state,
                request: false,
                error: accessoryById(state.error, action)
            };

        default :
            return state;
    }
}
