export const setSnowplowUserId = (email) => {
    if (window.snowplow && email && email !== '') {
        window.snowplow('setUserId', email);
    }
};

export const initSnowplowScript = () => {
    if (window.snowplow){
        if (!window.snowplowInitialized) {
            window.snowplow('newTracker', 'sp1', `${process.env.REACT_APP_SNOWPLOW_COLLECTOR_URL}`, {
                appId: `${process.env.REACT_APP_SNOWPLOW_APP_ID}`
            });
            window.snowplowInitialized = true;
        }
    }
};