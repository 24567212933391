import React, {Component} from 'react';
import {connect, useSelector} from 'react-redux'
import {withRouter} from 'react-router'
/*ui*/
import {withStyles} from '@material-ui/core/styles';
/*components*/
import getURLParameters from "../../helpers/getURLParameters";
import _get from "lodash/get";
import qs from 'qs'
import SectionBox from "../../components/SectionBox";
import Footer from "../../components/Footer";
import CarListing from "./Listing";
import Header from "../../components/Header";
import Box from "@material-ui/core/Box";
import {fetchPage} from "../../actions/pages";
import {setCurrentActiveDealer} from "../../actions/dealer";
import {PAGES_SLUGS} from "../../constants";
import {dealershipFacetName} from "../../constants/algolia";
import _isEmpty from "lodash/isEmpty";
import Seo from "../../components/seo/Seo";


const styles = theme => ({
    // ...defaultStyles(theme),
    productCard: {
        padding: 12,
        [theme.breakpoints.down('xs')]: {
            padding: 8
        }
    },
});

class Cars extends Component {
    state = {
        queryString: ''
    };

    componentDidMount() {
        this.checkIfDealerInstance();
        window.scrollTo(0, 0);
        const currentQueryString = _get(getURLParameters(), 'query', '');
        if (currentQueryString) {
            this.updateState(currentQueryString);
        }

        if (_isEmpty(this.props.pageData)) {
            this.props.fetchPage(PAGES_SLUGS.PRODUCTS)
        }

    }

    checkIfDealerInstance = () => {
        if (this.props.location.pathname === `/${PAGES_SLUGS.DEALER}`) {
            const qString= _get(this.props.location, "search", "").replace("?", "");
            const qStringColection = qs.parse(qString, {
                charset: 'utf-8',
                charsetSentinel: true
            })
            const dealer = _get(qStringColection,dealershipFacetName,"") ;
            if(!!dealer){
                this.props.setCurrentActiveDealer(dealer);
            }

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentQueryString = _get(getURLParameters(), 'query', '');
        if (prevState.queryString !== currentQueryString) {
            this.updateState(currentQueryString);
        }
    }

    updateState = (query) => {
        this.setState({
            queryString: query
        })
    };

    render() {
        return (
            <React.Fragment>
                {!_isEmpty(_get(this.props.pageData, 'data.meta_data', {})) &&
                <Seo data={this.props.pageData.data.meta_data}/>}
                <SectionBox>
                    <CarListing
                        isDealerPage={!!this.props.isDealerPage}
                        defaultQuery={this.state.queryString}
                    />
                </SectionBox>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        pageData: _get(state.pages.data, PAGES_SLUGS.PRODUCTS, {}),
        loading: state.pages.request,
        error: state.pages.error,
        location: state.router.location,
        isDealerPage: state.dealer.data,
    }
}

Cars = withRouter(Cars);
Cars = withStyles(styles)(Cars);
Cars = connect(mapStateToProps, {fetchPage, setCurrentActiveDealer})(Cars);
export default Cars;
