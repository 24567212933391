import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
/*ui*/
import SentimentDissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {Link as RouterLink} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";


const styles = theme => ({
    text: {
        textAlign: "center",
        opacity: "0.4",
    },
    content: {
        textAlign: "center",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: 'translate(-50%,-50%)',
    },
    icon: {
        fontSize: "80px",
        opacity: '0.8',
    }
});

class FallbackComponent extends Component {
    state = {
        counter: 5
    };

    render() {
        const {classes} = this.props;
        return (
            <Dialog
                fullScreen
                disableBackdropClick
                disableEscapeKeyDown
                open={true}
                onClose={() => {
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogContent>
                    <Grid container alignContent='stretch' className={classes.root}>
                        <Grid container item xs={12} alignItems='center' justify='center'>
                            <div className={classes.content}>
                                <div className={classes.text}>
                                    <SentimentDissatisfied className={classes.icon}/>

                                    <Typography gutterBottom variant="h5" component="h2" align="center">
                                        A aparut o eroare
                                    </Typography>
                                </div>

                                <Box display={"flex"} justifyContent={"center"} pt={3}>
                                    <Button variant="contained" color="primary" component={RouterLink} to={"/"}>
                                        Home
                                    </Button>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }
}

export default withStyles(styles)(FallbackComponent);
