import React, {useState} from 'react'
import {makeStyles, withStyles} from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";


const ExpansionPanel = withStyles((theme) => ({
    root: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        paddingBottom: theme.spacing(0),
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}))(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles((theme) => ({
    root: {
        backgroundColor: 'transparent',
        display: "inline-flex",
        boxShadow: 'none',
        border: 'none',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
        },
    },
    content: {
        backgroundColor: 'transparent',
        alignItems: "center",
        position: "relative",
        paddingTop: theme.spacing(1),
        '&$expanded': {
            margin: '12px 0',
        },
        "&:before": {
            content: `""`,
            width: 60,
            height: 1,
            backgroundColor: "#161618",
            position: "absolute",
            left: 0,
            top: 0,
        }
    },
    expanded: {},
}))(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
        },
    },
}))(MuiExpansionPanelDetails);

export default function ExpandableSections(props) {
    const classes = useStyles();
    const [isOpened, setIsOpened] = useState(true);
    const handleToggle = () => {
        setIsOpened(!isOpened);
    }
    const renderIcon = () => {
        return (isOpened ? <Icon className={clsx("brand-icon-up-arrow", classes.icon)}/> :
            <Icon className={clsx("brand-icon-down-arrow", classes.icon)}/>)
    }

    return (

        <ExpansionPanel square expanded={isOpened} onChange={handleToggle}>
            <Grid container>
                <Grid item xs={12}>
                    <ExpansionPanelSummary>
                        {renderIcon()} <Typography className={classes.cta}>{props.cta}</Typography>
                    </ExpansionPanelSummary>
                </Grid>
            </Grid>
            <ExpansionPanelDetails classes={{root: classes.expansionPanelDetails}}>
                {props.children}
            </ExpansionPanelDetails>
        </ExpansionPanel>

    );
}

const useStyles = makeStyles((theme) => ({
    cta: {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(24),
        lineHeight: theme.typography.pxToRem(32),
    },
    icon: {
        fontSize: "16px",
        marginRight: 20,
        marginLeft: theme.spacing(0.5),
        color: theme.palette.primary.main,
    },
    expansionPanelDetails: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    }

}));