import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _get from "lodash/get";
import 'date-fns';
import {Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form'
import * as Yup from 'yup';
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import {Checkboxes, makeRequired, makeValidate, Select, TextField} from 'mui-rff';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Fade} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

import {fetchModels} from "../../containers/AccessoriesIntroPage/actions";
import {submitAccessoryQuotationForm} from "../../actions/forms";
import {fetchDealerships} from "../../actions/products";
import {PAGES_SLUGS} from "../../constants";

import {appStyle} from "../../theme/appStyle";
import {getModels} from "../accessories/RenderModelsYears";

const GetAccessoryOfferForm = (props) => {

    const classes = useStyles();
    const subscription = {submitting: true};
    const [subscriptionState, setSubscriptionState] = useState(subscription);

    return (
        <div className={classes.root}>
            <Grid container justify={"center"}>
                <Grid item sm={8} xs={12}>
                    <Box
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                    >
                        <Icon className={clsx("brand-icon-contact-details", classes.sectionIcon)}/>
                        <Typography
                            variant="h2"
                            component="h2"
                            className={classes.sectionTitle}
                        >2. Contact</Typography>
                    </Box>
                </Grid>
            </Grid>

            <MainForm
                subscription={subscriptionState}
                idArray={props.idArray}
            />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    root: {},
    sectionTitle: {
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    }
}));


const schema = Yup.object().shape({
    first_name: Yup.string().required("Camp obligatoriu"),
    last_name: Yup.string().required("Camp obligatoriu"),
    email: Yup.string().required("Camp obligatoriu").email("Email invalid"),
    phone: Yup.string().required("Camp obligatoriu").matches(/^[0-9]{10}$/, 'Telefon invalid'),
    dealership_id: Yup.number().required("Camp obligatoriu"),
    terms: Yup.boolean()
        .oneOf([true], 'Camp obligatoriu')
        .required("Camp obligatoriu"),
    car_vin: Yup.string().max(20, "Poate contine maxim ${max} de caractere"),
    car_model: Yup.string()
});

/**
 * Uses the optional helper makeValidate function to format the error messages
 * into something usable by final form.
 */
const validate = makeValidate(schema);

/**
 * Grabs all the required fields from the schema so that they can be passed into
 * the components without having to declare them in both the schema and the component.
 */
const required = makeRequired(schema);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function MainForm({subscription, idArray}) {
    const dispatch = useDispatch();
    const classes = useFormStyles();
    const history = useHistory();

    const dealerships = useSelector(state => state.dealerships.data);
    const accessoryModels = useSelector(state => state.accessoryModels.data);
    const loadingDealerships = useSelector(state => state.dealerships.request);
    const loadingModelList = useSelector(state => state.accessoryModels.request);

    const [model, setModel] = React.useState('');
    /*Year removed in AP/VOL280-Acc-form*/

    const changeModel = (event) => {
        const value = event.target.value;

        setModel(value);
    };


    useEffect(() => {
        if (_isEmpty(dealerships)) {
            dispatch(fetchDealerships())
        }
        if (_isEmpty(accessoryModels)) {
            dispatch(fetchModels())
        }
    }, [])

    useEffect(() => {
        if (window.snowplow) {
            window.snowplow('enableFormTracking');
        }
    }, []);

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const onSubmit = async (values) => {
        try {
            /**
             * "Cheap" implementation
             */
            values['accessories'] = idArray;
            values['car_model'] = model.toString();
            await dispatch(submitAccessoryQuotationForm(values))
            history.push(`/${PAGES_SLUGS.OFFER_THANK_YOU}`, {to: PAGES_SLUGS.ACCESSORIES});
        } catch (errors) {
            setOpenSnackbar(true);
            return {[FORM_ERROR]: _get(errors, "message", "Oops, Submit failed!")}
        }
    };

    /*Error notification*/
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const getDealerships = () => {
        if (_isEmpty(dealerships)) {
            return [];
        } else {
            let options = [
                {label: 'Alege...', value: '', disabled: true},
            ];
            _map(dealerships, (item) => {
                options.push(
                    {label: item.name, value: item.id}
                )
            })
            return options;
        }
    }

    return (
        !(loadingDealerships || loadingModelList) &&
        <Fade>
            <Form
                onSubmit={onSubmit}
                subscription={subscription}
                validate={validate}
                key={subscription}
                render={({handleSubmit, submitting}) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate={true} autoComplete="on"
                              id="accessory_request_form">
                            <Grid container justify={"center"}>
                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <TextField
                                        label="Prenume"
                                        name="first_name"
                                        required={required.first_name}
                                        variant="outlined"
                                        InputProps={{
                                            classes: {root: classes.inputRoot}
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <TextField
                                        label="Nume"
                                        name="last_name"
                                        required={required.last_name}
                                        variant="outlined"
                                        InputProps={{
                                            classes: {root: classes.inputRoot}
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <TextField
                                        label="Telefon"
                                        name="phone"
                                        type="tel"
                                        required={required.phone}
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            classes: {root: classes.inputRoot}
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <TextField
                                        label="Email"
                                        name="email"
                                        type="email"
                                        required={required.email}
                                        variant="outlined"
                                        InputProps={{
                                            classes: {root: classes.inputRoot}
                                        }}
                                    />
                                </Grid>

                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <TextField
                                        label="Mesaj"
                                        name="message"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        fullWidth
                                        classes={{
                                            root: classes.inputRoot
                                        }}
                                    />
                                </Grid>

                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <Select
                                        label="Selecteaza dealer"
                                        name="dealership_id"
                                        required={required.dealership_id}
                                        data={getDealerships()}
                                        variant="outlined"
                                        inputProps={{
                                            classes: {
                                                root: classes.inputRoot,
                                                icon: classes.dropdownIcon
                                            }
                                        }}
                                        IconComponent={ExpandMoreIcon}
                                    />
                                </Grid>

                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <FormControl variant="outlined" className={classes.formControl}
                                                 fullWidth={true}>
                                        <Select
                                            name="car_model"
                                            id="model-select"
                                            value={model}
                                            onChange={changeModel}
                                            label="Model"
                                            variant="outlined"
                                            inputProps={{
                                                classes: {
                                                    root: classes.inputRoot,
                                                    icon: classes.dropdownIcon
                                                }
                                            }}
                                            IconComponent={ExpandMoreIcon}
                                        >
                                            {getModels(accessoryModels)}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <FormControl variant="outlined" className={classes.formControl}
                                                 fullWidth={true}>
                                        <TextField
                                            label="Numar de inmatriculare"
                                            placeholder="ex: B123ABC"
                                            name="reg_plate"
                                            variant="outlined"
                                            InputProps={{classes: {root: classes.inputRoot}}}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <TextField
                                        label="VIN"
                                        name="car_vin"
                                        variant="outlined"
                                        InputProps={{classes: {root: classes.inputRoot}}}
                                    />
                                    <FormHelperText>
                                        Completati pentru a verifica compatibilitatea cu modelul dumneavoastra.
                                    </FormHelperText>
                                </Grid>

                                <Grid item sm={8} xs={12} className={clsx(classes.formRow, classes.hafPad)}>
                                    <Checkboxes
                                        name="terms"
                                        required={required.terms}
                                        formControlLabelProps={{
                                            classes: {label: classes.checkboxLabel}
                                        }}
                                        color="primary"
                                        data={{
                                            label: <span>Sunt de acord cu <Link
                                                href={`/${PAGES_SLUGS.TERMS_AND_CONDITIONS}`}
                                                target={"_blank"}>termenii si conditiile</Link>.</span>,
                                            value: false,
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <Checkboxes
                                        name="contact_optin"
                                        formControlLabelProps={{
                                            classes: {label: classes.checkboxLabel}
                                        }}
                                        color="primary"
                                        data={{
                                            label: <span>Doresc sa primesc informatii si oferte legate de modelele Volvo.</span>,
                                            value: false,
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={8} xs={12} className={classes.formRow}>
                                    <Button variant="outlined" color="primary" type="submit" disabled={submitting}>
                                        Trimite
                                        {submitting &&
                                        <CircularProgress size={24} className={classes.buttonProgress}/>}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                                <Alert onClose={handleCloseSnackbar} severity="error">
                                    Oops! Something went wrong! Please check your info and retry.
                                </Alert>
                            </Snackbar>
                        </form>
                    )
                }}
            />
        </Fade>
    )
}

const useFormStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    withLineDivider: {
        position: "relative",
    },
    formRoot: {},
    inputRoot: {
        background: "#fff",
    },
    hafPad: {},
    formRow: {
        marginBottom: theme.spacing(3),
        "&$hafPad": {
            marginBottom: theme.spacing(1),
        }
    },
    formTextbox: {
        paddingTop: theme.spacing(0),
    },
    formCheckbox: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(5),
    },
    formTextboxLabel: {
        textAlign: "center",
        paddingBottom: theme.spacing(3),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    driverData: {
        position: "relative",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    driverDataInner: {
        margin: "0 auto",
        padding: `0 ${theme.spacing(3)}px`,
        background: "#FAFAFA",
        zIndex: 2,
    },
    divider: {
        width: '100%',
        position: "absolute",
        top: "50%",
        left: 0,
        transform: "translateY(-50%)",
    },
    icon: {
        fontSize: 32,
        marginRight: 10,
    },
    dropdownIcon: {
        color: theme.palette.primary.main,
    },
    checkboxLabel: {
        fontSize: theme.typography.pxToRem(14),
    }
}));
export default GetAccessoryOfferForm
