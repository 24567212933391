// export const API_ROOT_URL ="https://stage-services.fromto.ca/api/v1";//process.env.REACT_APP_API_URL;
export const API_ROOT_URL = process.env.REACT_APP_API_URL;
export const API_CARS = `${API_ROOT_URL}/cars`;
export const API_PRODUCT = `${API_ROOT_URL}/cars`;
export const API_MODELS = `${API_ROOT_URL}/models`;
export const API_MODELS_LIST = `${API_ROOT_URL}/models/list`;
export const API_DEALERSHIPS_LIST = `${API_ROOT_URL}/dealerships/list`;
export const API_DEALERSHIPS_LIST_BY_MODEL = `${API_ROOT_URL}/dealerships/list/model`;
export const API_TESTDRIVE = `${API_ROOT_URL}/test-drive`;
export const API_QUOTATION = `${API_ROOT_URL}/quotation`;
export const API_ACCESSORY_QUOTATION = `${API_ROOT_URL}/quotation/accessories`;
export const API_PAGES = `${API_ROOT_URL}/pages`;
export const API_BEST_PRICE_BY_MODEL = `${API_ROOT_URL}/cars/best-price-by-model`;
export const API_BEST_OFFER_BY_MODEL = `${API_ROOT_URL}/cars/best-offer-by-model`;
export const API_ACCESSORY_MODELS = `${API_ROOT_URL}/accessory-models`;
export const API_ACCESSORY_DETAILS = `${API_ROOT_URL}/accessories`;
export const API_WISHLIST_UUID = `${API_ROOT_URL}/wishlist/data`;
