import React, {Component} from 'react';
import {connectHits} from "react-instantsearch-dom";
// UI
import Grid from "@material-ui/core/Grid";
import DetailedAccCard from "./DetailedAccCard";


class AlgoliaAccHits extends Component {
    render() {
        const {hits} = this.props;
        return (
            hits.map(hit => {
                return (
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}
                          key={hit.slug}
                    >
                        <DetailedAccCard data={hit}/>
                    </Grid>
                )
            })
        )
    }
}

AlgoliaAccHits = connectHits(AlgoliaAccHits);
export default AlgoliaAccHits;
