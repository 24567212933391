export const withGtmTraking = process.env.REACT_APP_ENABLE_TRAKING_SCRIPTS;
//
// export const GTM_TESTDRIVE_INIT_FORM_PARAMS ={
//     'event' : 'webform_action',
//     'formType' : 'B2C-TDR',
//     'formId': 'test_drive_form',
//     'type': 'Webform',
//     'result' : 'load',
//     'nonInteraction': '1'
// }
//
// export const GTM_TESTDRIVE_SUCCESS_FORM_PARAMS ={
//     'event' : 'webform_action',
//     'formType' : 'B2C-TDR',
//     'formId': 'test_drive_form',
//     'type': 'Webform',
//     'result' : 'success',
//     'nonInteraction': '0'
// }
//
//
// export const GTM_GETOFFER_INIT_FORM_PARAMS ={
//     'event' : 'webform_action',
//     'formType' : 'B2C-OR',
//     'formId': 'offer_request_form',
//     'type': 'Webform',
//     'result' : 'load',
//     'nonInteraction': '1'
// }
//
// export const GTM_GETOFFER_SUCCESS_FORM_PARAMS ={
//     'event' : 'webform_action',
//     'formType' : 'B2C-OR',
//     'formId': 'offer_request_form',
//     'type': 'Webform',
//     'result' : 'success',
//     'nonInteraction': '0'
// }
export const GTM_GETOFFER_TY_PARAMS ={
    'event': 'conversion',
    'send_to': 'AW-770003597/y1Q5CLbS-q0BEI2lle8C'
}

/*Another one 2 Nov '22*/
export const GTM_GETOFFER_TY_PARAMS_22 ={
    'event': 'conversion',
    'send_to': 'AW-770003597/b1F0CIrW9KoBEI2lle8C'
}
