import React from 'react';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const Title = (props) => {
    const classes = useStyles();
    return <div className={classes.title}>
        <Typography
            variant="h2"
            component="h2"
            align={"center"}
            className={classes.text}
        >
            {props.children}
        </Typography>
    </div>
}

const useStyles = makeStyles((theme) => ({

    title: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        ".contrast-section & $text": {
            color: "#fff",
        }
    },
    text: {}

}));

const Text = (props) => {
    const classes = useTextStyles();
    return <Typography {...props} align={"justify"} component={"div"} className={classes.root}>
        {props.children}
    </Typography>
}


const useTextStyles = makeStyles((theme) => ({

    root: {
        "& p": {
            color: theme.palette.secondary.main,
            fontWeight: theme.typography.fontWeightLight,
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(24),
            letterSpacing: "0",
            marginBottom: theme.spacing(2),
            wordBreak: "break-word",
        },
        "& strong":{
            fontWeight: theme.typography.fontWeightMedium,
        },
        "& ul": {
            paddingLeft: theme.spacing(3),
            "& li": {
                color: theme.palette.secondary.main,
                fontWeight: theme.typography.fontWeightLight,
                fontSize: theme.typography.pxToRem(16),
                lineHeight: theme.typography.pxToRem(24),
                letterSpacing: "0",
                marginBottom: theme.spacing(0),
                wordBreak: "break-word",
            }
        },
        "& a": {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightLight,
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(24),
            letterSpacing: "0",
            marginBottom: theme.spacing(0),
            wordBreak: "break-word",
            position: 'relative',
            textDecoration: "none",
            "&:hover": {
                textDecoration: "none",
            },
            "&:before": {
                content: `''`,
                position: "absolute",
                width: "0",
                height: "1px",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                background: theme.palette.primary.main,
                transition: theme.transitions.create(['width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            "&:hover:before": {
                content: `''`,
                position: "absolute",
                width: "100%",
                height: "1px",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                background: theme.palette.primary.main
            }
        }
    },

}));

export {
    Title,
    Text,
}