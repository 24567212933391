const itemDetailsStyles = theme => ({
    rootPrice: {
        color: '#707070',
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(24),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(16),
        },
    },
    price: {
        color: "#007BCD",
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(24),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(16),
        },
    },
    oldPrice: {
        color: '#707070',
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(24),
        fontWeight: '300',
        paddingTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(0),
        },
    },
    oldPriceLineThrough: {
        textDecoration: "line-through",
    },
    customSnackbar: {
        top: 80
    },
    customLowerSnackbar: {
        top: 154
    },
    customAlert: {
        '& .MuiIcon-root': {
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(20),
        }
    },
    icon: {},
    alertSuccess: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.secondary.contrastText,
    },
    alertWarning: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.secondary.contrastText,
    }
});

export default itemDetailsStyles;
